import type { IconifyIcon } from "@iconify/react/dist/offline";
import { Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Popover, { type PopoverProps } from "@mui/material/Popover";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import type { SwipeableDrawerProps } from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { CustomScrollbar } from "src/components/others/Scrollbar";
import { themeBranding } from "src/config";
import useSiteContext from "src/hooks/useSiteContext";
import { Iconify } from "src/utils/getIcon";
import type { OfflineIcons } from "src/utils/icon";
import type { DateRangePickerHandle } from "./BasicDateRangePicker";

// Magic Button

type MagicButtonProps = {
	id?: string;
	value?: React.ReactNode;
	icon: IconifyIcon | OfflineIcons;
	handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
	title?: string;
};

export const MagicButtonBase = ({
	id,
	value,
	icon,
	handleClick,
	title,
}: MagicButtonProps) => {
	const { siteLoading } = useSiteContext();
	const theme = useTheme();

	const sxMagicButtonTheme = {
		border: "1px solid #919EAB52",
		borderRadius: "16px",
		fontWeight: themeBranding !== "smartImpulse" ? "bold" : "500",
		color: "text.secondary",
		background: theme.palette.background.paper,
		textTransform: "initial",
		"& :first-letter": {
			textTransform: "uppercase",
		},
		whiteSpace: "nowrap",
		"& .MuiButton-startIcon, .MuiButton-endIcon": {
			color: "primary.main",
		},
	};
	const button: any =
		value === "download" ? (
			<IconButton
				onClick={handleClick}
				size="small"
				sx={{ height: "40px", width: "40px" }}
				id={`${id}-button`}
			>
				{Iconify(icon, 25)}
			</IconButton>
		) : (
			<Button
				aria-describedby={id}
				id={`${id}-button`}
				variant="outlined"
				onClick={handleClick}
				size="small"
				disabled={siteLoading}
				startIcon={!siteLoading && Iconify(icon)}
				sx={{
					...sxMagicButtonTheme,
					...(value === "download" && {
						minWidth: "unset",
						"& .MuiButton-startIcon": { mr: 0, px: "2px" },
					}),
					...(siteLoading && { p: 0, m: 0 }),
				}}
			>
				{siteLoading ? (
					<Skeleton
						animation="wave"
						variant="rectangular"
						width={120}
						height={30}
						sx={{ borderRadius: "16px" }}
					/>
				) : (
					value || (value !== "download" && title) || ""
				)}
			</Button>
		);

	if (title !== undefined) {
		// console.log(title, '[APPTRACKER title]')
		return (
			<Tooltip title={title}>
				<span>
					{/* span to wrap disabled buttons and make React happy */}
					{button}
				</span>
			</Tooltip>
		);
	} else {
		return (
			<Button
				id={`${id}-button`}
				sx={{ ...sxMagicButtonTheme }}
				aria-describedby={id}
				variant="outlined"
				onClick={handleClick}
				size="small"
				startIcon={Iconify(icon)}
			>
				{value || (value !== "download" && title) || ""}
			</Button>
		);
	}
};

// HeaderButton
export const HeaderButton = (props: any) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<Box
			sx={{
				flex: "1",
				minWidth: "0",
			}}
		>
			<Button
				startIcon={isMobile ? Iconify("ph:buildings-duotone", 40) : null}
				endIcon={props.noEndIcon ? null : Iconify("ph:caret-down-light", 24)}
				// size="large"
				id={`${props.id}-button`}
				onClick={props.handleClick}
				sx={{
					border: "none",
					color: "white",
					flex: "1",
					minWidth: "0",
					maxWidth: "100%",

					background: "none",
					"& :hover": {
						border: "none",
					},
					justifyContent: "left",
				}}
			>
				<Typography
					variant="h4"
					component="div"
					sx={{
						textTransform: "initial",
						color: "white",
						maxWidth: "100%",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{props.value}
				</Typography>
			</Button>
		</Box>
	);
};

// Custom Popover
const StyledPopoverTheme = styled(Popover)({
	"& .MuiPopover-paper": {
		borderRadius: "16px",
		padding: "10px 0",
		overflowY: "hidden",
	},
});

type StylePopoverProps = PopoverProps & {
	value: any;
	header?: boolean;
	handleClose: (value?: any) => void;
};

export function StyledPopover(props: StylePopoverProps) {
	const { pathname } = useLocation();
	const isDashboard: boolean = pathname.includes("/dashboard/");

	return (
		<StyledPopoverTheme
			// id={`${props.id}-content`}
			open={props.open}
			anchorEl={props.anchorEl}
			onClose={() => props.handleClose()}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: isDashboard && !props.header ? "right" : "left",
			}}
			transformOrigin={{
				vertical: -8,
				horizontal: isDashboard && !props.header ? "right" : "left",
			}}
		>
			{/* {props.title && <Box
        sx={{ display: "flex", alignItems: "center", p: "7px 20px 5px 20px" }}
      >
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ flexGrow: 1, fontWeight: "bold" }}
        >
          {props.title}
        </Typography>
        <IconButton sx={{ transform: "scale(0.9)" }}>
          {Iconify("ph:x")}
        </IconButton>
      </Box>} */}
			{props.children}
		</StyledPopoverTheme>
	);
}

type MagicContainerProps = {
	id?: string;
	children: React.ReactElement;
	// daysChecked: number[] | null;
	title?: string;
	icon: IconifyIcon | OfflineIcons;
	value: any;
	withRef?: boolean;
	header?: boolean;
};

function MyPopover(props: MagicContainerProps) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const ref = React.useRef<DateRangePickerHandle>();

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (value: any) => {
		// console.log("Close MyPopover")
		ref.current?.propagateClose(value);
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	// const id = open ? "simple-popover" : undefined;

	const additional_props: any = { handleClose, openDrawer: open };
	if (props.withRef) {
		additional_props.ref = ref;
	}

	return (
		<Box sx={{ ...(props.header && { flex: "1", minWidth: "0" }) }}>
			{props.header ? (
				<HeaderButton
					id={props.id}
					value={props.value}
					handleClick={handleClick}
				/>
			) : (
				<MagicButtonBase
					id={props.id}
					value={props.value}
					icon={props.icon}
					title={props.title}
					handleClick={handleClick}
				/>
			)}
			<StyledPopover
				id={props.id}
				open={open}
				anchorEl={anchorEl}
				title={props.title}
				// children={props.children}
				value={props.value}
				handleClose={handleClose}
				children={React.cloneElement(props.children, additional_props)}
				header={props.header}
			/>
		</Box>
	);
}

// Custom Drawer
const StyledDrawer = styled(SwipeableDrawer)({
	"& .MuiDrawer-paper": {
		borderRadius: "16px 16px 0 0",
		padding: "10px 0 5px",
		margin: "4px",
		marginBottom: 0,
		// minHeight: "80%",
		// maxHeight: "95%",
		height: "90%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
});

const DrawerHandle = () => {
	return (
		<Box
			sx={{
				width: "100px",
				bgcolor: "grey.400",
				height: "4px",
				flexShrink: "0",
				borderRadius: "4px",
				mx: "auto",
				mt: "4px",
				mb: "10px",
			}}
		/>
	);
};

export const StyledDrawerWithHandle = (
	props: SwipeableDrawerProps & { children: React.ReactNode },
) => {
	const { children, ...other } = props;
	return (
		<StyledDrawer disableSwipeToOpen {...other}>
			<DrawerHandle />
			{children}
		</StyledDrawer>
	);
};

function MyDrawer(props: MagicContainerProps) {
	const [open, setOpen] = React.useState(false);
	const ref = React.useRef<DateRangePickerHandle>();

	const toggleDrawer = (newOpen: boolean) => (_event: any) => {
		setOpen(newOpen);
	};

	const handleClose = (value: any) => {
		// console.log("Close MyDrawer")
		ref.current?.propagateClose(value);
		setOpen(false);
	};

	const additional_props: any = { handleClose, openDrawer: open };
	if (props.withRef) {
		additional_props.ref = ref;
	}

	return (
		<React.Fragment>
			{props.header ? (
				<HeaderButton
					id={props.id}
					value={props.value}
					handleClick={toggleDrawer(true)}
				/>
			) : (
				<MagicButtonBase
					id={props.id}
					value={props.value}
					icon={props.icon}
					title={props.title}
					handleClick={toggleDrawer(true)}
				/>
			)}
			<StyledDrawerWithHandle
				// id={`${props.id}-content`}
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				children={React.cloneElement(props.children, additional_props)}
				disableSwipeToOpen
			/>
		</React.Fragment>
	);
}

export function MagicContainer(props: MagicContainerProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

	return isMobile ? (
		// ? <MyPopover {...props} />
		<MyPopover {...props} />
	) : (
		<MyDrawer {...props} />
	);
}
