import { Box, /*Stack,*/ Drawer } from "@mui/material";
import { styled /*useTheme*/ } from "@mui/material/styles";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavSectionVertical } from "../../../components/nav-section";
import Scrollbar from "../../../components/others/Scrollbar";
import { HEADER, NAVBAR } from "../../../config";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import useResponsive from "../../../hooks/useResponsive";
import navConfig from "./NavConfig";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("lg")]: {
		flexShrink: 0,
		transition: theme.transitions.create("width", {
			duration: theme.transitions.duration.standard,
		}),
	},
}));

// ----------------------------------------------------------------------

type Props = {
	isOpenSidebar: boolean;
	onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
	isOpenSidebar,
	onCloseSidebar,
}: Props) {
	// const theme = useTheme();

	const { pathname } = useLocation();
	const params = useParams();

	const isDesktop = useResponsive("up", "lg");

	const {
		isCollapse,
		collapseClick,
		collapsehover,
		onHoverEnter,
		onHoverLeave,
	} = useCollapseDrawer();

	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderContent = (
		<>
			{/* {!isDesktop && <Stack
        spacing={3}
        sx={{
          pt: 3,
          // pb: 2,
          px: 2.5,
          flexShrink: 0,
          // position: '-webkit-sticky',
          position: 'sticky',
          top: '0px',
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>} */}
			<Scrollbar
				sx={{
					height: 1,
					"& .simplebar-content": {
						height: 1,
						display: "flex",
						flexDirection: "column",
					},
				}}
			>
				<NavSectionVertical
					id="navBar"
					navConfig={navConfig(params)}
					isCollapse={isCollapse}
				/>

				<Box sx={{ flexGrow: 1 }} />

				{/* Hide documentation at launch */}
				{/* {!isCollapse && <NavbarDocs />} */}
			</Scrollbar>
		</>
	);

	return (
		<RootStyle
			sx={{
				width: {
					lg: isCollapse
						? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
						: NAVBAR.DASHBOARD_WIDTH,
				},
				...(collapseClick && {
					position: "absolute",
				}),
			}}
		>
			{!isDesktop && (
				<Drawer
					open={isOpenSidebar}
					onClose={onCloseSidebar}
					PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
				>
					{renderContent}
				</Drawer>
			)}

			{isDesktop && (
				<Drawer
					open
					variant="persistent"
					onMouseEnter={onHoverEnter}
					onMouseLeave={onHoverLeave}
					PaperProps={{
						sx: {
							border: "none",
							top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
							bottom: "0",
							margin: "0 0 0 20px",
							marginBottom: "20px",
							overflow: "hidden",
							width: NAVBAR.DASHBOARD_WIDTH - 20,
							height: "auto",
							// borderRightStyle: 'dashed',
							// bgcolor: theme.palette.background.default,
							bgcolor: "transparent",
							// background: "yellow",
							borderRadius: "16px",
							// WebkitBoxShadow:"0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
							// backdropFilter: 'blur(6px)',
							// WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
							// boxShadow: (theme) => theme.customShadows.z20,
							zIndex: (theme) => theme.zIndex.appBar - 1,
							transition: (theme) =>
								theme.transitions.create("width", {
									duration: theme.transitions.duration.standard,
								}),
							...(isCollapse && {
								width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
							}),
							...(collapsehover &&
								{
									// ...cssStyles(theme).bgBlur(),
									// boxShadow: (theme) => theme.customShadows.z24,
								}),
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</RootStyle>
	);
}
