// @mui
import {
	Box,
	CircularProgress,
	type CircularProgressProps,
	circularProgressClasses,
} from "@mui/material";

export function SmartCircularProgress(props: CircularProgressProps) {
	return (
		<Box sx={{ position: "relative" }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: (theme) =>
						theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
				}}
				size={30}
				thickness={6}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				sx={{
					color: (theme) =>
						theme.palette.mode === "light" ? "primary" : "secondary",
					animationDuration: "1000ms",
					position: "absolute",
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: "round",
					},
				}}
				size={30}
				thickness={6}
				{...props}
			/>
		</Box>
	);
}
