import { t } from "ttag";

export function formatUnitSplit(
	val: number,
	base_unit: string | boolean,
	display_point_zero: boolean,
	locale: string,
	isCurrency?: boolean,
) {
	let decimals;
	let result;
	let full_unit = "";
	val = Math.round(val * 10) / 10;

	if (base_unit === "%" || base_unit === "‰") {
		full_unit = base_unit;
		result = val;
	}
	// ajustement du nombre de décimales (2.0 -> 2, 1.5 -> 1.5, 300.0 -> 300)
	else if (base_unit !== false) {
		// Adding the right prefix
		let ratio = 1;
		if (
			Math.abs(val) >= 1e9 &&
			!(typeof base_unit === "string" && base_unit.charAt(0) === "g")
		) {
			ratio = 1e9;
			if (typeof isCurrency !== "undefined" && isCurrency) {
				full_unit = t` B` + base_unit;
			} else {
				full_unit = " G" + base_unit;
			}
		} else if (Math.abs(val) >= 1e6) {
			ratio = 1e6;
			if (typeof base_unit === "string" && base_unit.charAt(0) === "g") {
				// Megagrams > tons
				full_unit = " T" + base_unit.slice(1);
			} else {
				full_unit = " M" + base_unit;
			}
		} else if (Math.abs(val) >= 1e3) {
			ratio = 1e3;
			full_unit = " k" + base_unit;
		} else {
			full_unit = " " + base_unit;
		}
		result = val / ratio;
	} else {
		result = val;
	}

	if (
		result === 0 ||
		((Math.abs(result) % 1 === 0 || Math.abs(result) > 10) &&
			!display_point_zero)
	) {
		decimals = 0;
	} else {
		decimals = 1;
	}

	const number_format = new Intl.NumberFormat(locale, {
		maximumFractionDigits: decimals,
	});

	// result = result.toFixed(decimals) + full_unit;
	return {
		number: result,
		numberString: number_format.format(result),
		unit: full_unit,
		decimals: decimals,
		// decimalSeparator: number_format.formatToParts(result).find(part => part.type === 'decimal')
	};
}

export function formatUnit(
	val: number,
	base_unit: string | boolean,
	display_point_zero: boolean,
	locale: string,
) {
	const { numberString, unit } = formatUnitSplit(
		val,
		base_unit,
		display_point_zero,
		locale,
	);
	return numberString + unit;
}

export function getUnit(dimension: string) {
	/**
	 * Dimension can be :
	 * "energy" (Wh),
	 * "performance" (kWh/m²)
	 * "power" (W),
	 * "reactive-power" (VAr),
	 * "apparent-power" (VA),
	 * "temperature" (°C),
	 * "carbon_rate" (g CO₂/kWh)
	 * "carbon" (g CO₂)
	 * "percent" (%)
	 * Upcoming :
	 * "euro" (€)
	 **/
	const units: { [key: string]: string } = {
		energy: "Wh",
		performance: "kWh/m²",
		power: "W",
		"reactive-power": "VAr",
		"apparent-power": "VA",
		temperature: "°C",
		carbon_rate: "g.CO₂/kWh",
		carbon: "g.CO₂",
		percent: "%",
	};

	if (dimension in units) return units[dimension];

	return dimension;
}
