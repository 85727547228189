import type ColumnTable from "arquero/dist/types/table/column-table";
import type { Struct } from "arquero/dist/types/table/transformable";
import type { PeriodDataTable } from "../../components/echarts/aggregate";

export function getAggColumns(
	aq: any,
	columns: string[],
	columnsToExclude: string[],
) {
	const aggColumns: Record<string, number> = {};
	columns.map((column) => {
		if (!columnsToExclude.includes(column))
			aggColumns[column] = aq.op.sum(column);
	});
	return aggColumns;
}

export function getEnergyTotalTable(
	dataTable: ColumnTable,
	aggColumns: Record<string, number>,
) {
	return dataTable.rollup(aggColumns);
}

function aggregateAllPeriods(
	aq: any,
	dataByDay: ColumnTable,
	aggColumns: Record<string, number>,
) {
	const periodData: PeriodDataTable = {
		EnergyDay: null,
		EnergyWeek: null,
		EnergyMonth: null,
		EnergyYear: null,
		EnergyTotal: null,
	};
	periodData.EnergyDay = dataByDay.orderby("date").reify();

	// add site_count & active_site_count to aggregate number of values per sites & number of sites by period (Week, Month, ...)
	aggColumns.site_count = aq.op.sum("site_count");
	aggColumns.cumul_active_site = aq.op.sum("active_site_count");
	aggColumns.active_installation_uuid = aq.op.aggDistinctArray(
		"active_installation_uuid",
	);

	// add week, aggregate by week and add data_ratio: site_count / max sites * nb of days in period (in %)
	periodData.EnergyWeek = dataByDay
		.derive({
			week: aq.escape((d: Struct) => aq.op.getWeek(d?.date)),
		})
		.groupby("week")
		.rollup(aggColumns)
		.derive({
			active_site_count: aq.escape((d: Struct) =>
				aq.op.length(d?.active_installation_uuid),
			),
			data_ratio: aq.escape(
				(d: Struct) => (100 * d.site_count) / d.cumul_active_site,
			),
		})
		.orderby("week")
		.reify();

	// same process for month & year
	periodData.EnergyMonth = dataByDay
		.derive({
			month: aq.escape((d: Struct) => aq.op.getMonth(d?.date)),
		})
		.groupby("month")
		.rollup(aggColumns)
		.derive({
			active_site_count: aq.escape((d: Struct) =>
				aq.op.length(d?.active_installation_uuid),
			),
			data_ratio: aq.escape(
				(d: Struct) => (100 * d.site_count) / d.cumul_active_site,
			),
		})
		.orderby("month")
		.reify();

	periodData.EnergyYear = dataByDay
		.derive({
			year: aq.escape((d: Struct) => aq.op.getYear(d?.date)),
		})
		.groupby("year")
		.rollup(aggColumns)
		.derive({
			active_site_count: aq.escape((d: Struct) =>
				aq.op.length(d?.active_installation_uuid),
			),
			data_ratio: aq.escape(
				(d: Struct) => (100 * d.site_count) / d.cumul_active_site,
			),
		})
		.orderby("year")
		.reify();

	// aggregate on all rows
	if (periodData.EnergyYear) {
		periodData.EnergyTotal = getEnergyTotalTable(dataByDay, aggColumns).derive({
			active_site_count: aq.escape((d: Struct) =>
				aq.op.length(d?.active_installation_uuid),
			),
		});
	}
	return periodData;
}

export function getMCByInstallation(
	aq: any,
	data: ColumnTable,
	maxDaysCount?: number,
	installationTable?: ColumnTable,
) {
	// faster to do all aggregations (week, month, year) in a single function instead of function by Frequency
	const aggColumns = getAggColumns(aq, data.columnNames(), [
		"date",
		"installation_uuid",
	]);

	// add aggregation for date to count nb of days with data
	aggColumns.days_count = aq.op.count("date");
	let dataBySite = data.groupby("installation_uuid").rollup(aggColumns);

	if (maxDaysCount)
		dataBySite = dataBySite.derive({
			days_ratio: aq.escape((d: Struct) => (d.days_count * 100) / maxDaysCount),
		});

	if (installationTable) {
		dataBySite = dataBySite.join_left(installationTable, "installation_uuid");
		// total is in Wh, performance is in kWh/m² so divide by 1000
		dataBySite = dataBySite.derive({
			performance: aq.escape((d: Struct) =>
				d.surface ? d.total / (1000 * d.surface) : "",
			),
		});
	}

	// sort table by descending total energy
	dataBySite = dataBySite.orderby(aq.desc("total")).reify();
	// rename total column
	return dataBySite;
}

export function getMCByPeriod(
	aq: any,
	data: ColumnTable,
	activeSiteByDate: ColumnTable,
) {
	// activeSiteByDate = aq.table ({
	// date: string,
	// active_installation_uuid: string []
	// active_site_count: number
	// })
	// faster to do all aggregations (week, month, year) in a single function instead of function by Frequency
	const aggColumns = getAggColumns(aq, data.columnNames(), [
		"date",
		"installation_uuid",
	]);
	// date column should be string in format "2022-12-12" with no time data because it should have 1 pt/day
	let dataByDay = data.groupby("date").rollup({
		...aggColumns,
		// site_count are the number of site that have data for a day
		site_count: aq.op.distinct("installation_uuid"),
	});

	// add active_site_count to date : the number of sites that is active at this date (begin < date < end)
	dataByDay = dataByDay.join_left(activeSiteByDate, "date");

	// calculated data_ratio
	dataByDay = dataByDay.derive({
		data_ratio: aq.escape(
			(d: Struct) => (100 * d.site_count) / d.active_site_count,
		),
	});

	return aggregateAllPeriods(aq, dataByDay, aggColumns);
}

export function getInstallationTotalByPeriod(
	aq: any,
	data: ColumnTable,
	activeSiteByDate: ColumnTable,
	columnMapping: Record<string, string>,
	columnsToInclude?: string[],
) {
	// activeSiteByDate = aq.table ({
	// date: string,
	// active_installation_uuid: string []
	// active_site_count: number
	// })
	// WARNING colsToInclude should include the names of the columns after Mapping
	// use StringExpressions to avoid error on CI : Update expressions not allowed: looks like .pivot & .impute are stricter on expressions
	let dataByDay = data
		.groupby("date")
		.pivot(
			{ installation_uuid: "d => d.installation_uuid" },
			{ total: "d => aq.op.sum(d.total)" },
		);

	dataByDay = dataByDay.rename(columnMapping);

	dataByDay = dataByDay.derive({
		site_count: aq.escape((d: Struct) =>
			Object.entries(d).reduce(
				(count, entry) =>
					entry[0] !== "date" && entry[1] > 0 && typeof entry[1] === "number"
						? count + 1
						: count,
				0,
			),
		),
	});

	if (columnsToInclude) {
		const otherSitesTables = dataByDay
			.select(aq.not(columnsToInclude))
			.derive({
				otherSites: aq.escape((d: Struct) =>
					Object.entries(d).reduce(
						(sum, entry) =>
							entry[0] !== "date" &&
							entry[0] !== "site_count" &&
							typeof entry[1] === "number"
								? sum + entry[1]
								: sum,
						0,
					),
				),
			})
			.select("date", "otherSites");
		dataByDay = dataByDay
			.select("date", "site_count", columnsToInclude)
			.join_left(otherSitesTables, "date");
	}

	const columns: string[] = dataByDay.columnNames();
	const replaceUndefined: Record<string, any> = {};
	columns.map((column) => {
		replaceUndefined[column] = "() => 0";
	});
	dataByDay = dataByDay.impute(replaceUndefined);

	// add total & site_count columns
	dataByDay = dataByDay.derive({
		total: aq.escape((d: Struct) =>
			Object.entries(d).reduce(
				(sum, entry) =>
					entry[0] !== "date" &&
					entry[0] !== "site_count" &&
					typeof entry[1] === "number"
						? sum + entry[1]
						: sum,
				0,
			),
		),
	});

	// add active_site_count to date : the number of sites that is active at this date (begin < date < end)
	dataByDay = dataByDay.join_left(activeSiteByDate, "date");

	// calculated data_ratio
	dataByDay = dataByDay.derive({
		data_ratio: aq.escape(
			(d: Struct) => (100 * d.site_count) / d.active_site_count,
		),
	});

	const aggColumns = getAggColumns(aq, dataByDay.columnNames(), [
		"date",
		"site_count",
		"data_ratio",
	]);

	const dataEvolutionSite = aggregateAllPeriods(aq, dataByDay, aggColumns);
	return dataEvolutionSite;
}
