import * as aq from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";
import { Struct } from "arquero/dist/types/table/transformable";
import { differenceInCalendarDays, isAfter, isSameDay } from "date-fns";
import { isArray } from "lodash";
import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useParams } from "react-router";
import { meteringplanBenchmark } from "src/@types/installation";
import {
	FilterDetails,
	NumberSiteFilters,
	SiteFilters,
	initialFilters,
	numberSiteFilters,
} from "src/@types/metadataSchema";
import { SiteItemCustomerChild } from "src/@types/sites";
import {
	filtersSites,
	getFilterDetails,
} from "src/components/dashboard/Metadata/functions/filtersMetadata";
import getEnumTitle from "src/components/dashboard/Metadata/functions/getEnumTitle";
import { PeriodDataTable } from "src/components/echarts/aggregate";
import {
	BENCHMARK_AUXILIARIES_ID,
	BENCHMARK_SITES_NB,
	BENCHMARK_VENTILATION_ID,
} from "src/config";
import useLocales from "src/hooks/useLocales";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { getBenchmark } from "src/services/service";
import {
	getAggColumns,
	getInstallationTotalByPeriod,
	getMCByInstallation,
	getMCByPeriod,
} from "src/utils/arquero/aggregateGroup";
import {
	addArqueroFunctions,
	getActiveSiteByDate,
	mergeTwoColumns,
	restrictTablePeriod,
} from "src/utils/arquero/arqueroUtils";
import {
	deExtrapolateBenchmark,
	extrapolateBenchmark,
} from "src/utils/arquero/extrapolationGroup";
import benchmarkZonedDates, {
	isDataPerYear,
} from "src/utils/benchmarkZonedDates";
import { formatNumberLocale } from "src/utils/formatNumber";
import { returnChild } from "src/utils/treeFinder";
import { formatUnit, getUnit } from "src/utils/unitManager";
import { t } from "ttag";
import { DateRangeDate } from "./dateRangeContext";
import { dateFormatISO } from "./magicButtonContext";

export type ExtrapolationType = "real" | "extrapolated";

export const extrapolationValues: ExtrapolationType[] = [
	"real",
	"extrapolated",
];

type BenchmarkProviderProps = { children: ReactNode };

export type BenchmarkContextProps = {
	multisiteFlat: SiteItemCustomerChild[];
	filteredSites: SiteItemCustomerChild[];
	filters: SiteFilters;
	setFilters: (filters: SiteFilters) => void;
	areFiltersActive: boolean;
	allFiltersDetails: Record<string, FilterDetails>;
	numberSiteFiltersRange?: Record<keyof NumberSiteFilters, number[]>;
	loadBenchmark: (installations?: meteringplanBenchmark[]) => void;
	benchmarkLoaded: boolean;
	dataCumul?: ColumnTable;
	dataEvolution?: PeriodDataTable;
	dataEvolutionSite?: PeriodDataTable;
	maxDaysCount?: number;
	minDataDate?: Date;
	maxDataDate?: Date;
	dateRange?: DateRangeDate;
	setDateRange: (dateRange: DateRangeDate) => void;
	maxDateTz?: string;
	extrapolation: ExtrapolationType;
	setExtrapolation: (extrapolation: ExtrapolationType) => void;
	areSiteLackingDays: boolean;
	isExtrapolationActive: boolean;
	isDataYearly: boolean;
	openDialogGenericUsageContext: boolean;
	setOpenDialogGenericUsageContext: (bool: boolean) => void;
	filteredInstallationsState: meteringplanBenchmark[];
	setBenchmarkState: Dispatch<SetStateAction<BenchmarkStateProps>>;
};

type BenchmarkStateProps = Pick<
	BenchmarkContextProps,
	| "benchmarkLoaded"
	| "dataCumul"
	| "dataEvolution"
	| "dataEvolutionSite"
	| "maxDaysCount"
	| "minDataDate"
	| "maxDataDate"
	| "dateRange"
	| "maxDateTz"
>;

const initialState: BenchmarkContextProps = {
	multisiteFlat: [],
	filteredSites: [],
	filters: initialFilters,
	setFilters: () => {},
	areFiltersActive: false,
	allFiltersDetails: {},
	numberSiteFiltersRange: {} as Record<keyof NumberSiteFilters, number[]>,
	loadBenchmark: () => {},
	benchmarkLoaded: false,
	setDateRange: () => {},
	extrapolation: "real",
	setExtrapolation: () => {},
	areSiteLackingDays: false,
	isExtrapolationActive: false,
	isDataYearly: false,
	openDialogGenericUsageContext: false,
	setOpenDialogGenericUsageContext: () => {},
	filteredInstallationsState: [],
	setBenchmarkState: () => {},
};

// colors palette for dataEvolutionSite: order is from the most consuming site to the least one, and the last color is the 'Other sites' color
export const dataEvolutionSiteColors = [
	"#FDAE61",
	"#B2DF8A",
	"#BC80BD",
	"#FB9A99",
	"#FAACD3",
	"#80B1D3",
	"#C7DCEB",
	"#FB8072",
	"#D4EDBD",
	"#8DD3C7",
	"#D9D7DB",
];

const BenchmarkContext = createContext(initialState);

function BenchmarkProvider({ children }: BenchmarkProviderProps) {
	const { nodesLoaded, MultiSites, MultiSitesInstallations, MultisitesCount } =
		useNodesContext();
	const { siteSchema } = useSiteContext();
	const { currentLang } = useLocales();
	const params = useParams();
	const [multisiteFlat, setMultisiteFlat] = useState<SiteItemCustomerChild[]>(
		[],
	);
	const [filteredSites, setFilteredSites] = useState<SiteItemCustomerChild[]>(
		[],
	);
	const [filters, setFilters] = useState<SiteFilters>(initialFilters);
	const [extrapolation, setExtrapolation] = useState<ExtrapolationType>("real");
	const [openDialogGenericUsageContext, setOpenDialogGenericUsageContext] =
		useState(false);
	const areFiltersActive = Object.values(filters).some(
		(value) => value.length > 0,
	);
	const allFiltersDetails = Object.keys(initialFilters).reduce(
		(acc, field) => {
			acc[field] = getFilterDetails(field as keyof SiteFilters, siteSchema);
			return acc;
		},
		{} as Record<string, FilterDetails>,
	);

	const [benchmarkData, setBenchmarkData] = useState<ColumnTable | null>(null);
	const [benchmarkState, setBenchmarkState] = useState<BenchmarkStateProps>({
		benchmarkLoaded: false,
	});
	const [filteredInstallationsState, setFilteredInstallationsState] = useState<
		meteringplanBenchmark[]
	>([]);
	const [numberSiteFiltersRange, setNumberSiteFiltersRange] = useState<
		Record<keyof NumberSiteFilters, number[]>
	>({} as Record<keyof NumberSiteFilters, number[]>);

	// only extrapolate if some sites lacks data on some days
	const areSiteLackingDays = useMemo(() => {
		return benchmarkState.dataCumul
			? benchmarkState.dataCumul
					.array("days_ratio")
					.some((ratio: number | "") => ratio !== "" && ratio < 100)
			: false;
	}, [benchmarkState.dataCumul]);
	const isDataYearly = useMemo(() => {
		return benchmarkState.dateRange &&
			benchmarkState.maxDaysCount &&
			benchmarkState.dateRange[0] &&
			benchmarkState.dateRange[1]
			? isDataPerYear(
					benchmarkState.dateRange as [Date, Date],
					benchmarkState.maxDaysCount,
				)
			: false;
	}, [benchmarkState.maxDaysCount]);

	const processBenchmark = (
		installations: meteringplanBenchmark[],
		responseTable: ColumnTable,
		dateRange?: DateRangeDate,
	) => {
		// based on selected installations and response from API

		let { start, end, end_tz, last12Months, last12MonthsIso, maxDaysCount } =
			benchmarkZonedDates(installations);
		const all_uuids = installations.map((installation) => installation.data_id);
		// filter responseTable based on installations
		const filteredTable = responseTable.filter(
			aq.escape((d: Struct) => all_uuids.includes(d.installation_uuid)),
		);

		const surfaceData = installations.reduce(
			(data, installation) => {
				data.installation_uuid.push(installation.data_id);
				data.installation_label.push(installation.label);
				data.surface.push(installation.surface);
				data.site_label.push(installation.site_label);
				data.site_uuid.push(installation.site_uuid);
				// truncate dates to only have ISO format
				data.date_begin_customer.push(
					installation.date_begin_customer
						? new Date(installation.date_begin_customer)
						: null,
				);
				data.date_end_customer.push(
					installation.date_end_customer
						? new Date(installation.date_end_customer)
						: null,
				);
				return data;
			},
			{
				installation_uuid: [],
				installation_label: [],
				surface: [],
				site_label: [],
				site_uuid: [],
				date_begin_customer: [],
				date_end_customer: [],
			} as {
				installation_uuid: string[];
				installation_label: string[];
				surface: (number | null)[];
				site_label: string[];
				site_uuid: string[];
				date_begin_customer: (Date | null)[];
				date_end_customer: (Date | null)[];
			},
		);
		const installationTable = aq.table(surfaceData);
		const installationsWithData: string[] = filteredTable
			.groupby("installation_uuid")
			.count()
			.array("installation_uuid");

		// filter across dates first
		let restrictedTable = filteredTable;
		if (!dateRange && last12MonthsIso[0] && last12MonthsIso[1]) {
			restrictedTable = restrictTablePeriod(aq, filteredTable, last12MonthsIso);
		} else if (dateRange && dateRange[0] && dateRange[1]) {
			restrictedTable = restrictTablePeriod(aq, filteredTable, [
				dateFormatISO(dateRange[0]),
				dateFormatISO(dateRange[1]),
			]);
			maxDaysCount = differenceInCalendarDays(dateRange[1], dateRange[0]) + 1;
		}

		let dataCumul = getMCByInstallation(
			aq,
			restrictedTable,
			maxDaysCount,
			installationTable,
		);
		// add installations with data but not in restricted period
		const installationsWithDataForPeriod: string[] =
			dataCumul.array("installation_uuid");
		installationsWithData
			.filter((uuid) => !installationsWithDataForPeriod.includes(uuid))
			.map((uuid: string) => {
				dataCumul = dataCumul.concat(
					aq.table({
						installation_uuid: [uuid],
						total: [""],
						performance: [""],
						days_count: [""],
						days_ratio: [""],
					}),
				);
			});

		const siteLabelMapping = surfaceData.installation_uuid.reduce(
			(mapping, uuid, index) => {
				// mapping need to have ONLY existing keys in the Table, i.e. installations that have data for period
				if (installationsWithData.includes(uuid))
					mapping[uuid] = surfaceData.site_label[index];
				return mapping;
			},
			{} as Record<string, string>,
		);

		// construct table that indicates which installation is active for all dates in data
		const activeSiteByDate = getActiveSiteByDate(
			aq,
			filteredTable,
			installationTable,
		);

		// needs to have total per site to pick the 9 (at most) consuming sites to be used with dataEvolutionSite
		const totalPerSite = filteredTable
			.groupby("installation_uuid")
			.rollup(getAggColumns(aq, ["total"], []))
			.join_left(installationTable, "installation_uuid")
			.orderby(aq.desc("total"))
			.reify();
		// calculate evolution across all history
		const dataEvolution = getMCByPeriod(aq, filteredTable, activeSiteByDate);
		const sitesToInclude = totalPerSite
			.array("site_label")
			.slice(0, BENCHMARK_SITES_NB)
			.filter(
				(site: string) =>
					site !== undefined && Object.values(siteLabelMapping).includes(site),
			);
		const dataEvolutionSite = getInstallationTotalByPeriod(
			aq,
			filteredTable,
			activeSiteByDate,
			siteLabelMapping,
			sitesToInclude,
		);

		const savedExtrapolationValue = localStorage.getItem(
			"multisite-extrapolation",
		);
		if (savedExtrapolationValue)
			setExtrapolation(savedExtrapolationValue as ExtrapolationType);

		// merge columns 3 (Ventilation) & 4 (HVAC Auxiliaries) into 3 (Ventilation)
		// better to merge after aggregation (less rows to merge) even it it means to call the function multiple times ?
		dataCumul = mergeTwoColumns(
			aq,
			dataCumul,
			BENCHMARK_VENTILATION_ID.toString(),
			BENCHMARK_AUXILIARIES_ID.toString(),
		);
		Object.entries(dataEvolution).map((evolutionItem) => {
			const period = evolutionItem[0] as keyof PeriodDataTable;
			const periodTable = evolutionItem[1];
			if (periodTable)
				dataEvolution[period as keyof PeriodDataTable] = mergeTwoColumns(
					aq,
					periodTable,
					BENCHMARK_VENTILATION_ID.toString(),
					BENCHMARK_AUXILIARIES_ID.toString(),
				);
		});

		setBenchmarkState({
			maxDaysCount: maxDaysCount,
			dataCumul: dataCumul,
			dataEvolution: dataEvolution,
			dataEvolutionSite: dataEvolutionSite,
			minDataDate: start,
			maxDataDate: end,
			dateRange: dateRange ? dateRange : last12Months,
			maxDateTz: end_tz,
			benchmarkLoaded: true,
		});
	};

	const loadBenchmark = (
		installations?: meteringplanBenchmark[],
		abort_signal?: AbortSignal,
	) => {
		// make API call to retrieve benchmark
		if (installations && installations.length > 0) {
			getBenchmark(
				installations.map((installation) => installation.data_id),
				abort_signal,
			).then((responseTable: ColumnTable) => {
				if (responseTable.size > 0) {
					// save result from API in state to filter on it afterwards
					setBenchmarkData(responseTable);
					processBenchmark(installations, responseTable);
					setFilteredInstallationsState(installations);
				} else {
					// responseTable is empty because call to API failed
					if (!abort_signal || !abort_signal.aborted)
						setBenchmarkState({ benchmarkLoaded: true });
				}
			});
		} else {
			if (MultisitesCount && MultisitesCount > 800) {
				// set benchmarkLoaded to true so multisiteFlat can be processed and filtered
				setBenchmarkState({ benchmarkLoaded: true });
			}
		}
	};

	useEffect(() => {
		addArqueroFunctions(aq);
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		if (nodesLoaded && benchmarkState.benchmarkLoaded)
			setBenchmarkState({ benchmarkLoaded: false });
		loadBenchmark(MultiSitesInstallations, controller.signal);

		return () => {
			controller.abort();
		};
	}, [MultiSitesInstallations, nodesLoaded, MultiSites]);

	useEffect(() => {
		if (
			MultiSites &&
			((!isArray(MultiSites) && params.customer === MultiSites.uuid) ||
				(isArray(MultiSites) && params.customer === "all"))
		) {
			let searchableSites: SiteItemCustomerChild[] = returnChild(MultiSites);
			// if MultiSite does not have children
			if (!isArray(searchableSites)) searchableSites = [searchableSites];

			searchableSites.map((site: Record<string, any>) => {
				const defaultInstallation = MultiSitesInstallations
					? MultiSitesInstallations.find(
							(installation) => installation.site_uuid === site.uuid,
						)
					: undefined;
				site.benchmarkInstallation = defaultInstallation?.label;
				if (benchmarkState.dataCumul && defaultInstallation !== undefined) {
					const filterData = benchmarkState.dataCumul.filter(
						aq.escape(
							(d: Struct) =>
								d.installation_uuid === defaultInstallation?.data_id,
						),
					);
					const totalEnergy = filterData.get("total");
					site.consumption = totalEnergy ? totalEnergy : "";
					site.consumptionkWh = site.consumption ? site.consumption / 1000 : "";
					site.consumptionTitle = formatUnit(
						site.consumption,
						getUnit("energy"),
						false,
						currentLang.numberLocale,
					);
					site.performance = filterData.get("performance");
					site.performanceTitle =
						formatUnit(
							site.performance,
							false,
							false,
							currentLang.numberLocale,
						) +
						" " +
						getUnit("performance") +
						(isDataYearly &&
						(extrapolation === "extrapolated" || !areSiteLackingDays)
							? t`/year`
							: "");
					site.daysCount = filterData.get("days_count");
					// surface used to calculate performance
					site.performanceSurface = filterData.get("surface");
				} else {
					// put - so that it is sorted in last
					site.consumption = "";
					site.consumptionkWh = "";
					site.performance = "";
				}
				site.buildingSurfaceTitle =
					site.buildingSurface === null
						? "-"
						: formatNumberLocale(
								site.buildingSurface,
								currentLang.numberLocale,
							) + t` m²`;
				if (siteSchema?.properties.mainActivity) {
					site.mainActivityTitle = getEnumTitle(
						"mainActivity",
						site.mainActivity,
						siteSchema,
					);
				}
				if (siteSchema?.properties.constructionYear) {
					site.constructionYearTitle = getEnumTitle(
						"constructionYear",
						site.constructionYear,
						siteSchema,
					);
				}
				if (siteSchema?.properties.heating) {
					site.heatingTitle =
						site.heating && site.heating.length > 0
							? site.heating
									.map((item: string) =>
										getEnumTitle("heating", item, siteSchema),
									)
									.join(", ")
							: "-";
				}
			});

			// get min/max range for numberSiteFilters
			const allRange = {} as Record<keyof NumberSiteFilters, number[]>;
			numberSiteFilters.map((filterType) => {
				const allValues = searchableSites
					.filter((item) => item[filterType] !== null)
					.map((item) => item[filterType]) as number[];
				allRange[filterType] =
					allValues.length > 0
						? [Math.min(...allValues), Math.max(...allValues)]
						: [];
			});
			setNumberSiteFiltersRange(allRange);
			setMultisiteFlat(searchableSites);
		}
	}, [
		MultiSites,
		nodesLoaded,
		benchmarkState.benchmarkLoaded,
		benchmarkState.dataCumul,
		benchmarkState.dateRange,
	]);

	useEffect(() => {
		if (benchmarkState.benchmarkLoaded) {
			const sitesToBeFiltered = filtersSites(multisiteFlat, filters);
			setFilteredSites(sitesToBeFiltered);
			// retrieve corresponding installations to filter Benchmark on desired sites

			const filteredInstallations = MultiSitesInstallations
				? areFiltersActive
					? MultiSitesInstallations.filter((installation) =>
							sitesToBeFiltered
								.map((site) => site.uuid)
								.includes(installation.site_uuid),
						)
					: MultiSitesInstallations
				: [];
			setFilteredInstallationsState(filteredInstallations);
			if (benchmarkData) {
				processBenchmark(
					filteredInstallations,
					benchmarkData,
					benchmarkState.dateRange,
				);
			}
		}
	}, [filters]);

	useEffect(() => {
		return () => {
			setFilters({ ...initialFilters });
		};
	}, [MultiSites]);

	useEffect(() => {
		if (benchmarkState.dataCumul && benchmarkState.maxDaysCount) {
			if (extrapolation === "extrapolated") {
				if (areSiteLackingDays) {
					const extrapolatedDataCumul = extrapolateBenchmark(
						aq,
						benchmarkState.dataCumul,
						benchmarkState.maxDaysCount,
					);
					if (extrapolatedDataCumul) {
						setBenchmarkState({
							...benchmarkState,
							dataCumul: extrapolatedDataCumul,
						});
					}
				}
			} else if (extrapolation === "real") {
				const deExtrapolatedDataCumul = deExtrapolateBenchmark(
					aq,
					benchmarkState.dataCumul,
					benchmarkState.maxDaysCount,
				);
				if (deExtrapolatedDataCumul)
					setBenchmarkState({
						...benchmarkState,
						dataCumul: deExtrapolatedDataCumul,
					});
			}
			localStorage.setItem("multisite-extrapolation", extrapolation);
		}
	}, [extrapolation]);

	const setDateRange = (dateRange: DateRangeDate) => {
		if (benchmarkState.benchmarkLoaded) {
			const filteredInstallations = MultiSitesInstallations
				? areFiltersActive
					? MultiSitesInstallations.filter((installation) =>
							filteredSites
								.map((site) => site.uuid)
								.includes(installation.site_uuid),
						)
					: MultiSitesInstallations
				: [];
			if (benchmarkData) {
				processBenchmark(filteredInstallations, benchmarkData, dateRange);
			}
		}
	};

	return (
		<BenchmarkContext.Provider
			value={{
				multisiteFlat,
				filteredSites: areFiltersActive ? filteredSites : multisiteFlat,
				filters,
				setFilters,
				areFiltersActive,
				allFiltersDetails,
				numberSiteFiltersRange,
				loadBenchmark,
				setDateRange,
				extrapolation,
				setExtrapolation,
				areSiteLackingDays,
				isExtrapolationActive:
					areSiteLackingDays && extrapolation === "extrapolated",
				isDataYearly,
				openDialogGenericUsageContext,
				setOpenDialogGenericUsageContext,
				filteredInstallationsState,
				setBenchmarkState,
				...benchmarkState,
			}}
		>
			{children}
		</BenchmarkContext.Provider>
	);
}

export { BenchmarkContext, BenchmarkProvider };
