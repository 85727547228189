import type { DateRange } from "@mui/lab";
import type React from "react";
import type { Dispatch } from "react";
import type { ElectricityTypes, TemperatureTypes } from "src/@types/charts";
import type { Shortcuts } from "src/components/ShortCut/shortCut";
import type { SiteContent } from "./SiteContent";
import type { SelectedDays } from "./SpecialDay";
import type { meteringplanBaseObject } from "./installation";

// ----------------------------------------------------------------------

export type ActionMagicButtonMap<M extends { [index: string]: any }> = {
	[Key in keyof M]: {
		type: Key;
		payload: M[Key];
	};
};

export type Category = {
	id: number;
	label: string;
	color: string;
	description?: string;
	macrocategories?: {
		id: number;
		label: string;
		weight: number;
		color: string;
		icon_name?: string;
	}[];
};
export type DateRangeDate = DateRange<Date>;

export type DateSelection = {
	dateRange: DateRangeDate;
	shortcut: Shortcuts | undefined;
};

// Remember to update its json schema with:
// ./node_modules/.bin/ts-json-schema-generator --path src/@types/magic --type 'MagicButtonUserSettings' --tsconfig tsconfig.json > src/schemas/MagicButtonUserSettings.json
// (NOT src/@types/magic.ts, otherwise typescript error on Theme customShadows)
export type NodeAPIType = "customer" | "site_group" | "site" | "installation";
export type DashboardType = "monosite" | "multisite";
export type Ratio = "without" | "surface" | "people";
export type DataEvolutionType = "end-use" | "site";
export type MagicButtonUserSettings = {
	conso?: "consumption" | "currency" | "co2";
	ratio?: Ratio;
	temperature?: TemperatureTypes;
	yAxis: "auto" | "selection" | "manual";
	withUnoccupation: boolean;
	withEvents: boolean;
};

export type MagicButtonState = MagicButtonUserSettings & {
	chartType: ElectricityTypes;
	installation: string;
	installationLabel: string;
	installationObject?: meteringplanBaseObject;
	dashboard: DashboardType;
	node: string;
	nodeLabel?: string;
	site?: SiteContent;
	minDate: Date | null;
	maxDate: Date | null;
	category: Category["id"];
	categoryLabel: Category["label"];
	categories: Category[];
	selectedDays: SelectedDays;
	// currency: number | null;
	// co2: number | null;
	surface: number | null;
	// people: number | null;
	hdd: number | null;
	cdd: number | null;
	maxPower: number | null;
	maxEnergy: number | null;
	mainDates: DateSelection;
	compareDates: DateSelection;
	alertsDates?: DateSelection;
	sharedNotes: string | null;
	alertPeriodDates?: DateSelection;
	alertActiveDates?: DateSelection;
	alertThreshold: number | null;
	maxDaysCount?: number;
	multisiteTz?: string;
	dataEvolutionType?: DataEvolutionType;
};

export type DateKeys = "mainDates" | "compareDates";

// make all field in MagicButtonState optional & Pick which one are required
export type MagicButtonGraphState = Partial<MagicButtonState> &
	Pick<
		MagicButtonState,
		| "dashboard"
		| "mainDates"
		| "compareDates"
		| "selectedDays"
		| "chartType"
		| "categories"
		| "category"
		| "categoryLabel"
		| "ratio"
		| "surface"
		| "conso"
		| "temperature"
		| "hdd"
		| "cdd"
		| "minDate"
		| "maxDate"
		| "dataEvolutionType"
	>;

export enum MagicActionTypes {
	Input = 0, // = 'input',
	List = 1, // = 'list',
	ToggleButton = 2, // = 'toggleButton',
	Tree = 3, // = 'tree',
	DaysChecked = 4, // = "daysChecked",
	Switch = 5, // = "switch",
	DateRange = 6, // = "dateRange",
	sharedNotes = 7,
	updateStateAction = 8,
}

// type
export type MagicbuttonPayload = {
	[MagicActionTypes.Input]: {
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>;
	};
	[MagicActionTypes.List]: {
		id: keyof MagicButtonState;
		value: string | number;
	};
	[MagicActionTypes.ToggleButton]: {
		event: React.MouseEvent<HTMLElement, MouseEvent>;
	};
	[MagicActionTypes.Tree]: {
		nodeId?: string;
		installationId: string;
		Site: SiteContent;
	};
	[MagicActionTypes.DaysChecked]: {
		value: SelectedDays;
	};
	[MagicActionTypes.Switch]: {
		event: React.ChangeEvent<HTMLInputElement>;
	};
	[MagicActionTypes.DateRange]:
		| { mainDates: DateSelection }
		| { compareDates: DateSelection };
	[MagicActionTypes.sharedNotes]: { sharedNotes: string };
	[MagicActionTypes.updateStateAction]: { newState: MagicButtonState };
};

export type MagicButtonActions =
	ActionMagicButtonMap<MagicbuttonPayload>[keyof ActionMagicButtonMap<MagicbuttonPayload>];

export type MagicButtonContextType = {
	state: MagicButtonState;
	dispatchMagic: Dispatch<MagicButtonActions>;
	dateLabel: (val: DateKeys) => string;
};
