// TODO filter by pathname in treecomponent build

import { ElectricityTypes } from "src/@types/charts";
import type { intervalLengthEnum } from "src/@types/installation";
import type { OfflineIcons } from "src/utils/icon";
import { translateInterval } from "src/utils/translateInterval";
import { t } from "ttag";

// formattage date tooltip
// formattage date axe
// action au clic sur une barre ?

// contexte : chartType = "EnergyMonth"
// contexte : chartType = {type: "EnergyMonth", label = "" , ...}

// fonction qui trouve le child à partir du type.

export type chartParameterItem = {
	label: string | ((interval_length: intervalLengthEnum) => string);
	icon: OfflineIcons;
	type: ElectricityTypes;
	needsPublic: boolean;
	disabled?: boolean;
};

type chartParameterList = {
	title: string;
	type?: string;
	children: chartParameterItem[];
};

export const chartParameters = (): chartParameterList[] => [
	{
		title: t`Energy by end-use`,
		children: [
			{
				label: t`Energy per year`,
				icon: "ph:chart-bar-duotone",
				type: ElectricityTypes.EnergyYear,
				needsPublic: true,
			},
			{
				label: t`Energy per month`,
				icon: "ph:chart-bar-duotone",
				type: ElectricityTypes.EnergyMonth,
				needsPublic: true,
			},
			{
				label: t`Energy per week`,
				icon: "ph:chart-bar-duotone",
				type: ElectricityTypes.EnergyWeek,
				needsPublic: true,
			},
			{
				label: t`Energy per day`,
				icon: "ph:chart-bar-duotone",
				type: ElectricityTypes.EnergyDay,
				needsPublic: true,
			},
			{
				label: t`Pluriannual evolution`,
				icon: "ph:chart-bar-duotone",
				type: ElectricityTypes.PluriannualEvolution,
				needsPublic: true,
			},
			{
				label: t`Breakdown`,
				icon: "ph:chart-pie-slice-duotone",
				type: ElectricityTypes.EnergyDonut,
				needsPublic: true,
			},
			// {
			// 	label: t`Consumption by zone by end-use`,
			// 	icon: "ph:chart-bar-duotone",
			// 	type: ElectricityTypes.Benchmark,
			// 	needsPublic: true,
			// },
		],
	},
	{
		title: t`Power by end-use`,
		type: "power",
		children: [
			{
				label: (interval_length: intervalLengthEnum) => {
					const interval_length_str = translateInterval(interval_length);
					return t`Power at ${interval_length_str}`;
				},
				icon: "ph:chart-line-duotone",
				type: ElectricityTypes.Power,
				needsPublic: true,
			},
			{
				label: t`Typical week`,
				icon: "ph:chart-line-duotone",
				type: ElectricityTypes.TypicalWeek,
				needsPublic: true,
			},
			{
				label: t`Typical day`,
				icon: "ph:chart-line-duotone",
				type: ElectricityTypes.TypicalDay,
				needsPublic: true,
			},
		],
	},
	{
		title: t`Real-time load curve`,
		children: [
			{
				label: (interval_length: intervalLengthEnum) => {
					const interval_length_str = translateInterval(interval_length);
					return t`Load curve at ${interval_length_str}`;
				},
				icon: "ph:chart-line-duotone",
				type: ElectricityTypes.Loadcurve,
				needsPublic: false,
				// needsPublic: true //for test only !!!,
			},
		],
	},
	{
		title: t`Compare time periods`,
		children: [
			{
				label: t`Compare typical days`,
				icon: "ph:chart-line-duotone",
				type: ElectricityTypes.CompareTypicalDay,
				needsPublic: true,
			},
			{
				label: t`Compare typical weeks`,
				icon: "ph:chart-line-duotone",
				disabled: true,
				type: ElectricityTypes.CompareTypicalWeek,
				needsPublic: true,
			},
			// { label: 'Compare Power', icon: "ph:chart-line-duotone",disabled: true, type: ElectricityTypes.CompareEnergy, needsPublic:true },
			{
				label: t`Compare energy`,
				icon: "ph:chart-bar-duotone",
				disabled: true,
				type: ElectricityTypes.CompareEnergy,
				needsPublic: true,
			},
			{
				label: t`Site comparison by end-use`,
				icon: "ph:gauge-duotone",
				disabled: true,
				type: ElectricityTypes.Benchmark,
				needsPublic: true,
			},
		],
	},
	{
		title: t`Advanced analysis`,
		children: [
			{
				label: t`Quality of Energy`,
				icon: "ph:lightning-duotone",
				disabled: true,
				type: ElectricityTypes.QoE,
				needsPublic: false,
			},
			{
				label: t`Subscribed power`,
				icon: "ph:coins-duotone",
				disabled: true,
				type: ElectricityTypes.SubscribedPower,
				needsPublic: false,
			},
		],
	},
];
export const getUnitbyChartType = (chartType: ElectricityTypes) => {
	// console.log(Type)

	switch (chartType) {
		case ElectricityTypes.EnergyDay:
			// Monday 03/02/2018
			return "cccc P";
		case ElectricityTypes.EnergyWeek:
			// S3 2022
			// return "ww y"
			return "I R";

		case ElectricityTypes.EnergyMonth:
			// January 2021
			return "LLLL y";

		case ElectricityTypes.PluriannualEvolution:
			// Custom
			return "LLLL y";

		case ElectricityTypes.EnergyYear:
			// 2022
			return "y";

		case ElectricityTypes.Loadcurve:
		case ElectricityTypes.Power:
			// 06:00 03/02/2018
			return "cccc P p";

		case ElectricityTypes.CompareTypicalDay:
		case ElectricityTypes.TypicalDay:
			// 06:00
			return "p";

		case ElectricityTypes.TypicalWeek:
			// Monday 06:00
			return "cccc p";

		default:
			return "P";

		// break;
	}
};
export const getUnitbyChartTypeP = (chartType: ElectricityTypes) => {
	// console.log(Type)

	switch (chartType) {
		case ElectricityTypes.EnergyDay:
			// Monday 03/02/2018
			return "P";
		case ElectricityTypes.EnergyWeek:
			// S3 2022
			// return "ww y"
			return "P";

		case ElectricityTypes.EnergyMonth:
			// January 2021
			return "P";

		case ElectricityTypes.PluriannualEvolution:
			// Custom
			return "P";

		case ElectricityTypes.EnergyYear:
			// 2022
			return "P";

		case ElectricityTypes.Loadcurve:
		case ElectricityTypes.Power:
			// 06:00 03/02/2018
			// return "cccc P p"
			return "P";

		case ElectricityTypes.CompareTypicalDay:
		case ElectricityTypes.TypicalDay:
			// 06:00
			return "a";

		case ElectricityTypes.TypicalWeek:
			// Monday 06:00
			return "cccc p";

		default:
			return "P";

		// break;
	}
};

export function findByType(chartType: ElectricityTypes) {
	for (const row of chartParameters()) {
		for (const child of row.children) {
			if (child.type === chartType) return child;
		}
	}
	throw new Error("Missing chartType");
}
