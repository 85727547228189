import { isArray } from "lodash";
import getPropertyFromSchema from "src/components/dashboard/Metadata/functions/getPropertyFromSchema";
import type { OfflineIcons } from "src/utils/icon";
import { t } from "ttag";

export interface oneOfItem {
	enum: string[];
	title: string;
	[key: string]: any;
}

export function isOneOfItem(variable: any): variable is oneOfItem {
	return (
		variable instanceof Object &&
		isArray(variable.enum) &&
		variable.enum.every((item: any) => typeof item === "string") &&
		typeof variable.title === "string"
	);
}

type Item = {
	anyOf?: oneOfItem[];
	type?: string;
	properties?: Record<string, metadataProperty>;
};

export type metadataProperty = {
	title: string;
	type: string;
	enum?: string[];
	enumNames?: string[];
	oneOf?: oneOfItem[];
	anyOf?: oneOfItem[];
	items?: Item;
	minimum?: number;
	properties?: Record<string, metadataProperty>;
	uniqueItems?: boolean;
	$ref?: string;
	isImportant?: boolean;
	isRequired?: boolean;
	icon?: OfflineIcons;
	[key: string]: any;
};

export type metadataSchema = {
	definitions: Record<string, metadataProperty>;
	description: string;
	properties: Record<string, metadataProperty>;
	title: string;
	type: string;
};

export type otherKpi = {
	kpi: string;
	number: number | string;
	startDate: string | Date;
	id?: string;
};

export type SiteFilters = {
	mainActivity: oneOfItem[];
	heating: oneOfItem[];
	cooling: oneOfItem[];
	constructionYear: oneOfItem[];
	country_code: oneOfItem[];
	label: string[];
	buildingSurface: number[];
};
export const initialFilters: SiteFilters = {
	mainActivity: [],
	heating: [],
	cooling: [],
	constructionYear: [],
	country_code: [],
	label: [],
	buildingSurface: [],
};

export type SimpleSiteFilters = Omit<
	SiteFilters,
	"buildingSurface" | "heating" | "cooling"
>;
export const simpleSiteFilters: (keyof SimpleSiteFilters)[] = [
	"label",
	"mainActivity",
	"constructionYear",
	"country_code",
];

export type NumberSiteFilters = Pick<SiteFilters, "buildingSurface">;
export const numberSiteFilters: (keyof NumberSiteFilters)[] = [
	"buildingSurface",
];

export type ArraySiteFilters = Pick<SiteFilters, "heating" | "cooling">;
export const arraySiteFilters: (keyof ArraySiteFilters)[] = [
	"heating",
	"cooling",
];

export type FilterDetails = {
	title: string;
	placeholder: string;
	options?: oneOfItem[];
	unit?: string;
};
