import { getTime } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { dropWhile, max, min, takeWhile } from "lodash";
import type { APIGraphSeries, Point } from "src/@types/graphSeries";
import {
	type Frequency,
	endOfPeriod,
	startOfPeriod,
} from "src/utils/timeFormatter";

export type TimeRange = [number, number];

export function getTimeRange(apiSeries: APIGraphSeries) {
	const timestamps = apiSeries.data
		.filter((x) => x !== null)
		.map((pair) => (pair as [number, number])[0]);
	if (timestamps.length) {
		return [timestamps[0], timestamps[timestamps.length - 1]] as TimeRange;
	}
}

export const filterOnElecRange = (
	electricity_data: APIGraphSeries[],
	temperature_data: APIGraphSeries[],
	tz: string,
	freq: Frequency,
): [TimeRange | undefined, APIGraphSeries[]] => {
	// find min/max timestamps
	const elec_time_ranges = electricity_data
		.map(getTimeRange)
		.filter((val) => val !== undefined) as TimeRange[];
	const elec_min_ts = min(elec_time_ranges.map((rng) => rng[0]));
	const elec_max_ts = max(elec_time_ranges.map((rng) => rng[1]));

	const elec_range =
		elec_min_ts && elec_max_ts
			? ([elec_min_ts, elec_max_ts] as TimeRange)
			: undefined;

	const elec_min = elec_min_ts
		? getTime(zonedTimeToUtc(startOfPeriod(elec_min_ts, tz, freq), tz))
		: undefined;
	const elec_max = elec_max_ts
		? getTime(zonedTimeToUtc(endOfPeriod(elec_max_ts, tz, freq), tz))
		: undefined;

	const filtered_temperature_data = temperature_data.map(
		(temperature_series) => {
			let filtered_data: Point[];
			if (elec_min && elec_max) {
				filtered_data = dropWhile(temperature_series.data, (point) =>
					point ? +point[0] < elec_min : true,
				);
				filtered_data = takeWhile(filtered_data, (point) =>
					point ? +point[0] <= elec_max : true,
				);
			} else {
				filtered_data = [];
			}
			return {
				...temperature_series,
				data: filtered_data,
			};
		},
	);

	return [elec_range, filtered_temperature_data];
};

export const restrictPeriod = (
	series: APIGraphSeries[],
	filterPeriod: [Date, Date] | undefined | null,
	tz: string,
	includeEnd?: boolean,
) => {
	if (filterPeriod) {
		const limitStart = getTime(zonedTimeToUtc(filterPeriod[0], tz));
		const limitEnd = getTime(zonedTimeToUtc(filterPeriod[1], tz));
		// console.debug('restrictPeriod', limitStart, limitEnd)
		return series?.map((single_series) => {
			return {
				...single_series,
				data: single_series.data.filter(
					(point) =>
						!point ||
						(+point[0] >= limitStart &&
							(includeEnd ? +point[0] <= limitEnd : +point[0] < limitEnd)),
				),
			};
		});
	}
	return series;
};
