import type { metadataSchema, oneOfItem } from "src/@types/metadataSchema";
import getPropertyFromSchema from "./getPropertyFromSchema";

export default (
	propertyKey: string,
	metadataValue: string,
	schema?: metadataSchema,
) => {
	if (schema === undefined) return "-";
	let property = getPropertyFromSchema(schema, propertyKey);
	let enumVal = undefined;
	if (property.items) {
		property = property.items;
	}
	if (property.anyOf) {
		enumVal = property.anyOf.find(
			(item: oneOfItem) => item.enum[0] === metadataValue,
		);
	} else if (property.oneOf) {
		enumVal = property.oneOf.find(
			(item: oneOfItem) => item.enum[0] === metadataValue,
		);
	}
	return enumVal && enumVal.title ? enumVal.title : "-";
};
