// ----------------------------------------------------------------------

export default function SmartXIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.6 6.33315H2.4C1.95817 6.33315 1.6 6.69133 1.6 7.13315V18.3332C1.6 18.775 1.95817 19.1332 2.4 19.1332H21.6C22.0418 19.1332 22.4 18.775 22.4 18.3332V7.13315C22.4 6.69133 22.0418 6.33315 21.6 6.33315ZM2.4 4.73315C1.07452 4.73315 0 5.80767 0 7.13315V18.3332C0 19.6586 1.07452 20.7332 2.4 20.7332H21.6C22.9255 20.7332 24 19.6586 24 18.3332V7.13315C24 5.80767 22.9255 4.73315 21.6 4.73315H2.4Z"
				fill="#7BC622"
			/>
			<path
				d="M3.19971 7.9333C3.19971 7.49147 3.55788 7.1333 3.99971 7.1333H19.9997C20.4415 7.1333 20.7997 7.49147 20.7997 7.9333V17.5333C20.7997 17.9751 20.4415 18.3333 19.9997 18.3333H3.99971C3.55788 18.3333 3.19971 17.9751 3.19971 17.5333V7.9333Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.9997 7.9333H3.99971V17.5333H19.9997V7.9333ZM3.99971 7.1333C3.55788 7.1333 3.19971 7.49147 3.19971 7.9333V17.5333C3.19971 17.9751 3.55788 18.3333 3.99971 18.3333H19.9997C20.4415 18.3333 20.7997 17.9751 20.7997 17.5333V7.9333C20.7997 7.49147 20.4415 7.1333 19.9997 7.1333H3.99971Z"
				fill="black"
			/>
			<path
				d="M19.9997 12.3333C19.9997 12.5543 19.8206 12.7333 19.5997 12.7333C19.3788 12.7333 19.1997 12.5543 19.1997 12.3333C19.1997 12.1124 19.3788 11.9333 19.5997 11.9333C19.8206 11.9333 19.9997 12.1124 19.9997 12.3333Z"
				fill="white"
				fillOpacity="0.15"
			/>
			<path
				d="M4.79932 8.73328H18.3993V16.7333H4.79932V8.73328Z"
				fill="white"
				fillOpacity="0.15"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.91654 10.9947C8.54758 11.3015 8.09577 11.8954 7.55343 12.9202C7.45009 13.1155 7.20803 13.19 7.01278 13.0867C6.81752 12.9833 6.74301 12.7413 6.84634 12.546C7.404 11.4923 7.91469 10.7873 8.4051 10.3795C8.91388 9.95656 9.4408 9.82681 9.95379 10.0207C10.4174 10.1959 10.7852 10.6086 11.0866 11.0441C11.3924 11.4858 11.6765 12.0228 11.9465 12.5329L11.9534 12.546C12.2314 13.0713 12.495 13.5681 12.771 13.9669C13.054 14.3758 13.3049 14.6126 13.5288 14.6972C13.7033 14.7632 13.9326 14.763 14.2832 14.4715C14.6522 14.1648 15.104 13.5708 15.6463 12.546C15.7497 12.3508 15.9917 12.2762 16.187 12.3796C16.3822 12.4829 16.4568 12.725 16.3534 12.9202C15.7958 13.9739 15.2851 14.679 14.7947 15.0867C14.2859 15.5097 13.759 15.6394 13.246 15.4456C12.7824 15.2704 12.4145 14.8576 12.1132 14.4222C11.8074 13.9804 11.5232 13.4434 11.2533 12.9334L11.2463 12.9202C10.9684 12.395 10.7048 11.8981 10.4288 11.4993C10.1458 11.0904 9.89487 10.8536 9.67098 10.769C9.49647 10.7031 9.26714 10.7032 8.91654 10.9947Z"
				fill="black"
				fillOpacity="0.1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0002 3.65859C11.5422 3.65859 11.1021 3.8356 10.7716 4.15262C10.6391 4.27974 10.4287 4.27538 10.3015 4.14288C10.1744 4.01038 10.1788 3.79992 10.3113 3.6728C10.7655 3.23699 11.3707 2.99365 12.0002 2.99365C12.6296 2.99365 13.2348 3.23699 13.689 3.6728C13.8215 3.79992 13.8259 4.01038 13.6988 4.14288C13.5716 4.27538 13.3612 4.27974 13.2287 4.15262C12.8983 3.8356 12.4581 3.65859 12.0002 3.65859Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9998 1.66493C11.015 1.66493 10.0693 2.0499 9.36446 2.73764C9.23304 2.86588 9.02255 2.86329 8.89432 2.73188C8.76608 2.60046 8.76866 2.38997 8.90008 2.26173C9.72911 1.4528 10.8415 1 11.9998 1C13.1581 1 14.2705 1.4528 15.0995 2.26173C15.2309 2.38997 15.2335 2.60046 15.1053 2.73188C14.9771 2.86329 14.7666 2.86588 14.6351 2.73764C13.9303 2.0499 12.9846 1.66493 11.9998 1.66493Z"
				fill="black"
				fillOpacity="0.2"
			/>
		</svg>
	);
}
