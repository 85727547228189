import { t } from "ttag";

export enum SpecialDay {
	OccupiedUnoccupiedDays = "OU",
	OccupiedDays = "O",
	UnoccupiedDays = "U",
}

export const SpecialDayLabel = (val: SpecialDay): string => {
	switch (val) {
		case SpecialDay.OccupiedUnoccupiedDays:
			return t`Occupied/unoccupied days`;
		case SpecialDay.OccupiedDays:
			return t`Occupied days`;
		case SpecialDay.UnoccupiedDays:
			return t`Unoccupied days`;
	}
};

export enum WeekDay {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 7,
}
export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type WeekDays = DayOfWeek[];

export type SelectedDays = SpecialDay | WeekDays;

export const dayParameters: WeekDays = [
	1,
	2,
	3,
	4,
	5,
	6,
	7, // `sunday`,
];
