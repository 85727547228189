// import SearchBar from './searchBar';
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import type { LinkProps } from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { isArray, isEqual } from "lodash";
import * as React from "react";
import {
	type Path,
	NavLink as RouterLink,
	type To,
	useLocation,
	useNavigate,
} from "react-router-dom";
import {
	type chartParameterItem,
	chartParameters,
} from "src/@types/ChartParameters";
import {
	type DayOfWeek,
	type SelectedDays,
	SpecialDay,
	SpecialDayLabel,
	dayParameters,
} from "src/@types/SpecialDay";
import { ElectricityTypes } from "src/@types/charts";
import {
	intervalLengthEnum,
	type meteringplanBaseObject,
} from "src/@types/installation";
import type { Category } from "src/@types/magic";
import { CustomScrollbar } from "src/components/others/Scrollbar";
import { allowedSpecialDays } from "src/contexts/ChartOptionsContext";
import useAuth from "src/hooks/useAuth";
import useMagicButtonContext from "src/hooks/useMagicButtonContext";
import { analysisUrl } from "src/utils/links";
import { serializeSelectedDays } from "src/utils/serializers";
import { dayRenderer } from "src/utils/weekDays";
import { t } from "ttag";
import { Iconify } from "../../../../utils/getIcon";

export interface RouterLinkProps extends LinkProps {
	component?: React.ElementType;
	to?: To;
	end?: boolean;
}

export const buildPath = (
	location: Path,
	items: [search_key: string, search_value: string | undefined][],
): Path => {
	const search = new URLSearchParams(location.search);

	for (const [search_key, search_value] of items) {
		if (search_value === undefined) {
			search.delete(search_key);
		} else {
			search.set(search_key, search_value);
		}
	}
	return {
		...location,
		search: search.toString(),
	};
};

/*eslint dot-notation: off*/
// Custom List
export const StyledListItemButton = styled(ListItemButton)<RouterLinkProps>(
	({ theme }) => ({
		fontWeight: "400",
		color: theme.palette.text.primary,
		py: "6px",
		px: { xs: "10px", sm: "20px" },
		// height: "36px",
		"& *:first-letter": { textTransform: "capitalize" },
		"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
			// backgroundColor: alpha(`var(--item-color, ${theme.palette.primary.main})`, 0.08),
			backgroundColor: alpha(theme.palette.primary.main, 0.08),
			[`& *:not(.MuiListItemText-secondary)`]: {
				// added21022022
				color: `${theme.palette.primary.main}`,
				fontWeight: "bold",
			},
		},
		[`& .MuiListItemIcon-root`]: {
			marginRight: "16px",
			minWidth: "unset",
		},
		[`& .MuiCheckbox-root`]: {
			// padding: "2px",
			marginLeft: "-9px",
		},
		// Added
		[`& .MuiListItemText-root `]: {
			margin: 0,
			padding: "1px",
			[`& .MuiTypography-root `]: {
				lineHeight: "22px",
			},
			// added21022022
			[`& .MuiListItemText-secondary `]: {
				fontSize: "12px",
				lineHeight: "18px",
				color: "disabled",
			},
		},
	}),
);

export const StyledSubHeader = styled(ListSubheader)(() => ({
	py: "6px",
	px: { xs: "10px", sm: "20px" },
	height: "36px",
	lineHeight: "18px",
	[`& *`]: {
		verticalAlign: "middle",
		fontWeight: "bold !important",
		lineHeight: "18px !important",
	},
}));

type DaysSelectorProps = {
	// id: string;
	daysChecked: SelectedDays;
	handleClose?: any;
	chartType: ElectricityTypes;
};

export function DaysSelector(props: DaysSelectorProps) {
	// let initialValue = props.daysChecked
	const { state } = useMagicButtonContext();
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedDays, setSelectedDays] = React.useState<SelectedDays>(
		props.daysChecked,
	);
	const selectedDaysRef = React.useRef<SelectedDays>([]);

	const handleSpecialDay = (value: SpecialDay) => {
		// console.log(value, "selectedDays new value")
		setSelectedDays(value);
	};

	const handleToggle = (value: DayOfWeek) => {
		// console.log(value, "selectedDays new value")

		let newVal: SelectedDays;
		switch (selectedDays) {
			case SpecialDay.OccupiedUnoccupiedDays:
			case SpecialDay.OccupiedDays:
			case SpecialDay.UnoccupiedDays:
				newVal = [value];
				break;
			default:
				const currentIndex = selectedDays.indexOf(value);
				newVal = [...selectedDays];
				if (currentIndex === -1) {
					newVal.push(value);
					newVal.sort();
				} else {
					newVal.splice(currentIndex, 1);
					if (newVal.length === 0) {
						newVal = SpecialDay.OccupiedUnoccupiedDays;
					}
				}
				break;
		}
		setSelectedDays(newVal);
	};

	// Store the value in the ref each time the state changes
	// Otherwise, the state when will unmount will be NOK
	React.useEffect(() => {
		selectedDaysRef.current = selectedDays;
	}, [selectedDays]);

	React.useEffect(() => {
		if (!isEqual(state.selectedDays, selectedDays)) {
			setSelectedDays(state.selectedDays);
			selectedDaysRef.current = selectedDays;
		}
	}, [state.selectedDays]);

	// Dispatch only when component will unmount
	React.useEffect(() => {
		return () => {
			// console.log('selectedDays', state.selectedDays, selectedDaysRef.current)
			if (!isEqual(selectedDaysRef.current, state.selectedDays)) {
				navigate(
					buildPath(location, [
						["days", serializeSelectedDays(selectedDaysRef.current)],
					]),
				);
			}
		};
	}, []);

	return (
		<List
			component="nav"
			id="selectedDays"
			sx={{
				width: "100%",
				bgcolor: "background.paper",
				position: "relative",
				overflow: "auto",
				maxHeight: "80vh",
				...CustomScrollbar(),
				"& ul": { padding: 0, margin: 0 },
			}}
			// subheader={<li />}
		>
			<li key={"occupied/unoccupied"}>
				<ul>
					{allowedSpecialDays(props.chartType).map((special_day) => {
						return (
							<ListItem key={special_day} disablePadding>
								<StyledListItemButton
									dense
									selected={selectedDays === special_day}
									onClick={(_e) => {
										handleSpecialDay(special_day);
										props.handleClose();
									}}
								>
									<ListItemText id={`checkbox-list-label-${special_day}`}>
										<Typography variant="body2">
											{SpecialDayLabel(special_day)}
										</Typography>
									</ListItemText>
								</StyledListItemButton>
							</ListItem>
						);
					})}
				</ul>
			</li>

			<li key={"customWeekDays"}>
				<ul>
					<Divider sx={{ my: "8px", mx: { xs: "10px", sm: "20px" } }} />
					<StyledSubHeader color="primary">
						<Typography variant="overline">{t`Custom week days`}</Typography>
					</StyledSubHeader>
					{dayParameters.map((key) => {
						const label = dayRenderer([key]);
						// const label = day.label;
						const labelId = `checkbox-list-label-${key}`;
						const isSelected =
							isArray(selectedDays) && selectedDays.includes(key);

						return (
							<ListItem key={key} disablePadding>
								<StyledListItemButton
									dense
									selected={isSelected}
									onClick={(_e) => handleToggle(key)}
								>
									<ListItemIcon>
										<Checkbox
											edge="start"
											size="small"
											checked={isSelected}
											tabIndex={-1}
											disableRipple
											inputProps={{ "aria-labelledby": labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId}>
										<Typography variant="body2">{label}</Typography>
									</ListItemText>
								</StyledListItemButton>
							</ListItem>
						);
					})}
				</ul>
			</li>
		</List>
	);
}

export const chartLabel = (
	item: chartParameterItem,
	installation: meteringplanBaseObject | undefined,
) => {
	let itemLabel = item.label;
	if (typeof itemLabel === "function") {
		const interval =
			intervalLengthEnum[installation?.interval_length || "len10min"];
		itemLabel = itemLabel(interval);
	}

	return itemLabel;
};

type ChartSelectorProps = {
	// id: keyof MagicButtonState;
	selected: ElectricityTypes;
	handleClose?: () => void;
};

export function ChartSelector(props: ChartSelectorProps) {
	const { state } = useMagicButtonContext();
	const installation = state.installationObject;
	const { user } = useAuth();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const charts = chartParameters().filter((section) => {
		// hide comparison section if user is not Smart Impulse
		const hidePrivateChart =
			!user?.isSmartImpulse && section.title === t`Compare time periods`;
		// hide sections where all charts are disabled
		const allDisabled = section.children.every((item) => item.disabled);
		return !allDisabled && !hidePrivateChart;
	});

	const handleClose = props.handleClose || (() => {});

	return (
		<List
			component="nav"
			id="chartType"
			sx={{
				width: "100%",
				minWidth: 300,
				bgcolor: "background.paper",
				position: "relative",
				overflow: "auto",
				...(!isMobile && { maxHeight: 500 }),
				"& ul": { padding: 0, margin: 0 },
				maxHeight: "80vh",
				...CustomScrollbar(),
			}}
			className="scroller"
			subheader={<li />}
		>
			{charts.map((section, index) => {
				const label = section.title;

				return (
					<li key={label} id={label}>
						<ul>
							<StyledSubHeader color="primary">
								<Typography variant="overline">{label}</Typography>
							</StyledSubHeader>
							{section.children.map((item) => {
								const isDisplayable =
									(!item.needsPublic || installation?.public) &&
									(item.type === ElectricityTypes.Loadcurve
										? !!state.installationObject?.loadcurve_end
										: true);

								const linkLabelProps: RouterLinkProps = {};
								// link to URL with empty searchParams
								const to = analysisUrl(
									item.type,
									state.node,
									state.installation,
									new URLSearchParams(),
								);
								if (props.selected !== item.type && to !== null) {
									linkLabelProps.to = to;
									linkLabelProps.component = RouterLink;
								}

								// hide disabled items in production for now
								if (item.disabled && process.env.NODE_ENV === "production")
									return null;

								return (
									<StyledListItemButton
										key={item.type}
										selected={props.selected === item.type}
										disabled={item.disabled || !isDisplayable}
										{...(linkLabelProps as any)}
										onClick={() => {
											handleClose();
											// The magicButtonState will be updated via the props of Analysis
										}}
									>
										<ListItemIcon>{Iconify(item.icon)}</ListItemIcon>
										<ListItemText>
											<Typography variant="body2">
												{chartLabel(item, installation)}
											</Typography>
										</ListItemText>
									</StyledListItemButton>
								);
							})}
							{charts.length - 1 !== index && (
								<Divider sx={{ my: "8px", mx: { xs: "10px", sm: "20px" } }} />
							)}
						</ul>
					</li>
				);
			})}
		</List>
	);
}

type ImageExportSelectorProps = {
	chartType: ElectricityTypes;
	dispatch: (
		withLegend?: boolean,
		withTitle?: boolean,
		exportWidth?: number,
		exportHeight?: number,
		exportMethod?: "download" | "newTab",
		key?: string,
	) => void;
	handleClose?: () => void;
};

export function ImageExportSelector(props: ImageExportSelectorProps) {
	// const { state } = useMagicButtonContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClose = props.handleClose || (() => {});
	type exportImageOptionsProps = {
		width: number | undefined;
		height: number | undefined;
		withTitle: boolean;
		withLegend: boolean;
		openNewTab: boolean;
		key: string | undefined;
	};
	const { user } = useAuth();

	const [exportImageOptions, setExportImageOptions] = React.useState({
		width: undefined,
		height: undefined,
		withTitle: false,
		withLegend: user && user.isSmartImpulse ? false : true,
		openNewTab: user && user.isSmartImpulse ? true : false,
		// withLegend: true,
		// openNewTab: false,
		// key:undefined,
	} as exportImageOptionsProps);

	const getImageExportOptions = (chartType: ElectricityTypes) => {
		switch (chartType) {
			case ElectricityTypes.TypicalDay:
				return [
					{
						height: 1000,
						width: 2500,
						title: t`Standard`,
						key: "standard",
					},
					{
						height: 700,
						width: 1070,
						title: t`Image S`,
						secondary: t`Smart DIAG - 30% of the slide`,
						key: "typicalday-s",
					},
					{
						height: 826,
						width: 1526,
						title: t`Image M`,
						secondary: t`Smart DIAG - 50% of the slide`,
						key: "typicalday-m",
					},
				];
			case ElectricityTypes.EnergyDonut:
				return [
					{
						height: 950,
						width: 950,
						title: t`Standard`,
						key: "donut-standard",
					},
				];
			default:
				return [
					{
						height: 1000,
						width: 2500,
						title: t`Standard`,
						key: "standard",
					},
					{
						height: 884,
						width: 2334,
						title: t`Image M`,
						secondary: t`Smart DIAG - 80% of the slide`,
						key: "default-m",
					},
					{
						height: 938,
						width: 3029,
						title: t`Image L`,
						secondary: t`Smart DIAG - 90% of the slide`,
						key: "default-l",
					},
				];
		}
	};

	const myImageExportOptions = getImageExportOptions(props.chartType);

	const handleChange = (key: string) => {
		setExportImageOptions({
			...exportImageOptions,
			[key]: !(exportImageOptions as any)[key],
		});
	};

	type CheckboxItemProps = {
		name: "withTitle" | "withLegend" | "openNewTab";
		title: string;
	};

	const CheckboxItem = ({ name, title }: CheckboxItemProps) => (
		<ListItem key={name} disablePadding>
			<StyledListItemButton
				dense
				selected={exportImageOptions[name]}
				onClick={() => handleChange(name)}
			>
				<ListItemIcon>
					<Checkbox
						edge="start"
						size="small"
						checked={exportImageOptions[name]}
						tabIndex={-1}
						disableRipple
						inputProps={{ "aria-labelledby": title }}
					/>
				</ListItemIcon>
				<ListItemText id={title}>
					<Typography variant="body1">{title}</Typography>
				</ListItemText>
			</StyledListItemButton>
		</ListItem>
	);

	return (
		<List
			component="nav"
			id="export-image-options"
			sx={{
				width: "100%",
				minWidth: 300,
				bgcolor: "background.paper",
				position: "relative",
				overflow: "auto",
				...(!isMobile && { maxHeight: 500 }),
				"& ul": { padding: 0, margin: 0 },
				...CustomScrollbar(),
			}}
			className="scroller"
			// subheader={<li />}
		>
			{/* <li key="options" id="options">
                <ul> */}
			<StyledSubHeader color="primary">
				<Typography variant="overline">{t`Options`}</Typography>
			</StyledSubHeader>
			<CheckboxItem name="withLegend" title={t`Legend`} />
			<CheckboxItem name="withTitle" title={t`Title`} />
			<CheckboxItem name="openNewTab" title={t`Open in new tab`} />
			<Divider sx={{ my: "8px", mx: { xs: "10px", sm: "20px" } }} />
			{/* </ul>
            </li>
            <li key="size" id='size'> */}
			<StyledSubHeader color="primary">
				<Typography variant="overline">{t`Export size`}</Typography>
			</StyledSubHeader>
			{/* <ul> */}
			{myImageExportOptions.map((item) => {
				// console.log("export image items", item);
				return (
					<StyledListItemButton
						key={item.key}
						onClick={() => {
							props.dispatch(
								exportImageOptions.withLegend,
								exportImageOptions.withTitle,
								item.width,
								item.height,
								exportImageOptions.openNewTab ? "newTab" : "download",
								item.key,
							);
							handleClose();
						}}
					>
						<ListItemIcon>{Iconify("ph:image-duotone")}</ListItemIcon>
						<ListItemText
							primary={item.title}
							secondary={item.secondary || undefined}
						/>
					</StyledListItemButton>
				);
			})}
			{/* </ul>
            </li> */}
		</List>
	);
}

type CategorySelectorProps = {
	// id: keyof MagicButtonState;
	data: Category[];
	categorySelected: Category["id"];
	handleClose?: () => void;
};

// categorySelector
export function CategorySelector(props: CategorySelectorProps) {
	const handleClose = props.handleClose || (() => {});
	const location = useLocation();

	return (
		<Box>
			{/* <SearchBar data={props.data}/> */}
			<List
				component="nav"
				id="category"
				sx={{
					width: "100%",
					minWidth: 300,
					bgcolor: "background.paper",
					position: "relative",
					overflow: "auto",
					maxHeight: "80vh",
					...CustomScrollbar(),
				}}
			>
				{props.data.map((category) => {
					const linkLabelProps: RouterLinkProps = {};

					if (props.categorySelected !== category.id) {
						linkLabelProps.to = buildPath(location, [
							["category", `${category.id}`],
						]);
						linkLabelProps.component = RouterLink;
					}

					return (
						<StyledListItemButton
							key={category.id}
							selected={props.categorySelected === category.id}
							{...(linkLabelProps as any)}
							onClick={(_e) => {
								handleClose();
							}}
							sx={{
								// added21022022
								"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
									backgroundColor: alpha(category.color, 0.08),
									"& .MuiTypography-root:not(.MuiListItemText-secondary), & .MuiListItemIcon-root svg path":
										{
											color: category.color,
										},
									"&:hover": {
										backgroundColor: alpha(category.color, 0.08),
									},
								},
							}}
						>
							<ListItemIcon
								sx={{
									color: category.color,
								}}
							>
								{Iconify("ph:circle-fill")}
							</ListItemIcon>
							<ListItemText secondary={category.description}>
								<Typography variant="body2">{category.label}</Typography>
							</ListItemText>
						</StyledListItemButton>
					);
				})}
			</List>
		</Box>
	);
}

type BenchmarkSelectorProps = {
	benchmarkItems: { label: string; type: string }[];
	selected?: string;
	handleClick: (newValue: string) => void;
	handleClose?: void;
};

export function BenchmarkSelector(props: BenchmarkSelectorProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const handleClose = props.handleClose || (() => {});

	return (
		<List
			component="nav"
			id="chartType"
			sx={{
				width: "100%",
				bgcolor: "background.paper",
				position: "relative",
				overflow: "auto",
				...(!isMobile && { maxHeight: 500 }),
				"& ul": { padding: 0, margin: 0 },
				maxHeight: "80vh",
				...CustomScrollbar(),
			}}
			className="scroller"
		>
			<ul>
				{props.benchmarkItems.map((item) => {
					return (
						<StyledListItemButton
							key={item.type}
							selected={props.selected === item.type}
							onClick={(_e) => {
								props.handleClick(item.type);
								handleClose();
							}}
							sx={{ "& *:first-letter": { textTransform: "none" } }}
						>
							<ListItemText>
								<Typography variant="body2">{item.label}</Typography>
							</ListItemText>
						</StyledListItemButton>
					);
				})}
			</ul>
		</List>
	);
}
