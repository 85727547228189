import { Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import useFeedbackContext from "src/hooks/useFeedbackContext";
import { t } from "ttag";
import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/others/Iconify";

// ----------------------------------------------------------------------

export default function FeedbackButton() {
	// const location = useLocation();
	// console.log(location, "hereurl");
	// const feedbackUrl =
	// 	"https://docs.google.com/forms/d/e/1FAIpQLSfnOwwnyMbcYm7Z5r47QBRc-Plgde5Rmj15xHVQ1KjdX-Am_w/viewform?usp=pp_url&entry.1781500597=" +
	// 	window.location.href;
	const { setOpenForm } = useFeedbackContext();

	const handleOpen = () => {
		setOpenForm(true);
	};

	return (
		<Tooltip title={t`Your opinion counts!`}>
			{/* <Link href={feedbackUrl} underline="none" target="_blank" rel="noopener"> */}
			<IconButtonAnimate
				color={"default"}
				size="large"
				sx={{
					"&:hover": {
						backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.2),
						// height:52,
						// width:52,
					},
				}}
				onClick={handleOpen}
			>
				<Iconify
					icon="ph:chat-dots-fill"
					width={20}
					height={20}
					sx={{ color: "white" }}
				/>
			</IconButtonAnimate>
			{/* </Link> */}
		</Tooltip>
	);
}
