import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Fuse from "fuse.js";
import { min, sortBy } from "lodash";
import * as React from "react";
import { useState } from "react";
import type { SiteContent } from "src/@types/SiteContent";
import type {
	meteringplanBaseObject,
	meteringplanObject,
	meteringplanVirtualObject,
} from "src/@types/installation";
import useSiteContext from "src/hooks/useSiteContext";
import { Iconify } from "src/utils/getIcon";
import { t } from "ttag";

// Custom search field
const StyledSearchBar = styled(TextField)({
	"& .MuiOutlinedInput-root": {
		padding: "0 8px",
	},
});

const arraySearch = (array: SiteContent, keyword: string): SiteContent => {
	// Fuzzy finder !

	// Flatten installations
	const searchableLines: any[] = [];

	const walkTree = (tree: any[]) => {
		tree.forEach((item) => {
			searchableLines.push({
				data_id: item.data_id,
				label: item.label,
			});
			if (item.children !== undefined) {
				walkTree(item.children);
			}
		});
	};

	walkTree(array.metering_plan);
	walkTree(array.virtual);
	// console.log(searchableLines, "tree searchableLines")

	const options = {
		isCaseSensitive: false,
		includeScore: true,
		shouldSort: false,
		ignoreLocation: true,
		threshold: 0.4,
		keys: ["label"],
	};

	const fuse = new Fuse(searchableLines, options);
	const result = fuse.search(keyword);

	const matchedLines = new Set(result.map((row) => row.item.data_id));

	const scores: { [key: string]: number | undefined } = {};
	result.forEach((row) => {
		scores[row.item.data_id as string] = row.score;
	});

	// console.log(matchedLines, scores, 'tree matchedLines')
	// Now deeply filter the initial list
	type scoredMeteringplanBaseObject = meteringplanBaseObject & {
		score?: number;
	};
	type filterItem = scoredMeteringplanBaseObject | undefined;
	const max_score = 1;

	const filterTree = (tree: any[]) => {
		const res = tree
			.map((item): filterItem => {
				const matched_line =
					item !== undefined && matchedLines.has(item?.data_id);
				const item_score =
					(matched_line ? scores[item?.data_id as string] : max_score) ||
					max_score;

				if (item?.children !== undefined) {
					const children = filterTree(item.children);

					// lines with children that matched
					if (children.length > 0) {
						// add current score if it exists
						const score = min(
							children
								.map(
									((child) => (child as scoredMeteringplanBaseObject).score) ||
										max_score,
								)
								.concat([item_score]),
						);

						return {
							...item,
							score,
							children: children as meteringplanBaseObject[],
						};
					}
				}

				// line matched, but not its children
				if (matched_line) {
					return {
						...item,
						score: item_score,
						children: [] as meteringplanBaseObject[],
					};
				}

				return undefined;
				// console.log(item,matchedLines, 'item score')
			})
			.filter((item: any) => item !== undefined);

		// console.log(res, 'res array')
		return sortBy(res, [
			(item) => (item as scoredMeteringplanBaseObject).score,
		]);
	};
	const new_plan = filterTree(array.metering_plan) as meteringplanObject[];
	const new_virtual = filterTree(array.virtual) as meteringplanVirtualObject[];

	// return a copy of the state, so that we don't modify the original object
	return {
		...array,
		metering_plan: new_plan,
		virtual: new_virtual,
	};
};
const SearchBarNodes = (props: {
	expanded: any;
	handleExpandClick?: (command?: string) => void;
	openDrawer: boolean;
	initSite: SiteContent | undefined;
	Site: SiteContent | undefined;
	setSite: (SiteContent: SiteContent) => void;
	sx?: object;
}) => {
	const { expanded, handleExpandClick, Site, setSite, initSite } = props;
	const [search, setSearch] = useState("");

	const open: boolean = props.openDrawer;

	const handleSearch = (new_search: string) => {
		const old_search = search;
		if (old_search === new_search) return;

		setSearch(new_search);
		if (new_search === "") {
			// console.log(initSite, 'erase !')
			setSite(initSite!);
		} else {
			const searchArray = arraySearch(Site!, new_search);
			// console.log(searchArray, 'searchArray')
			setSite(searchArray);
			if (typeof handleExpandClick === "function" && expanded.length === 0) {
				handleExpandClick("expand");
			}
		}
	};

	// Delete search when component will unmount
	React.useEffect(() => {
		handleSearch("");
	}, []);

	const handleDeleteSearch = (_event: React.MouseEvent<HTMLElement>) => {
		handleSearch("");
	};
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				py: "8px",
				px: { xs: "10px", sm: "20px" },
				marginBottom: "12px",
				...props.sx,
			}}
		>
			<StyledSearchBar
				id="mysearch"
				placeholder={t`Search`}
				autoComplete={"off"}
				size="small"
				sx={{ flexGrow: 1 }}
				value={search}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					handleSearch(open ? event.target.value : "")
				}
				// onChange={(event: any) => handleSearch(open ? search + event.target.value : '')}
				autoFocus
				InputProps={{
					startAdornment: expanded ? (
						<Tooltip
							title={
								expanded.length === 0
									? t`Expand all`
									: expanded.length === 1
										? t`Collapse node selected`
										: t`Collapse all`
							}
						>
							<IconButton
								onClick={() => {
									if (typeof handleExpandClick === "function")
										handleExpandClick();
								}}
								size="small"
								sx={{ transform: "scale(0.9)" }}
							>
								{expanded.length === 0
									? Iconify("ph:arrows-out-line-vertical")
									: Iconify("ph:arrows-in-line-vertical")}
							</IconButton>
						</Tooltip>
					) : null,
					endAdornment: (
						<Tooltip
							title={search && search.length > 0 ? t`Delete` : t`Search`}
						>
							<IconButton
								onClick={handleDeleteSearch}
								size="small"
								sx={{ transform: "scale(0.9)" }}
							>
								{search && search.length > 0
									? Iconify("ph:x-circle-light")
									: Iconify("ph:magnifying-glass")}
							</IconButton>
						</Tooltip>
					),
				}}
				variant="outlined"
			/>
		</Box>
	);
};

export default SearchBarNodes;
