import type { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					boxShadow: theme.customShadows.dropdown,
				},
				listbox: {
					"& .MuiAutocomplete-option": {
						margin: theme.spacing(1),
						borderRadius: theme.shape.borderRadius,
					},
					maxHeight: "none",
					padding: 0,
				},
			},
		},
	};
}
