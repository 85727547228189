import { useContext } from "react";
import { NodesContext } from "../contexts/NodesContext";

// ----------------------------------------------------------------------

const useNodesContext = () => {
	const context = useContext(NodesContext);

	if (!context) throw new Error("Site context must be use inside SiteProvider");

	return context;
};

export default useNodesContext;
