import {
	type Locale,
	add,
	eachDayOfInterval,
	eachMonthOfInterval,
	eachWeekOfInterval,
	eachYearOfInterval,
	formatISO,
	getDaysInMonth,
	getTime,
	isEqual,
	isValid,
	parseISO,
	secondsToHours,
	secondsToMinutes,
	toDate,
} from "date-fns";
import {
	startOfDay,
	startOfHour,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from "date-fns";
import {
	endOfDay,
	endOfHour,
	endOfMonth,
	endOfWeek,
	endOfYear,
} from "date-fns";
import {
	format,
	formatInTimeZone,
	utcToZonedTime,
	zonedTimeToUtc,
} from "date-fns-tz";
import { t } from "ttag";

// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc' // import plugin
// import timezone from 'dayjs/plugin/timezone' // import plugin
// import dayjs_fr from 'dayjs/locale/fr' // import locale

// dayjs.extend(utc) // use plugin
// dayjs.extend(timezone) // use plugin
// dayjs.locale(dayjs_fr)

export type DateInput = string | number | Date;

export const LOCAL_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function timeFormat(
	ts: DateInput,
	tz: string,
	template: string,
	locale: Locale,
): string {
	const date = utcToZonedTime(ts, tz);
	// console.log(date, tz, template, locale,'for jest')
	return format(date, template, { timeZone: tz, locale: locale });
}

export function formatISOInTimeZone(dt: DateInput, tz: string) {
	return formatInTimeZone(dt, tz, "yyyy-MM-dd'T'HH:mm:ssXXX");
}
export const getTimezoneString = (): string => {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return timezone;
};
export function startOfDayZoned(dt: DateInput, tz: string) {
	// from https://github.com/marnusw/date-fns-tz/issues/67
	const inputZoned = utcToZonedTime(dt, tz);
	const dayStartZoned = startOfDay(inputZoned);
	return zonedTimeToUtc(dayStartZoned, tz);
}

export enum Frequency {
	Hour = 0,
	Day = 1,
	Week = 2,
	Month = 3,
	Year = 4,
}

export function naiveStartOfPeriod(dt: number | Date, frequency: Frequency) {
	// danger : startOfYY returns a Date in local time

	switch (frequency) {
		case Frequency.Hour:
			return startOfHour(dt);
		case Frequency.Day:
			return startOfDay(dt);
		case Frequency.Week:
			return startOfWeek(dt, { weekStartsOn: 1 }); // weeks start on Monday, not Sunday
		case Frequency.Month:
			return startOfMonth(dt);
		case Frequency.Year:
			return startOfYear(dt);
	}
}

export function startOfPeriod(
	dt: DateInput,
	tz: string,
	frequency: Frequency,
): Date {
	const dtZoned = utcToZonedTime(dt, tz);
	return naiveStartOfPeriod(dtZoned, frequency);
}

export function startOfPeriodTimestamp(
	ts: DateInput,
	tz: string,
	frequency: Frequency,
): number {
	// date-fns:
	return getTime(zonedTimeToUtc(startOfPeriod(ts, tz, frequency), tz));

	// dayjs:
	/*
    const d1 = dayjs.tz(ts, tz)

    let res: dayjs.Dayjs
    switch (frequency) {
        case Frequency.Hour:
            res = d1.startOf('hour')
            break
        case Frequency.Day:
            res = d1.startOf('day')
            break
        case Frequency.Week:
            // locale + tz is buggy (see https://github.com/iamkun/dayjs/issues/2398)
            // weeks start on Monday, not Sunday, so use 'fr' locale
            const withoutTz = dayjs(d1.format('YYYY-MM-DD HH:mm:ss:SSS')).locale('fr', {weekStart: 1})
            res = withoutTz.startOf('week').tz(tz, true)
            break
        case Frequency.Month:
            res = d1.startOf('month')
            break
        case Frequency.Year:
            res = d1.startOf('year')
            break
        default:
            throw new Error("Unhandled frequency");
    }

    return res.valueOf()

    */
}

export function startOfNextPeriodTimestamp(
	ts: DateInput,
	tz: string,
	frequency: Frequency,
	stayOnBound = false,
): number {
	const initial_date = toDate(utcToZonedTime(ts, tz));
	const start = startOfPeriod(ts, tz, frequency);
	let next_start: Date;

	if (stayOnBound && isEqual(initial_date, start)) {
		// we are already at the limit
		next_start = start;
	} else {
		switch (frequency) {
			case Frequency.Hour:
				next_start = add(start, { hours: 1 });
				break;
			case Frequency.Day:
				next_start = add(start, { days: 1 });
				break;
			case Frequency.Week:
				next_start = add(start, { weeks: 1 });
				break;
			case Frequency.Month:
				next_start = add(start, { months: 1 });
				break;
			case Frequency.Year:
				next_start = add(start, { years: 1 });
				break;
			default:
				throw new Error("Unhandled frequency");
		}
	}
	return getTime(zonedTimeToUtc(next_start, tz));
}

export function nbOfDays(frequency: Frequency, date?: number | Date) {
	switch (frequency) {
		case Frequency.Hour:
			throw new Error("Not valid for hour freq");
		case Frequency.Day:
			return 1;
		case Frequency.Week:
			return 7;
		case Frequency.Month:
			if (date) return getDaysInMonth(date);
			else return 30;
		case Frequency.Year:
			return 365;
	}
}

export function naiveEndOfPeriod(dt: number | Date, frequency: Frequency) {
	switch (frequency) {
		case Frequency.Hour:
			return endOfHour(dt);
		case Frequency.Day:
			return endOfDay(dt);
		case Frequency.Week:
			return endOfWeek(dt, { weekStartsOn: 1 });
		case Frequency.Month:
			return endOfMonth(dt);
		case Frequency.Year:
			return endOfYear(dt);
	}
}

export function endOfPeriod(dt: DateInput, tz: string, frequency: Frequency) {
	const dtZoned = utcToZonedTime(dt, tz);
	return naiveEndOfPeriod(dtZoned, frequency);
}

export function endOfPeriodTimestamp(
	ts: DateInput,
	tz: string,
	frequency: Frequency,
): number {
	return getTime(zonedTimeToUtc(endOfPeriod(ts, tz, frequency), tz));
}

export function eachDate(
	start: DateInput,
	end: DateInput,
	tz: string,
	frequency: Frequency,
) {
	const startZoned = utcToZonedTime(start, tz);
	const endZoned = utcToZonedTime(end, tz);

	switch (frequency) {
		case Frequency.Hour:
			throw new Error("Not valid for hour freq");
		case Frequency.Day:
			return eachDayOfInterval({ start: startZoned, end: endZoned });
		case Frequency.Week:
			return eachWeekOfInterval(
				{ start: startZoned, end: endZoned },
				{ weekStartsOn: 1 },
			);
		case Frequency.Month:
			return eachMonthOfInterval({ start: startZoned, end: endZoned });
		case Frequency.Year:
			return eachYearOfInterval({ start: startZoned, end: endZoned });
	}
}

export function eachEpoch(
	start: DateInput,
	end: DateInput,
	tz: string,
	frequency: Frequency,
) {
	return eachDate(start, end, tz, frequency).map((dt) =>
		getTime(zonedTimeToUtc(dt, tz)),
	);
}

export function convertSeconds(seconds: number) {
	// warning : these functions are floor rounding
	const hours = secondsToHours(seconds);
	const minutes = secondsToMinutes(seconds) - hours * 60;
	return { hours, minutes };
}

export function durationFormat(hours: number, minutes: number) {
	const duration: string[] = [];
	if (hours > 0) {
		duration.push(hours + " " + (hours > 1 ? t`hours` : t`hour`));
	}
	if (minutes > 0) {
		duration.push(minutes + " " + (minutes > 1 ? t`minutes` : t`minute`));
	}
	return duration.join(" ");
}

export function utcDateFromLocalTz(newDate: DateInput, locale?: Locale) {
	// new Date() create date that is converted in local timezone when using toString() method
	// TODO : check if really needed
	return zonedTimeToUtc(newDate, LOCAL_TZ, { locale: locale });
}

export function timestamp(naive_iso: string, tz: string): number {
	// date-fns:
	return getTime(zonedTimeToUtc(naive_iso, tz));

	// dayjs:
	// return dayjs.tz(naive_iso, tz).valueOf()
}

export function formatNaiveISO(dt: Date) {
	return format(dt, "yyyy-MM-dd'T'HH:mm:ss");
}
