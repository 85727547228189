// @mui
import { TextField, type TextFieldProps } from "@mui/material";
// form
import { Controller, useFormContext } from "react-hook-form";

// ----------------------------------------------------------------------

interface IProps {
	name: string;
	children: any;
}

export default function RHFSelect({
	name,
	children,
	...other
}: IProps & TextFieldProps) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...field}
					select
					fullWidth
					SelectProps={{ native: true }}
					error={!!error}
					helperText={error?.message}
					{...other}
				>
					{children}
				</TextField>
			)}
		/>
	);
}
