import type { AuthUser } from "src/@types/auth";
import type { meteringplanObject } from "src/@types/installation";

export const displayInstallation = (
	installation: meteringplanObject,
	user: AuthUser,
	useFor: "selector" | "selectorForSiteInfo" | "nextInstallButton",
): boolean => {
	// display all installations
	if (user && user.isSmartImpulse) return true;

	let hasAccess =
		installation.has_children_with_user_access ||
		(installation.user_access && installation.date_begin_customer !== null);
	switch (useFor) {
		case "selector":
			break;
		case "selectorForSiteInfo":
			// selectorForSiteInfo: show parent unviewable install if only child metadata is viewable because it is needed to map through children, it is hidden afterwards
			hasAccess =
				hasAccess &&
				(installation.can_view_metadata ||
					installation.has_children_view_metadata);
			break;
		case "nextInstallButton":
			// nextInstallButton: do not show parent unviewable install if only child metadata is viewable
			hasAccess = hasAccess && installation.can_view_metadata;
			break;
	}
	return hasAccess;
};

export const displayChildren = (
	installation: meteringplanObject,
	user: AuthUser,
	useFor: "selector" | "selectorForSiteInfo" | "nextInstallButton",
) => {
	let children: meteringplanObject[] = [];
	if (displayInstallation(installation, user, useFor))
		children.push(installation);

	if (installation.children) {
		installation.children.map((child) => {
			children = children.concat(displayChildren(child, user, useFor));
		});
	}
	return children;
};
