import {
	type IconifyIcon,
	Icon as TrueIconifyIcon,
} from "@iconify/react/dist/offline";
import SvgIconStyle from "../components/others/SvgIconStyle";

// eva
import alertCircleFill from "@iconify/icons-eva/alert-circle-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosUpwardFill from "@iconify/icons-eva/arrow-ios-upward-fill";
import arrowRightFill from "@iconify/icons-eva/arrow-right-fill";
import bellBill from "@iconify/icons-eva/bell-fill";
import bookFill from "@iconify/icons-eva/book-fill";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import emailFill from "@iconify/icons-eva/email-fill";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import googleFill from "@iconify/icons-eva/google-fill";
import evaHeartFill from "@iconify/icons-eva/heart-fill";
import infoFill from "@iconify/icons-eva/info-fill";
import messageCircleFill from "@iconify/icons-eva/message-circle-fill";
import moreHorizontalFill from "@iconify/icons-eva/more-horizontal-fill";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import personFill from "@iconify/icons-eva/person-fill";
import plusFill from "@iconify/icons-eva/plus-fill";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import addressBook from "@iconify/icons-ph/address-book-duotone";
// ph
import alignLeftDuotone from "@iconify/icons-ph/align-left-duotone";
import alignRightDuotone from "@iconify/icons-ph/align-right-duotone";
import arrowCounterClockwiseDuotone from "@iconify/icons-ph/arrow-counter-clockwise-duotone";
import arrowDownDuotone from "@iconify/icons-ph/arrow-down-duotone";
import arrowLeftDuotone from "@iconify/icons-ph/arrow-left-duotone";
import arrowRightDuotone from "@iconify/icons-ph/arrow-right-duotone";
import arrowUpDuotone from "@iconify/icons-ph/arrow-up-duotone";
import arrowsInLineHorizontal from "@iconify/icons-ph/arrows-in-line-horizontal";
import arrowsInLineVertical from "@iconify/icons-ph/arrows-in-line-vertical";
import arrowsOutLineVertical from "@iconify/icons-ph/arrows-out-line-vertical";
import arrowsVerticalDuotone from "@iconify/icons-ph/arrows-vertical-duotone";
import batteryChargingVerticalDuotone from "@iconify/icons-ph/battery-charging-vertical-duotone";
import batteryHighDuotone from "@iconify/icons-ph/battery-high-duotone";
import bellRingingDuotone from "@iconify/icons-ph/bell-ringing-duotone";
import bellRingingFill from "@iconify/icons-ph/bell-ringing-fill";
import bellSlashDuotone from "@iconify/icons-ph/bell-slash-duotone";
import buildingsDuotone from "@iconify/icons-ph/buildings-duotone";
import calculatorDuotone from "@iconify/icons-ph/calculator-duotone";
import calendar from "@iconify/icons-ph/calendar";
import calendarCheckDuotone from "@iconify/icons-ph/calendar-check-duotone";
import calendarDuotone from "@iconify/icons-ph/calendar-duotone";
import carDuotone from "@iconify/icons-ph/car-duotone";
import caretDownLight from "@iconify/icons-ph/caret-down-light";
import chartBarDuotone from "@iconify/icons-ph/chart-bar-duotone";
import chartLineDuotone from "@iconify/icons-ph/chart-line-duotone";
import chartLineUpDuotone from "@iconify/icons-ph/chart-line-up-duotone";
import chartPieSliceDuotone from "@iconify/icons-ph/chart-pie-slice-duotone";
import chatCircleDotsDuotone from "@iconify/icons-ph/chat-circle-dots-duotone";
import chatDotsFill from "@iconify/icons-ph/chat-dots-fill";
import chatTextDuotone from "@iconify/icons-ph/chat-text-duotone";
import checkCircleDuotone from "@iconify/icons-ph/check-circle-duotone";
import checksDuotone from "@iconify/icons-ph/checks-duotone";
import circleDuotone from "@iconify/icons-ph/circle-duotone";
import circleFill from "@iconify/icons-ph/circle-fill";
import clockCounterClockwiseDuotone from "@iconify/icons-ph/clock-counter-clockwise-duotone";
import clockDuotone from "@iconify/icons-ph/clock-duotone";
import coinsDuotone from "@iconify/icons-ph/coins-duotone";
import ConfettiDuotone from "@iconify/icons-ph/confetti-duotone";
import cornersInDuotone from "@iconify/icons-ph/corners-in-duotone";
import cornersOutDuotone from "@iconify/icons-ph/corners-out-duotone";
import crosshairDuotone from "@iconify/icons-ph/crosshair-duotone";
import databaseDuotone from "@iconify/icons-ph/database-duotone";
import desktopTowerDuotone from "@iconify/icons-ph/desktop-tower-duotone";
import DetectiveDuotone from "@iconify/icons-ph/detective-duotone";
import downloadDuotone from "@iconify/icons-ph/download-duotone";
import facebookLogoFill from "@iconify/icons-ph/facebook-logo-fill";
import fileCSV from "@iconify/icons-ph/file-csv";
import fileImageDuotone from "@iconify/icons-ph/file-image-duotone";
import fileXLS from "@iconify/icons-ph/file-xls";
import fireDuotone from "@iconify/icons-ph/fire-duotone";
import forkKnifeDuotone from "@iconify/icons-ph/fork-knife-duotone";
import frameCorners from "@iconify/icons-ph/frame-corners";
import funnelDuotone from "@iconify/icons-ph/funnel-duotone";
import gaugeDuotone from "@iconify/icons-ph/gauge-duotone";
import handDuotone from "@iconify/icons-ph/hand-duotone";
import handWavingDuotone from "@iconify/icons-ph/hand-waving-duotone";
import heartFill from "@iconify/icons-ph/heart-fill";
import imageDuotone from "@iconify/icons-ph/image-duotone";
import infoDuotone from "@iconify/icons-ph/info-duotone";
import kanbanDuotone from "@iconify/icons-ph/kanban-duotone";
import leafDuotone from "@iconify/icons-ph/leaf-duotone";
import lightbulbDuotone from "@iconify/icons-ph/lightbulb-duotone";
import lightningDuotone from "@iconify/icons-ph/lightning-duotone";
import linkedinLogoFill from "@iconify/icons-ph/linkedin-logo-fill";
import listBold from "@iconify/icons-ph/list-bold";
import listFill from "@iconify/icons-ph/list-fill";
import lockKeyDuotone from "@iconify/icons-ph/lock-key-duotone";
import magnifyingGlass from "@iconify/icons-ph/magnifying-glass";
import magnifyingGlassDuotone from "@iconify/icons-ph/magnifying-glass-plus-duotone";
import mapPinDuotone from "@iconify/icons-ph/map-pin-duotone";
import mapPinLine from "@iconify/icons-ph/map-pin-line";
import mapPinLineDuotone from "@iconify/icons-ph/map-pin-line-duotone";
import megaphoneDuotone from "@iconify/icons-ph/megaphone-duotone";
import excelDuotone from "@iconify/icons-ph/microsoft-excel-logo-duotone";
import minusCircle from "@iconify/icons-ph/minus-circle";
import moonDuotone from "@iconify/icons-ph/moon-duotone";
import noteDuotone from "@iconify/icons-ph/note-duotone";
import PencilDuotone from "@iconify/icons-ph/pencil-duotone";
import percentDuotone from "@iconify/icons-ph/percent-duotone";
import plusCircle from "@iconify/icons-ph/plus-circle";
import plusCircleDuotone from "@iconify/icons-ph/plus-circle-duotone";
import plus from "@iconify/icons-ph/plus-duotone";
import shoppingCartDuotone from "@iconify/icons-ph/shopping-cart-duotone";
import signOutDuotone from "@iconify/icons-ph/sign-out-duotone";
import slidersDuotone from "@iconify/icons-ph/sliders-duotone";
import sunDimDuotone from "@iconify/icons-ph/sun-dim-duotone";
import sunDuotone from "@iconify/icons-ph/sun-duotone";
import tableDuotone from "@iconify/icons-ph/table-duotone";
import thermometerDuotone from "@iconify/icons-ph/thermometer-duotone";
import trashDuotone from "@iconify/icons-ph/trash-duotone";
import treeStructure from "@iconify/icons-ph/tree-structure";
import treeStructureDuotone from "@iconify/icons-ph/tree-structure-duotone";
import twitterLogoFill from "@iconify/icons-ph/twitter-logo-fill";
import usersDuotone from "@iconify/icons-ph/users-duotone";
import warningDuotone from "@iconify/icons-ph/warning-duotone";
import wifiLowDuotone from "@iconify/icons-ph/wifi-low-duotone";
import xCircleDuotone from "@iconify/icons-ph/x-circle-duotone";
import xCircleLight from "@iconify/icons-ph/x-circle-light";
import xDuotone from "@iconify/icons-ph/x-duotone";
import buildingsDuotoneBold from "@iconify/icons-solar/buildings-2-bold-duotone";

import download from "@iconify/icons-fa-solid/download";
// fa
import expand from "@iconify/icons-fa-solid/expand";

// ant-design
import androidFilled from "@iconify/icons-ant-design/android-filled";
import appleFilled from "@iconify/icons-ant-design/apple-filled";
import filePdfFilled from "@iconify/icons-ant-design/file-pdf-filled";
import instagramFilled from "@iconify/icons-ant-design/instagram-filled";
import trophyFilled from "@iconify/icons-ant-design/trophy-filled";
import windowsFilled from "@iconify/icons-ant-design/windows-filled";
import speedoMeter from "@iconify/icons-bi/speedometer2";
import roundAccountBox from "@iconify/icons-ic/round-account-box";
import roundAddAPhoto from "@iconify/icons-ic/round-add-a-photo";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import roundVpnKey from "@iconify/icons-ic/round-vpn-key";
// Google Material Icons
import twotoneBrokenImage from "@iconify/icons-ic/twotone-broken-image";

const IconMap = {
	// ph
	"ph:align-left-duotone": alignLeftDuotone,
	"ph:align-right-duotone": alignRightDuotone,
	"ph:arrow-counter-clockwise-duotone": arrowCounterClockwiseDuotone,
	"ph:arrows-in-line-vertical": arrowsInLineVertical,
	"ph:arrows-in-line-horizontal-regular": arrowsInLineHorizontal,
	"ph:arrows-out-line-vertical": arrowsOutLineVertical,
	"ph:arrows-vertical-duotone": arrowsVerticalDuotone,
	"ph:battery-charging-vertical-duotone": batteryChargingVerticalDuotone,
	"ph:battery-high-duotone": batteryHighDuotone,
	"ph:bell-ringing-duotone": bellRingingDuotone,
	"ph:bell-ringing-fill": bellRingingFill,
	"ph:buildings-duotone": buildingsDuotone,
	"ph:address-book-duotone": addressBook,
	"solar:buildings-2-bold-duotone": buildingsDuotoneBold,
	"ph:calculator-duotone": calculatorDuotone,
	"ph:calendar-check-duotone": calendarCheckDuotone,
	"ph:calendar-duotone": calendarDuotone,
	"ph:calendar": calendar,
	"ph:car-duotone": carDuotone,
	"ph:caret-down-light": caretDownLight,
	"ph:chart-bar-duotone": chartBarDuotone,
	"ph:chart-line-duotone": chartLineDuotone,
	"ph:chart-line-up-duotone": chartLineUpDuotone,
	"ph:chart-pie-slice-duotone": chartPieSliceDuotone,
	"ph:chat-circle-dots-duotone": chatCircleDotsDuotone,
	"ph:chat-dots-fill": chatDotsFill,
	"ph:chat-text-duotone": chatTextDuotone,
	"ph:checks-duotone": checksDuotone,
	"ph:check-circle-duotone": checkCircleDuotone,
	"ph:circle-fill": circleFill,
	"ph:coins-duotone": coinsDuotone,
	"ph:corners-in-duotone": cornersInDuotone,
	"ph:corners-out-duotone": cornersOutDuotone,
	"ph:database-duotone": databaseDuotone,
	"ph:desktop-tower-duotone": desktopTowerDuotone,
	"ph:detective-duotone": DetectiveDuotone,
	"ph:download-duotone": downloadDuotone,
	"ph:facebook-logo-fill": facebookLogoFill,
	"ph:file-image-duotone": fileImageDuotone,
	"ph:fire-duotone": fireDuotone,
	"ph:fork-knife-duotone": forkKnifeDuotone,
	"ph:funnel-duotone": funnelDuotone,
	"ph:gauge-duotone": gaugeDuotone,
	"ph:hand-duotone": handDuotone,
	"ph:hand-waving-duotone": handWavingDuotone,
	"ph:heart-fill": heartFill,
	"ph:image-duotone": imageDuotone,
	"ph:info-duotone": infoDuotone,
	"ph:kanban-duotone": kanbanDuotone,
	"ph:lightbulb-duotone": lightbulbDuotone,
	"ph:lightning-duotone": lightningDuotone,
	"ph:linkedin-logo-fill": linkedinLogoFill,
	"ph:list-bold": listBold,
	"ph:list-fill": listFill,
	"ph:lock-key-duotone": lockKeyDuotone,
	"ph:magnifying-glass-plus-duotone": magnifyingGlassDuotone,
	"ph:magnifying-glass": magnifyingGlass,
	"ph:map-pin-duotone": mapPinDuotone,
	"ph:map-pin-line-duotone": mapPinLineDuotone,
	"ph:megaphone-duotone": megaphoneDuotone,
	"ph:microsoft-excel-logo-duotone": excelDuotone,
	"ph:minus-circle": minusCircle,
	"ph:moon-duotone": moonDuotone,
	"ph:note-duotone": noteDuotone,
	"ph:percent-duotone": percentDuotone,
	"ph:plus-duotone": plus,
	"ph:plus-circle": plusCircle,
	"ph:plus-circle-duotone": plusCircleDuotone,
	"ph:shopping-cart-duotone": shoppingCartDuotone,
	"ph:sign-out-duotone": signOutDuotone,
	"ph:sliders-duotone": slidersDuotone,
	"ph:sun-dim-duotone": sunDimDuotone,
	"ph:sun-duotone": sunDuotone,
	"ph:thermometer-duotone": thermometerDuotone,
	"ph:tree-structure-duotone": treeStructureDuotone,
	"ph:twitter-logo-fill": twitterLogoFill,
	"ph:users-duotone": usersDuotone,
	"ph:warning-duotone": warningDuotone,
	"ph:wifi-low-duotone": wifiLowDuotone,
	"ph:x-circle-duotone": xCircleDuotone,
	"ph:x-circle-light": xCircleLight,
	"ph:x-duotone": xDuotone,
	"ph:arrow-left-duotone": arrowLeftDuotone,
	"ph:arrow-right-duotone": arrowRightDuotone,
	"ph:tree-structure": treeStructure,
	"ph:clock-duotone": clockDuotone,
	"ph:leaf-duotone": leafDuotone,
	"ph:arrow-up-duotone": arrowUpDuotone,
	"ph:arrow-down-duotone": arrowDownDuotone,
	"ph:bell-slash-duotone": bellSlashDuotone,
	"ph:crosshair-duotone": crosshairDuotone,
	"ph:clock-counter-clockwise-duotone": clockCounterClockwiseDuotone,
	"ph:trash-duotone": trashDuotone,
	"ph:frame-corners": frameCorners,
	"ph:circle-duotone": circleDuotone,
	"ph:map-pin-line": mapPinLine,
	"ph:table-duotone": tableDuotone,
	"ph:file-csv": fileCSV,
	"ph:file-xls": fileXLS,
	"ph:confetti-duotone": ConfettiDuotone,
	"ph:pencil-duotone": PencilDuotone,
	// eva
	"eva:alert-circle-fill": alertCircleFill,
	"eva:alert-triangle-fill": alertTriangleFill,
	"eva:arrow-right-fill": arrowRightFill,
	"eva:bell-fill": bellBill,
	"eva:checkmark-circle-2-fill": checkmarkCircle2Fill,
	"eva:book-fill": bookFill,
	"eva:close-fill": closeFill,
	"eva:eye-fill": eyeFill,
	"eva:eye-off-fill": eyeOffFill,
	"eva:facebook-fill": facebookFill,
	"eva:google-fill": googleFill,
	"eva:heart-fill": evaHeartFill,
	"eva:info-fill": infoFill,
	"eva:message-circle-fill": messageCircleFill,
	"eva:person-fill": personFill,
	"eva:plus-fill": plusFill,
	"eva:search-fill": searchFill,
	"eva:more-horizontal-fill": moreHorizontalFill,
	"eva:more-vertical-fill": moreVerticalFill,
	"eva:trending-down-fill": trendingDownFill,
	"eva:trending-up-fill": trendingUpFill,
	"eva:arrow-ios-back-fill": arrowIosBackFill,
	"eva:arrow-ios-downward-fill": arrowIosDownwardFill,
	"eva:arrow-ios-forward-fill": arrowIosForwardFill,
	"eva:arrow-ios-upward-fill": arrowIosUpwardFill,
	"eva:email-fill": emailFill,
	"eva:trash-2-outline": trash2Outline,

	// fa
	"fa-solid:expand": expand,
	"fa-solid:download": download,

	"ant-design:android-filled": androidFilled,
	"ant-design:apple-filled": appleFilled,
	"ant-design:file-pdf-filled": filePdfFilled,
	"ant-design:instagram-filled": instagramFilled,
	"ant-design:trophy-filled": trophyFilled,
	"ant-design:windows-filled": windowsFilled,

	"ic:round-filter-list": roundFilterList,
	"ic:round-account-box": roundAccountBox,
	"ic:round-add-a-photo": roundAddAPhoto,
	"ic:round-vpn-key": roundVpnKey,

	"bi:speedometer2": speedoMeter,
};

const unhandledIcons = {
	"eva:archive-fill": null,
	"eva:arrow-circle-down-fill": null,
	"eva:attach-2-fill": null,
	"eva:book-open-fill": null,
	"eva:calendar-fill": null,
	"eva:checkmark-circle-2-outline": null,
	"eva:checkmark-fill": null,
	"eva:chevron-down-fill": null,
	"eva:chevron-right-fill": null,
	"eva:chevron-up-fill": null,
	"eva:close-circle-fill": null,
	"eva:collapse-fill": null,
	"eva:copy-fill": null,
	"eva:diagonal-arrow-left-down-fill": null,
	"eva:diagonal-arrow-right-up-fill": null,
	"eva:done-all-fill": null,
	"eva:download-fill": null,
	"eva:edit-2-fill": null,
	"eva:edit-fill": null,
	"eva:expand-fill": null,
	"eva:file-fill": null,
	"eva:flash-fill": null,
	"eva:heart-fill": null,
	"eva:home-fill": null,
	"eva:inbox-fill": null,
	"eva:link-fill": null,
	"eva:linkedin-fill": null,
	"eva:menu-2-fill": null,
	"eva:menu-fill": null,
	"eva:message-square-fill": null,
	"eva:mic-fill": null,
	"eva:minus-fill": null,
	"eva:options-2-fill": null,
	"eva:people-fill": null,
	"eva:phone-fill": null,
	"eva:pin-fill": null,
	"eva:plus-fill": null,
	"eva:printer-fill": null,
	"eva:radio-button-off-outline": null,
	"eva:refresh-fill": null,
	"eva:search-fill": null,
	"eva:settings-2-fill": null,
	"eva:share-fill": null,
	"eva:shield-fill": null,
	"eva:shopping-cart-fill": null,
	"eva:smiling-face-fill": null,
	"eva:star-fill": null,
	"eva:star-outline": null,
	"eva:twitter-fill": null,
	"eva:video-fill": null,
	"ic:round-add-photo-alternate": null,
	"ic:round-add-shopping-cart": null,
	"ic:round-arrow-right-alt": null,
	"ic:round-business-center": null,
	"ic:round-clear-all": null,
	"ic:round-filter-list": null,
	"ic:round-forum": null,
	"ic:round-fullscreen-exit": null,
	"ic:round-fullscreen": null,
	"ic:round-keyboard-arrow-right": null,
	"ic:round-label-important": null,
	"ic:round-label": null,
	"ic:round-mark-email-read": null,
	"ic:round-power-settings-new": null,
	"ic:round-receipt": null,
	"ic:round-redo": null,
	"ic:round-refresh": null,
	"ic:round-reply": null,
	"ic:round-report": null,
	"ic:round-send": null,
	"ic:round-thumb-up": null,
	"ic:round-undo": null,
	"ic:round-verified": null,
	"ic:round-view-agenda": null,
	"ic:round-view-day": null,
	"ic:round-view-module": null,
	"ic:round-view-week": null,
};

const getIcon = (name: string) => (
	<SvgIconStyle
		src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
		sx={{ width: 1, height: 1 }}
	/>
);

type IconParams = Parameters<typeof TrueIconifyIcon>;
export type OfflineIcons = keyof typeof IconMap | keyof typeof unhandledIcons;

export const Icon = (
	props: IconParams[0] & { icon: IconifyIcon | OfflineIcons },
) => {
	if (typeof props.icon === "string") {
		const icon: IconifyIcon | undefined =
			IconMap[props.icon as keyof typeof IconMap];
		if (!icon) {
			console.error(`Icon should be offline: ${props.icon}`);
		}
		props = {
			...props,
			icon: icon || twotoneBrokenImage,
		};
	}
	return <TrueIconifyIcon {...props} />;
};

export const ICONS = (color?: any) => {
	return {
		// For header
		sites: <Icon icon={buildingsDuotone} color={color} />,
		multisites: <Icon icon={buildingsDuotoneBold} color={color} />,
		notifications: <Icon icon={bellRingingDuotone} color={color} />,
		settings: <Icon icon={slidersDuotone} color={color} />,
		darktheme: <Icon icon={moonDuotone} color={color} />,
		lighttheme: <Icon icon={sunDimDuotone} color={color} />,
		fullscreen: <Icon icon={cornersOutDuotone} color={color} />,
		signout: <Icon icon={signOutDuotone} color={color} />,
		checkall: <Icon icon={checksDuotone} color={color} />,
		menumobile: <Icon icon={listFill} color={color} />,
		// For sidebar
		dashboard: <Icon icon={gaugeDuotone} color={color} />,
		analytics: <Icon icon={chartBarDuotone} color={color} />,
		actions: <Icon icon={calendarCheckDuotone} color={color} />,
		alerts: <Icon icon={megaphoneDuotone} hFlip={true} color={color} />,
		reports: <Icon icon={fileImageDuotone} color={color} />,
		siteinformation: <Icon icon={mapPinLineDuotone} color={color} />,
		meteringplan: <Icon icon={treeStructureDuotone} color={color} />,
		users: <Icon icon={usersDuotone} color={color} />,
		projects: <Icon icon={kanbanDuotone} color={color} />,
		issues: <Icon icon={wifiLowDuotone} color={color} />,
		exports: <Icon icon={downloadDuotone} color={color} />,
		// api: <SwapHorizTwoToneIcon />,
		// For magic buttons
		chart: <Icon icon={chartPieSliceDuotone} color={color} />,
		calendar: <Icon icon={calendarDuotone} color={color} />,
		light: <Icon icon={lightbulbDuotone} color={color} />,
		filter: <Icon icon={funnelDuotone} color={color} />,
		options: <Icon icon={slidersDuotone} color={color} />,
		// For treeItems
		node: <Icon icon={buildingsDuotone} color={color} />,
		site: <Icon icon={mapPinDuotone} color={color} />,
		virtualinstallation: <Icon icon={lightningDuotone} color={color} />,
		category: <Icon icon={circleFill} color={color} />,
		// Common
		caretdown: <Icon icon={caretDownLight} color={color} />,
		//others
		mapPinLine: <Icon icon={mapPinLineDuotone} color={color} />,
		tableDuotone: <Icon icon={tableDuotone} color={color} />,
		// Not used
		blog: getIcon("ic_blog"),
		cart: <Icon icon={shoppingCartDuotone} color={color} />,
		chat: <Icon icon={chatCircleDotsDuotone} color={color} />,
		mail: getIcon("ic_mail"),
		user: getIcon("ic_user"),
		kanban: getIcon("ic_kanban"),
		banking: getIcon("ic_banking"),
		ecommerce: getIcon("ic_ecommerce"),
		booking: getIcon("ic_booking"),
	};
};
