import { useEffect, useRef } from "react";

// ----------------------------------------------------------------------

export default function useIsMountedRef() {
	const isMounted = useRef(true);

	//callback funct unmount
	useEffect(
		() => () => {
			isMounted.current = false;
		},
		[],
	);
	return isMounted;
}
