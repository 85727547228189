import { Stack, Toolbar } from "@mui/material";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
// components
import Logo from "../components/others/Logo";
import LanguagePopover from "./dashboard/header/LanguagePopover";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
	top: 0,
	left: 0,
	lineHeight: 0,
	width: "100%",
	position: "absolute",
	padding: theme.spacing(3, 3, 0),
	[theme.breakpoints.up("sm")]: {
		padding: theme.spacing(5, 5, 0),
	},
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
	const theme = useTheme();
	return (
		<HeaderStyle>
			<Toolbar
				sx={{
					padding: "0 19px !important",
					minHeight: "100% !important",
					px: { lg: 5 },
					flexDirection: "row",
					justifyContent: "left",
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent="start">
					<Logo color={theme.palette.secondary.main} />
				</Stack>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="end"
					sx={{ marginLeft: "auto" }}
				>
					<LanguagePopover />
				</Stack>
			</Toolbar>
			<Outlet />
		</HeaderStyle>
	);
}
