// @mui
import { Box, type BoxProps } from "@mui/material";
import { type ReactNode, forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import { brandSetting } from "src/config";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
	children: ReactNode;
	meta?: ReactNode;
	title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(
	({ children, title = "", meta, ...other }, ref) => {
		return (
			<>
				<Helmet>
					{title && <title>{`${title} | ${brandSetting.branding}`}</title>}
					{meta}
				</Helmet>

				<Box ref={ref} {...other}>
					{children}
				</Box>
			</>
		);
	},
);

export default Page;
