import { cloneDeep } from "lodash";
import type { metadataSchema } from "src/@types/metadataSchema";

export default function parseSchemaDefs(
	schema: metadataSchema,
	record: Record<string, any> | undefined,
	ignoredFields?: Array<string>,
) {
	if (record === undefined) return record;

	// parse definitions to add them to record
	const parsed_record: Record<string, any> = cloneDeep(record);
	Object.entries(record).map((field) => {
		if (ignoredFields && ignoredFields.includes(field[0])) {
			delete parsed_record[field[0]];
		} else {
			let parsed_def: any = schema;
			if (field[1].$ref !== undefined) {
				const definition_refs = field[1].$ref.split("/");
				definition_refs.slice(1).map((definition: string) => {
					parsed_def = parsed_def[definition as keyof typeof parsed_def];
				});
				if (parsed_def.$ref !== undefined) {
					// recursive call to add nested definitions
					const final_parsed = parseSchemaDefs(
						schema,
						{ field: parsed_def },
						ignoredFields,
					);
					if (final_parsed !== undefined) parsed_def = final_parsed.field;
				} else if (parsed_def.properties) {
					// recursive call to add nested definitions
					const final_parsed = parseSchemaDefs(
						schema,
						parsed_def.properties,
						ignoredFields,
					);
					if (final_parsed !== undefined) {
						parsed_def.properties = final_parsed;
					}
				}

				parsed_record[field[0]] = { ...field[1], ...parsed_def };
				delete parsed_record[field[0]].$ref;
			}
		}
	});
	return parsed_record;
}
