import { type ReactNode, createContext, useState } from "react";

type FeedbackProviderProps = { children: ReactNode };

export type FeedbackContextProps = {
	openForm: boolean;
	setOpenForm: (open: boolean) => void;
	openContact: boolean;
	setOpenContact: (open: boolean) => void;
	openContactLocalStorage: boolean;
	setOpenContactLocalStorage: (open: boolean) => void;
};

const initialState: FeedbackContextProps = {
	openForm: false,
	setOpenForm: () => {},
	openContact: false,
	setOpenContact: () => {},
	openContactLocalStorage: false,
	setOpenContactLocalStorage: () => {},
};

const FeedbackContext = createContext(initialState);

function FeedbackProvider({ children }: FeedbackProviderProps) {
	const [openForm, setOpenForm] = useState(false);
	const [openContact, setOpenContact] = useState(false);
	const [openContactLocalStorage, setOpenContactLocalStorage] = useState(false);

	// console.log(openForm,openContact, 'openForm')

	return (
		<FeedbackContext.Provider
			value={{
				openForm,
				setOpenForm,
				openContact,
				setOpenContact,
				openContactLocalStorage,
				setOpenContactLocalStorage,
			}}
		>
			{children}
		</FeedbackContext.Provider>
	);
}

export { FeedbackContext, FeedbackProvider };
