import {
	differenceInCalendarDays,
	getYear,
	isAfter,
	isLeapYear,
	isWithinInterval,
	subYears,
} from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import {
	installationZonedDates,
	meteringplanBenchmark,
} from "src/@types/installation";
import { shortcutFunctions } from "src/components/ShortCut/shortcutOptions";
import { utcDateFromLocalTz } from "./timeFormatter";

export default function benchmarkZonedDates(
	installations: meteringplanBenchmark[],
) {
	let start: Date | undefined = undefined,
		end: Date | undefined = undefined;
	let start_tz = "",
		end_tz = "";

	installations.map((installation) => {
		const insta_dates = installationZonedDates(
			installation,
			installation.timezone,
		);
		if (
			insta_dates.start !== null &&
			(start === undefined || insta_dates.start < start)
		) {
			start = insta_dates.start;
			start_tz = installation.timezone;
		}
		if (
			insta_dates.end !== null &&
			(end === undefined || insta_dates.end > end)
		) {
			end = insta_dates.end;
			end_tz = installation.timezone;
		}
	});

	const today = utcToZonedTime(utcDateFromLocalTz(new Date()), end_tz);
	if (end === undefined || isAfter(end, today)) end = today;

	if (start === undefined) start = subYears(today, 1);

	// tz is not that relevant if end date is provided
	let last12Months = shortcutFunctions().getLast12Months([start, end]);
	// console.log([start, end], last12Months, '[start, end] benchmarkZonedDates')
	if (last12Months[0] && last12Months[1]) {
		if (start > last12Months[0] || end < last12Months[1]) {
			// console.log([start, end], last12Months, '[start, end] benchmarkZonedDates inside if')
			if (start > last12Months[0]) last12Months[0] = start;
			if (end < last12Months[1]) last12Months[1] = end;
		}
	}
	let last12MonthsIso: [string, string] = ["", ""];
	let maxDaysCount: number | undefined;
	if (last12Months[0] && last12Months[1]) {
		// console.log([start, end], last12Months, '[start, end] benchmarkZonedDates differenceInCalendarDays')
		maxDaysCount =
			differenceInCalendarDays(last12Months[1], last12Months[0]) + 1;
		last12MonthsIso = [
			format(last12Months[0], "yyyy-MM-dd"),
			format(last12Months[1], "yyyy-MM-dd"),
		];
	}

	return {
		maxDaysCount: maxDaysCount,
		last12Months: last12Months,
		last12MonthsIso: last12MonthsIso,
		start: start,
		start_tz: start_tz,
		end: end,
		end_tz: end_tz,
	};
}

export function isDataPerYear(dateRange: [Date, Date], daysCount: number) {
	// Take into account leap years => daysCount should be 366 if the 29th of February of a leap year is in the range
	let yearDays = 365;
	dateRange.map((date) => {
		if (isLeapYear(date) && yearDays < 366) {
			let leapYear = getYear(date);
			let thresholdDate = new Date(`${leapYear}-02-29`);
			if (
				isWithinInterval(thresholdDate, {
					start: dateRange[0],
					end: dateRange[1],
				})
			)
				yearDays += 1;
		}
	});
	return daysCount === yearDays;
}
