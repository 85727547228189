import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./App";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { AuthProvider } from "./contexts/DjangoContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { persistor, store } from "./redux/store";
import { ActivateMessenger } from "./utils/smoochSmart";

// ----------------------------------------------------------------------

export const FullApp = () => {
	return (
		<AuthProvider>
			<HelmetProvider>
				<ReduxProvider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<SettingsProvider>
								<ActivateMessenger />
								<CollapseDrawerProvider>
									<App />
								</CollapseDrawerProvider>
							</SettingsProvider>
						</LocalizationProvider>
					</PersistGate>
				</ReduxProvider>
			</HelmetProvider>
		</AuthProvider>
	);
};
