import {
	Alert,
	Box,
	Button,
	Link,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { type StepType, useTour } from "@reactour/tour";
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
// import { IconifyRef } from './getIcon';
import {
	generatePath,
	useLocation,
	useNavigate,
	useParams,
} from "react-router";
// import useSiteContext from 'src/hooks/useSiteContext';
// import { SiteContent } from 'src/@types/SiteContent';
import Smooch from "smooch";
import DataReportIllustration from "src/assets/illustrations/DataReport";
// import useSettings from 'src/hooks/useSettings';
import LaunchIllustration from "src/assets/illustrations/Launch";
// import { useTour } from '@reactour/tour';
// import { MotivationIllustration } from 'src/assets';
import { brandSetting } from "src/config";
import useAuth from "src/hooks/useAuth";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { PATH_DASHBOARD } from "src/routes/paths";
// import useAppTour from 'src/hooks/useAppTourContext';
import { updateLastTourDate } from "src/services/service";
import { jt, t } from "ttag";
import { findChildrenArray } from "./treeFinder";

export type ContentProps = {
	steps: StepType[];
	currentStep: number;
	transition?: boolean;
	setCurrentStep: Dispatch<SetStateAction<number>>;
	setIsOpen: (arg0: boolean) => void;
};

// export function StepsConstruct(isOpen:boolean |  Boolean, setIsOpen:Dispatch<SetStateAction<Boolean>>){
export function appTour() {
	// const { openTour, setOpenTour } = useAppTour()

	// const { isOpen, steps, setCurrentStep } = useTour()
	// const { themeMode } = useSettings();
	const { Sites } = useNodesContext();
	const { Site } = useSiteContext();
	const { canUseChat } = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const waitClickId = (elementID: string) => {
		const handle = setInterval(() => {
			const elem = document.getElementById(elementID);
			if (elem !== null) {
				clearInterval(handle);
				elem.click();
			}
		}, 10);
	};
	// Compte demo: redirect if user has not seen any site and first customer does not have any site (should not be possible)
	const default_site = "b746f728-6aef-4e14-a147-547a6c26c5df";
	const site_uuid = useMemo(() => {
		if (params.node) return params.node;
		if (localStorage.getItem("site") !== null)
			return localStorage.getItem("site");
		if (Sites.length > 0) {
			if (Sites[0].type === "site") return Sites[0].uuid;
			else {
				const children = findChildrenArray(Sites[0]);
				if (children.length > 0) return children[0];
				else {
					// default site
					return default_site;
				}
			}
		}

		return default_site;
	}, [params.node, Sites]);

	return [
		{
			selector: "#accountPopover-button",
			position: "center",
			content: (props: {
				steps: StepType[];
				isOpen: boolean;
				currentStep: number;
				transition: boolean;
				setCurrentStep: Dispatch<SetStateAction<number>>;
				setIsOpen: (arg0: boolean) => void;
			}) => (
				<Stack
					id="welcomeMessage"
					direction="column"
					sx={{
						textAlign: "center",
						alignItems: "center",
						gap: 2,
						" + div": { display: "none !important" },
						width: isMobile ? undefined : "293px",
					}}
				>
					<Typography variant="h5" component="h1">
						{t`Welcome on the ${brandSetting.branding} Dashboard!`}
					</Typography>
					{/* <MotivationIllustration /> */}
					<LaunchIllustration sx={{ width: "239px" }} />
					<Typography variant="body1" component="p">
						{t`Thanks to the intro tour, discover the platform's features in a few seconds.`}
					</Typography>
					<Stack direction="row" sx={{ gap: 2, justifyContent: "center" }}>
						<Button
							variant="contained"
							color="error"
							onClick={() => {
								props.setIsOpen(false);
								updateLastTourDate("last_period");
							}}
							//   TODO : Update date of last visited intro tour côté serveur (pour que le message ne pop plus automatiquement)
						>
							{t`No thanks`}
						</Button>
						<Button
							variant="contained"
							color="success"
							onClick={() => {
								props.setCurrentStep(props.currentStep + 1);
							}}
							//   TODO : Update date of last visited intro tour côté serveur (pour que le message ne pop plus automatiquement)
						>
							{t`Take the tour!`}
						</Button>
					</Stack>
					<Button
						variant="text"
						sx={{ width: "fit-content" }}
						onClick={() => {
							props.setIsOpen(false);
						}}
					>
						{t`Remind me later.`}
					</Button>
				</Stack>
			),
			action: () => {
				waitClickId("dashboard");
			},
		},
		{
			selector: "#nodeSelector-button",
			// highlightedSelectors: [
			//   '#nodeSelector-button',
			//   '#nodeSelector-content',
			// ],
			// mutationObservables: ['#nodeSelector-content'],
			// resizeObservables: ['#nodeSelector-content'],
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`To get started, click here to select a site`}
				</Typography>
			),
			action: () => {
				navigate(
					generatePath(PATH_DASHBOARD.dashboard_with_node, { node: site_uuid }),
				);
				updateLastTourDate("last_period");
				waitClickId("dashboard");
			},
			// action: () => {
			//   try {
			//     document.querySelector<HTMLElement>('#nodeSelector-button')?.click()
			//   } catch (error) {
			//     console.error("Error during intro tour: ", error)
			//   }
			// },
			// actionAfter: () => {
			//   try {
			//     document.querySelector<HTMLElement>("div.MuiBackdrop-root")?.click();
			//   } catch (error) {
			//     console.error("Error during intro tour: ", error)
			//   }
			// },
		},
		// {
		//   selector: '#navBar',
		//   content: (props: ContentProps) => (
		//     <Typography variant="body1" component="p">
		//       {t`Click here to navigate between the different pages.`}
		//     </Typography>
		//   ),
		//   // action: Si le menu est réduit en PC ou mobile, l'ouvrir > Voir avant si on maintient cette étape ou pas.
		// },
		{
			selector: "main",
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Dashboard`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`The Dashboard brings all the essential information for monitoring your site's energy performance in the blink of an eye!`}
					</Typography>
				</Stack>
			),
			action: () => {
				waitClickId("dashboard");
			},
		},
		{
			selector: "#statsCard",
			highlightedSelectors: [
				"#statsCard",
				"#siteInfoCard",
				"#donutCard",
				// '#energyEfficiencyCard' Not included for scrolling improvement
			],
			resizeObservables: ["#statsCard", "#siteInfoCard", "#donutCard"],
			mutationObservables: ["#statsCard", "#siteInfoCard", "#donutCard"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="body1" component="p">
						{t`At the top, you will find your site's information, key energy indicators and breakdown by end-use over the sliding year. The consumption will be extrapolated if you have less than 1 year of data.`}
					</Typography>
					<br />
					<Alert
						severity="info"
						variant="outlined"
						sx={{ mb: 2, background: "none" }}
					>
						<Typography variant="caption" component="p">
							{t`Tip: To get currency/CO₂ equivalents and kWh/m²/year indicator, please indicate your floor area, price and CO₂ references in the 'Options' menu !`}
						</Typography>
					</Alert>
				</Stack>
			),
			action: () => {
				waitClickId("dashboard");
				document.querySelector("#statsCard")?.scrollIntoView();
			},
		},
		{
			selector: "#energyCard",
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`Here you can track the evolution of your consumption over time by day, week, month or year...`}
				</Typography>
			),
			action: () => {
				waitClickId("dashboard");
			},
		},

		{
			selector: "#powerCard",
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`... and determine precisely which device consumes when with the fine-grained 10 minutes 'Power' data (by default)!`}
				</Typography>
			),
			action: () => {
				waitClickId("dashboard");
			},
		},
		...(brandSetting.withAlerts && Site?.has_alerts_access
			? [
					{
						selector: "#alertCard",
						content: (_props: ContentProps) => (
							<Stack sx={{ width: isMobile ? undefined : "293px" }}>
								<Typography variant="body1" component="p">
									{t`With your latest alerts, be notified as soon as your consumption drifts!`}
								</Typography>
								<br />
								<Alert
									severity="info"
									variant="outlined"
									sx={{ mb: 2, background: "none" }}
								>
									<Typography variant="caption" component="p">
										{t`If you want to create new alerts, you can contact your energy efficiency engineer with the chat`}
									</Typography>
								</Alert>
							</Stack>
						),
						action: () => {
							waitClickId("dashboard");
						},
					},
				]
			: []),
		{
			selector: "#sharedNotesCard",
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`Take all your notes concerning your site directly in your Dashboard! (highlights, next steps...)`}
				</Typography>
			),
			action: () => {
				isMobile
					? params.node && params.installation
						? navigate(
								generatePath(PATH_DASHBOARD.dashboard_with_node, {
									node: params.node,
									installation: params.installation,
								}),
							)
						: navigate(PATH_DASHBOARD.dashboard)
					: waitClickId("dashboard");
			},
		},
		{
			selector: "main",
			highlightedSelectors: ["main", "#chartType"],
			// position: 'right',
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Analysis`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`To go further, the 'Analysis' page gives you access to all the visualizations available.`}
						{/* Typical profiles enable you to understand the average consumption profile of the building over a specific period. */}
					</Typography>
				</Stack>
			),
			action: () => {
				isMobile
					? navigate(PATH_DASHBOARD.analysis.root)
					: waitClickId("analysis");
				waitClickId("chartSelector-button");
			},
			actionAfter: () => {
				try {
					if (location.pathname.includes(PATH_DASHBOARD.analysis.root)) {
						document
							.querySelector<HTMLElement>("div.MuiBackdrop-root")
							?.click();
					}
					// console.debug('intro', document.getElementById('chartSelector-button'))
					// console.debug('[INTRO Tour button ]',document.getElementsByClassName("MuiBackdrop-root"))
					// document.getElementById('chartSelector-button')!.onclick
					//@ts-ignore
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
		},
		{
			selector: "#installationSelector-button",
			highlightedSelectors: [
				"#installationSelector-button",
				"#installationSelector-content",
			],
			resizeObservables: [
				"#installationSelector-button",
				"#installationSelector-content",
			],
			mutationObservables: ["#installationSelector-content"],
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`Click here to see your metering plan and navigate between measuring points`}
				</Typography>
			),
			action: () => {
				waitClickId("analysis");
				waitClickId("installationSelector-button");
			},
			actionAfter: () => {
				try {
					document.querySelector<HTMLElement>("div.MuiBackdrop-root")?.click();
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
		},
		{
			selector: ".chartContainer",
			highlightedSelectors: [".chartContainer", "#customtoolbar"],
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`With the toolbar, you can zoom or move in all the charts, and export them in image/excel in 1-click`}
				</Typography>
			),
			action: () => {
				waitClickId("analysis");
				try {
					document
						.querySelector<HTMLElement>("div.chart")
						?.classList.toggle("activeToolbar");
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
			actionAfter: () => {
				try {
					document
						.querySelector<HTMLElement>("div.chart")
						?.classList.toggle("activeToolbar");
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
		},
		{
			selector: ".chartLegend",
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`The interactive legend allows to show details and quickly highlight a end-use on mouse over and to filter categories on click.`}
				</Typography>
			),
			action: () => {
				waitClickId("analysis");
				try {
					document
						.querySelector<HTMLElement>(".chartLegend div:nth-of-type(2)")
						?.click();
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
			actionAfter: () => {
				try {
					document.querySelector<HTMLElement>(".legendDisplayAll")?.click();
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
		},
		{
			selector: "#optionsSelector-button",
			highlightedSelectors: [
				"#optionsSelector-button",
				"#optionsSelector-content",
			],
			mutationObservables: ["#optionsSelector-content"],
			content: (_props: ContentProps) => (
				<Typography
					variant="body1"
					component="p"
					sx={{ width: isMobile ? undefined : "293px" }}
				>
					{t`The 'Options' menu allows you to access more advanced features: display energy charts in €/CO2 equivalents and ratios, display temperature or degree days...`}
				</Typography>
			),
			action: () => {
				isMobile
					? navigate(PATH_DASHBOARD.analysis.root)
					: waitClickId("analysis");
				try {
					document
						.querySelector<HTMLElement>("#optionsSelector-button")
						?.click();
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
			actionAfter: () => {
				try {
					document.querySelector<HTMLElement>("div.MuiBackdrop-root")?.click();
				} catch (error) {
					console.error("Error during intro tour: ", error);
				}
			},
		},

		...(brandSetting.withAlerts && Site?.has_alerts_access
			? [
					{
						selector: "main",
						content: (_props: ContentProps) => (
							<Stack sx={{ width: isMobile ? undefined : "293px" }}>
								<Typography variant="h5" component="h1">
									{t`Alerts`}
								</Typography>
								<Typography variant="body1" component="p">
									{t`Find all your alerts and notifications in this page.`}
									{/* Typical profiles enable you to understand the average consumption profile of the building over a specific period. */}
								</Typography>
							</Stack>
						),
						action: () => {
							isMobile
								? params.node
									? navigate(
											generatePath(
												PATH_DASHBOARD.alerts.notifications_with_node,
												{ node: params.node },
											),
										)
									: navigate(PATH_DASHBOARD.alerts.notifications)
								: waitClickId("alerts");
						},
					},
				]
			: []),
		{
			selector: "main",
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Site information`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`The 'Site information' page gathers all information relative to your site and its equipments and let you update it progressively.`}
					</Typography>
				</Stack>
			),
			action: () => {
				isMobile
					? params.node
						? navigate(
								generatePath(PATH_DASHBOARD.general.site_info_with_node, {
									node: params.node,
								}),
							)
						: navigate(PATH_DASHBOARD.general.site_info)
					: waitClickId("site_info");
			},
		},
		// {
		//   selector: '#headerRight',
		//   highlightedSelectors: [
		//     '#headerRight',
		//     '#accountPopover-content .MuiPaper-root',
		//   ],
		//   mutationObservables: ['#accountPopover-content .MuiPaper-root'],
		//   resizeObservables: ['#accountPopover-content .MuiPaper-root'],
		//   content: (props: { steps: StepType[], currentStep: number, transition: boolean, setCurrentStep: Dispatch<SetStateAction<number>>, setIsOpen: (arg0: boolean) => void }) => (
		//     <Typography variant="body1" component="p">
		//       {t`Access all your settings here, replay this intro tour, give us feedback or even try our dark mode!`}
		//     </Typography>
		//   ),
		//   action: () => {
		//     try {
		//       //@ts-ignore
		//       const handleDoc = setInterval(
		//         () => {
		//           try {
		//             document.querySelector<HTMLElement>('#accountPopover-button')?.click()
		//             clearInterval(handleDoc)
		//           } catch (err) {
		//             console.debug(err, 'err #changingMode')
		//           }
		//         }, 10)
		//     } catch (error) {
		//       console.error("Error during intro tour: ", error)
		//     }
		//     goTo(PATH_DASHBOARD.dashboard)
		//   },
		//   actionAfter: () => {
		//     try {
		//       document.querySelector<HTMLElement>("div.MuiBackdrop-root")?.click();
		//     } catch (error) {
		//       console.error("Error during intro tour: ", error)
		//     }
		//   },
		// },
		// {
		//   selector: themeMode === 'light' ? '#darkMode' : '#lightMode',
		//   content: (props: ContentProps) => (
		//     <Typography variant="body1" component="p">
		//       {themeMode === 'light' ? t`Now try the dark mode!` : t`Now try the light mode!`}
		//     </Typography>
		//   ),
		//   action: () => {
		//     try {
		//       //@ts-ignore
		//       const handleDoc = setInterval(
		//         () => {
		//           try {
		//             const elem = document.querySelector<HTMLElement>(themeMode === 'light' ? '#darkMode' : '#lightMode')
		//             if (elem) {
		//               clearInterval(handleDoc)
		//               elem.click()
		//             }
		//           } catch (err) {
		//             console.debug(err, 'err #changingMode')
		//           }
		//         }, 10)
		//     } catch (error) {
		//       console.error("Error during intro tour: ", error)
		//     }
		//   },
		// },
		...(canUseChat()
			? [
					{
						selector: "#web-messenger-container",
						mutationObservables: ["#web-messenger-container"],
						resizeObservables: ["#web-messenger-container"],
						content: (_props: {
							steps: StepType[];
							currentStep: number;
							transition: boolean;
							setCurrentStep: Dispatch<SetStateAction<number>>;
							setIsOpen: (arg0: boolean) => void;
						}) => (
							<Stack sx={{ width: isMobile ? undefined : "293px" }}>
								<Typography variant="body1" component="p">
									{t`If you have any questions, contact directly your energy efficiency engineer with the Chat!`}
								</Typography>
								<br />
								<Alert
									severity="info"
									variant="outlined"
									sx={{ mb: 2, background: "none" }}
								>
									<Typography variant="caption" component="p">
										{t`Your opinion counts! Send us all your feedbacks and improvement suggestions by clicking on the 'Feedback' button at the top right.`}
									</Typography>
								</Alert>
							</Stack>
						),
						action: () => {
							waitClickId("dashboard");
							try {
								Smooch.open();
							} catch (err) {
								console.debug(err, "err smooch");
							}
						},
						actionAfter: () => {
							try {
								Smooch.close();
							} catch (err) {
								console.debug(err, "err smooch");
							}
						},
					},
				]
			: []),
		{
			selector: "#accountPopover-button",
			position: "center",
			content: (props: ContentProps) => (
				<Stack
					direction="column"
					sx={{
						textAlign: "center",
						alignItems: "center",
						gap: 3,
						width: isMobile ? undefined : "293px",
					}}
				>
					<Typography variant="h5" component="h1">
						{t`Good navigation!`}
					</Typography>
					<DataReportIllustration sx={{ width: "80%" }} />
					<Button
						variant="text"
						sx={{ width: "fit-content" }}
						onClick={() => {
							props.setIsOpen(false);
						}}
					>
						{t`Let's go!`}
					</Button>
				</Stack>
			),
			action: () => {
				waitClickId("dashboard");
			},
		},
	] as StepType[];
	// return isOpen ? Steps :[]
}
