import { Box, type BoxProps } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import Image from "src/components/others/Image";

// ----------------------------------------------------------------------

export default function LaunchIllustration({ ...other }: BoxProps) {
	const theme = useTheme();
	const PRIMARY = theme.palette.secondary.main; // to replace "#0CBC8B"
	const SECONDARY = theme.palette.primary.main; // to replace "#367CFF"

	return (
		<Box {...other}>
			<svg
				id="Layer_1"
				data-name="Layer 1"
				width="100%"
				height="100%"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 771.002 765.527"
			>
				<path
					d="M199.64,731.077c.09.09.19.18.28.27h-.42C199.55,731.257,199.59,731.167,199.64,731.077Z"
					fill={SECONDARY}
					opacity="0.1"
				/>
				<path
					d="M682.19,518.537a346.649,346.649,0,0,1-72.59,212.81H364.83c23.99-23.48,39.01-57.09,39.01-94.4,0-60.91-40.02-111.93-93.75-125.21l47.96-146.39-71.69-52.61-85.97,88.67a130.4,130.4,0,0,0-152.48,42.85A90.725,90.725,0,0,0,0,419.447C42.68,275.387,176.04,170.3,333.93,170.3,526.27,170.3,682.19,326.207,682.19,518.537Z"
					fill={SECONDARY}
					opacity="0.1"
				/>
				<path
					d="M534.146,390.55s-14.09-12.195-16.039-38.225c-1.509-20.154,16.039-5.986,16.039-5.986s-.946-14.523,9.442-12.392,15.251,17.646,15.251,17.646"
					fill="#0c0c0c"
				/>
				<path
					d="M597.684,339.264s51.662,9.066,28.126,78.318H611.8"
					fill="#0c0c0c"
				/>
				<path
					id="sk"
					d="M539.5,489.578c.229-.03,2.774,42.19,51.821,38.51,33.956-2.544,46.063-38.569,46.841-38.569-3.661-.778-9.826-1.91-11.452-2.778-5.347-2.873-11.963-5.862-13.686-16.245-.993-5.985.692-36.6.173-42.656l-52.121,11.633c.728,12.788,5.826,42.435-12.04,47.681C546.064,488.022,542.862,488.82,539.5,489.578Z"
					fill="#E98862"
				/>
				<path
					id="sh"
					d="M560.345,475.039a37.668,37.668,0,0,0,1.568-8.09,86.188,86.188,0,0,0-.341-18.815,57.138,57.138,0,0,0,28.807-12.62c.968,3.574,6.291,19.192,4.817,22.589C592.41,464.524,579.32,475.039,560.345,475.039Z"
					fill="#0c0c0c"
					opacity="0.52"
				/>
				<path
					d="M569.6,464.838c20.64-.465,46.065-11.783,48.285-39.609l2-25.107c2.22-27.826-10.6-51.549-36.794-53.64h0c-26.2-2.09-47.583,18.9-49.8,46.73l-.887,11.115a84.232,84.232,0,0,0,.068,11.475c.674,10.1,1.739,25.453,7.843,36.2C546.074,462.151,556.41,465.135,569.6,464.838Z"
					fill="#E98862"
				/>
				<circle cx="541.06" cy="407.042" r="3.237" fill="#0c0c0c" />
				<circle cx="570.783" cy="407.246" r="3.237" fill="#0c0c0c" />
				<path
					d="M615.577,433.577c7.962.635,15.1-7.368,15.732-15.33s-5.469-12.867-13.431-13.5l-4.528,5.008-4.618,23.279Z"
					fill="#E98862"
				/>
				<path
					d="M608.508,420.241s-.051-39.588-11.837-42.822c-7.85-2.154-27.905,7.841-45.553,3.234-7.72-2.015-15.274-.577-16.277,3.066-.4,1.443-3.86-19.9,9.455-35.034,10.6-12.047,42.751-13.5,56.989-8.358,39.424,14.225,18.12,66.263,18.12,66.263l-2.7,13.466Z"
					fill="#0c0c0c"
				/>
				<g opacity="0.52">
					<path
						d="M557.069,429.791l-.051,0c-2.566-.2-6.849-.817-6.7-2.736.124-1.591,5.8-18.426,6.45-20.34a.656.656,0,0,1,1.242.42c-2.521,7.455-6.3,18.939-6.385,20.02.218.473,2.839,1.123,5.5,1.328a.656.656,0,0,1-.048,1.31Z"
						fill="#0c0c0c"
					/>
				</g>
				<path
					d="M551.328,390.311a15.415,15.415,0,0,0-8.148-.567,13.639,13.639,0,0,0-3.827,1.364,15.981,15.981,0,0,0-3.285,2.317.833.833,0,0,0,1.049,1.292l.052-.04a14.2,14.2,0,0,1,3.013-1.886,11.6,11.6,0,0,1,3.358-.994,13.167,13.167,0,0,1,6.8.808l.082.035a1.251,1.251,0,0,0,.975-2.3C551.373,390.328,551.349,390.318,551.328,390.311Z"
					fill="#0c0c0c"
				/>
				<path
					d="M570.33,392.876a13.164,13.164,0,0,1,6.8-.808,11.646,11.646,0,0,1,3.358,1,14.215,14.215,0,0,1,3.012,1.886l.051.039a.832.832,0,0,0,1.05-1.29,16.005,16.005,0,0,0-3.284-2.318,13.639,13.639,0,0,0-3.827-1.364,15.414,15.414,0,0,0-8.148.565,1.251,1.251,0,0,0,.845,2.355l.061-.024Z"
					fill="#0c0c0c"
				/>
				<path
					d="M553.925,436.31a33.814,33.814,0,0,0,21.429-4.69,1.369,1.369,0,0,1,2.06,1.378c-.715,4.4-3.3,12.43-12.631,13.013-6.025.377-10.144-4.437-12.183-7.6A1.367,1.367,0,0,1,553.925,436.31Z"
					fill="#fff"
				/>
				<ellipse
					cx="561.397"
					cy="347.771"
					rx="16.095"
					ry="10.935"
					transform="translate(75.193 794.669) rotate(-74.365)"
					fill="#0c0c0c"
				/>
				<path
					id="b_1_b"
					d="M592.295,517.559c34.092-1.607,35.527-30.543,35.527-30.543s16.892,1.091,28.122,2.611c18.384,2.487,30.512,8.721,40.163,18.372,8.978,8.979,14.444,18.692,8.66,80.711-4.188,44.9-1.24,143.679-1.24,143.679H497.776S485.438,602.924,483.719,582.71c-3.283-38.589-5.948-72.206,2.063-80.218,16.472-16.471,48.8-15.476,62.958-15.474C548.971,487.018,545.868,519.748,592.295,517.559Z"
					fill={SECONDARY}
				/>
				<path
					id="b_4_b"
					d="M292.018,731.057l148.6.735a37.5,37.5,0,0,0,32.357-24.1l73.291-162.6a37.5,37.5,0,0,0-21.755-48.365c-19.365-7.349-38.483-2.409-45.831,16.954L411.341,658.712,289.005,660.7S291.1,731.124,292.018,731.057Z"
					fill={SECONDARY}
				/>
				<path
					d="M486.627,591.731a.5.5,0,0,0-1,.063l4.183,66.828a.5.5,0,0,0,.46.468h.07a.5.5,0,0,0,.468-.53"
					fill="#0c0c0c"
				/>
				<g id="b_4_ha">
					<path
						d="M461.285,663.4l30.134-10.562-4.326-.3a5.524,5.524,0,0,1-5.033-5.617,5.322,5.322,0,0,1,5.894-5l53.313,5.665-1.385,17.133a26.8,26.8,0,0,1-18.7,23.415L498.1,695.328a5.45,5.45,0,0,1-6.446-2.452,5.321,5.321,0,0,1,.836-6.252.461.461,0,0,1-.093-.662l-4.256,1.334a5.524,5.524,0,0,1-6.864-3.126,5.32,5.32,0,0,1,2.292-6.449.47.47,0,0,1-.131-.105l-1.749.548a5.451,5.451,0,0,1-6.446-2.452,5.327,5.327,0,0,1,1.931-7.13.463.463,0,0,1-.016-.109l-12.549,4.4a5.02,5.02,0,0,1-3.321-9.475Z"
						fill="#E98862"
					/>
					<path
						id="b_4_ha_sh"
						d="M477.649,668.964a.514.514,0,0,0,.171-.03l12.139-4.414a.5.5,0,1,0-.342-.94l-12.139,4.414a.5.5,0,0,0,.171.97Z"
						fill="#0a0a0a"
						opacity="0.2"
					/>
					<path
						id="b_4_ha_sh-2"
						data-name="b_4_ha_sh"
						d="M483.785,677.79a.51.51,0,0,0,.167-.029l9.569-3.395a.5.5,0,0,0-.334-.943l-9.569,3.395a.5.5,0,0,0,.167.972Z"
						fill="#0a0a0a"
						opacity="0.2"
					/>
					<path
						id="b_4_ha_sh-3"
						data-name="b_4_ha_sh"
						d="M492.8,686.745a.5.5,0,0,0,.178-.033L497.455,685a.5.5,0,1,0-.357-.934l-4.481,1.709a.5.5,0,0,0,.178.967Z"
						fill="#0a0a0a"
						opacity="0.2"
					/>
				</g>
				<path
					id="b_4_b-2"
					data-name="b_4_b"
					d="M518.932,700.7,705.77,731.67a37.5,37.5,0,0,0,41.038-43.5l-39.842-159.6a37.5,37.5,0,1,0-73.961,12.443l30.556,111.236L527.8,633.7Z"
					fill={SECONDARY}
				/>
				<g id="b_4_st">
					<path
						d="M687.915,655.735a.5.5,0,0,0,.074-.994L664.7,651.253a.494.494,0,0,0-.569.42.5.5,0,0,0,.421.569l23.288,3.487A.471.471,0,0,0,687.915,655.735Z"
						fill="#0c0c0c"
					/>
				</g>
				<g id="b_4_st-2" data-name="b_4_st">
					<path
						d="M700.569,732.389a.5.5,0,0,0,.064-1L519.979,700.316l8.757-66,134.825,17.929a.511.511,0,0,0,.425-.149.5.5,0,0,0,.134-.431l-14.589-62.941a.494.494,0,0,0-.577-.41.5.5,0,0,0-.41.576l14.477,62.274L528.36,633.255a.5.5,0,0,0-.563.443l-8.865,67a.5.5,0,0,0,.432.549L700.5,732.385A.487.487,0,0,0,700.569,732.389Z"
						fill="#0c0c0c"
					/>
				</g>
				<path
					d="M3.873,733.389H770a1,1,0,0,0,0-2H3.873a1,1,0,0,0,0,2Z"
					fill="#292a2e"
				/>
				<path
					d="M364.83,731.347c-21.65,21.22-50.62,34.18-82.46,34.18s-60.79-12.97-82.45-34.18Z"
					fill="#fff"
				/>
				<path
					d="M335.305,318.415a81.259,81.259,0,0,0-112.21,24.8l-.077.121A6.275,6.275,0,0,1,211.61,341.5c-5.23-20.9-1.594-46.941,10.909-66.54,24.059-37.713,86.623-65.553,124.314-41.46"
					fill={PRIMARY}
				/>
				<path
					d="M332.523,316.641A81.257,81.257,0,0,1,357.33,428.849l-.077.12a6.275,6.275,0,0,0,6.476,9.571c21.161-4.069,43.239-18.34,55.742-37.939,24.059-37.714,22.935-106.183-14.8-130.208"
					fill={PRIMARY}
				/>
				<path
					d="M534.308,104.6a306.317,306.317,0,0,1-42.272,104.113C452.223,271.126,402.675,317.68,363.293,356.1l-76.929-49.076c19.043-51.39,39.568-116.458,79.382-178.868a306.336,306.336,0,0,1,76.6-82.217"
					fill="#292a2e"
				/>
				<circle cx="439.67" cy="151.539" r="45.234" fill={PRIMARY} />
				<circle cx="439.67" cy="151.539" r="35.828" fill="#fff" />
				<rect
					x="276.481"
					y="329.546"
					width="92.017"
					height="12.002"
					transform="translate(231.076 -120.78) rotate(32.535)"
					fill="#292a2e"
				/>
				<path
					d="M534.308,104.6,442.341,45.934C492.035,9.272,536.345,0,536.345,0S546.609,44.087,534.308,104.6Z"
					fill={PRIMARY}
				/>
				<path
					d="M352.916,310.411c-23.64,37.056-59.052,84.589-62.9,82.132s24.327-54.6,47.966-91.66,33.789-44.989,37.641-42.532S376.555,273.355,352.916,310.411Z"
					fill={PRIMARY}
				/>
				<path
					d="M382.781,715.4,337.52,576.273a12.177,12.177,0,0,0-11.58-8.41H141.548a12.178,12.178,0,0,0-11.581,15.945l45.26,139.127a12.179,12.179,0,0,0,11.581,8.411H371.2A12.179,12.179,0,0,0,382.781,715.4Z"
					fill="#292a2e"
				/>
				<rect
					x="340.291"
					y="720.065"
					width="134.57"
					height="11.281"
					rx="5.641"
					fill="#292a2e"
				/>
				<circle cx="256.374" cy="656.391" r="19.339" fill="#fff" />
			</svg>
		</Box>
	);
}
