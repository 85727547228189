import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// import useSiteContext from 'src/hooks/useSiteContext'
import Fuse from "fuse.js";
import { debounce, min, sortBy } from "lodash";
import * as React from "react";
import { KeyboardEventHandler, useState } from "react";
import type { SitesList } from "src/@types/sites";
import useNodesContext from "src/hooks/useNodesContext";
import { t } from "ttag";
import { Iconify } from "../../../../utils/getIcon";

// Custom search field
const StyledSearchBar = styled(TextField)({
	"& .MuiOutlinedInput-root": {
		padding: "0 8px",
	},
});

const arraySearch = (array: any, keyword: string) => {
	// Fuzzy finder !

	// Flatten lists of sites with concatenated labels
	const searchableSites: any[] = [];

	const walkTree = (tree: any, parent: string) => {
		tree.forEach((item: any) => {
			if (item.type === "site") {
				searchableSites.push({
					uuid: item.uuid,
					label: parent + " - " + item.label,
				});
			} else if (item.children !== undefined) {
				walkTree(item.children, parent + " - " + item.label);
			}
		});
	};

	walkTree(array, "");
	const options = {
		isCaseSensitive: false,
		includeScore: true,
		shouldSort: false,
		ignoreLocation: true,
		threshold: 0.4,
		keys: ["label"],
	};
	const fuse = new Fuse(searchableSites, options);
	const result = fuse.search(keyword);

	const matchedSites = new Set(result.map((row) => row.item.uuid));
	const scores: { [key: string]: number | undefined } = {};
	result.forEach((row) => {
		scores[row.item.uuid as string] = row.score;
	});

	// Now deeply filter the initial list
	const filterTree = (tree: any) => {
		const res = tree
			.map((item: any) => {
				if (item.type === "site") {
					if (matchedSites.has(item.uuid)) {
						item.score = scores[item.uuid as string];
						return item;
					}
				} else if (item.children !== undefined) {
					const children = filterTree(item.children);
					const score = min(children.map((child: any) => child.score || 1));

					if (children.length)
						return {
							...item,
							score,
							children,
						};
				}
				return undefined;
			})
			.filter((item: any) => item !== undefined);

		return sortBy(res, [(item) => item.score]);
	};

	return filterTree(array);
};

const SearchBar = (props: {
	expanded: any;
	handleExpandClick?: (command?: string) => void;
	openDrawer: boolean;
	initSites: SitesList;
	Sites: SitesList;
	setSites: (SitesList: SitesList) => void;
	searchInput: string;
	setSearchInput: (searchInput: string) => void;
}) => {
	const {
		expanded,
		handleExpandClick,
		setSites,
		initSites,
		searchInput,
		setSearchInput,
	} = props;
	const open: boolean = props.openDrawer;

	const inputhandle = (new_search: string) => {
		setSearchInput(new_search);
		// handleSearch(new_search)
		// debounce a little the search, so that React has enough time to display the new searchInput value
		debounce(handleSearch, 200, { leading: false })(new_search);
	};

	const handleSearch = (new_search: string, _event?: any) => {
		// const old_search = searchInput
		// if (old_search === new_search) return
		// console.log(event, new_search,'onFocus')

		// setSearch(new_search);
		if (new_search === "") {
			// console.log(new_search, 'new_search')
			setSites(initSites);
			if (typeof handleExpandClick === "function") {
				handleExpandClick("collapse");
			}
		} else {
			const searchArray = arraySearch(initSites, new_search);
			setSites(searchArray);
			if (typeof handleExpandClick === "function") {
				new_search.length > 3
					? handleExpandClick("expand")
					: handleExpandClick("collapse");
			}
			// scroll to top of results
			// document.getElementById('node')?.scroll({ top: 0 })
		}
	};

	// Delete search when component will unmount
	React.useEffect(() => {
		return () => {
			setSearchInput("");
			handleSearch("");
		};
	}, []);

	const handleDeleteSearch = (_event: React.MouseEvent<HTMLElement>) => {
		setSearchInput("");
		handleSearch("");
	};
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				py: "8px",
				px: { xs: "10px", sm: "20px" },
				marginBottom: "12px",
			}}
		>
			<StyledSearchBar
				id="mysearch"
				placeholder={t`Search`}
				size="small"
				sx={{ flexGrow: 1 }}
				value={searchInput}
				// onKeyDown={(event)=> (event.key === 'ArrowDown' ? setFocusOnArrowDown(true) : null)}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					inputhandle(open ? event.target.value : "")
				}
				autoFocus
				// type={'search'}
				autoComplete={"off"}
				InputProps={{
					startAdornment: expanded ? (
						<Tooltip
							title={
								expanded.length === 0
									? t`Expand all`
									: expanded.length === 1
										? t`Collapse node selected`
										: t`Collapse all`
							}
						>
							<IconButton
								onClick={() => {
									if (typeof handleExpandClick === "function")
										handleExpandClick();
								}}
								size="small"
								sx={{ transform: "scale(0.9)" }}
							>
								{expanded.length === 0
									? Iconify("ph:arrows-out-line-vertical")
									: Iconify("ph:arrows-in-line-vertical")}
							</IconButton>
						</Tooltip>
					) : null,
					endAdornment: (
						<Tooltip
							title={
								searchInput && searchInput.length > 0 ? t`Delete` : t`Search`
							}
						>
							<IconButton
								onClick={handleDeleteSearch}
								size="small"
								sx={{ transform: "scale(0.9)" }}
							>
								{searchInput && searchInput.length > 0
									? Iconify("ph:x-circle-light")
									: Iconify("ph:magnifying-glass")}
							</IconButton>
						</Tooltip>
					),
				}}
				variant="outlined"
			/>
		</Box>
	);
};

export default SearchBar;
