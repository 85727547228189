import { brandSettingsType } from "src/components/others/BrandSettings";
// ----------------------------------------------------------------------

export type ThemeMode = "light" | "dark";
export type ThemeDirection = "rtl" | "ltr";
export type ThemeColorPresets =
	| "default"
	| "purple"
	| "cyan"
	| "blue"
	| "orange"
	| "red";
export type ThemeLayout = "vertical" | "horizontal";
export type ThemeStretch = boolean;
export const ValidBrandings = [
	"smartImpulse",
	"smeg",
	"herveThermique",
	"ejMaintenance",
] as const;
export type ThemeBranding = (typeof ValidBrandings)[number];

type ColorVariants = {
	name: string;
	lighter: string;
	light: string;
	main: string;
	dark: string;
	darker: string;
	mainGradient?: string;
	contrastText: string;
};

export type SettingsValueProps = {
	themeMode: ThemeMode;
	themeDirection: ThemeDirection;
	themeColorPresets: ThemeColorPresets;
	themeStretch: ThemeStretch;
	themeLayout: ThemeLayout;
};

export type SettingsContextProps = {
	themeMode: ThemeMode;
	themeDirection: ThemeDirection;
	themeColorPresets: ThemeColorPresets;
	themeLayout: ThemeLayout;
	themeStretch: boolean;
	setColor: ColorVariants;
	colorOption: {
		name: string;
		value: string;
	}[];
	onToggleMode: VoidFunction;
	onToggleStretch: VoidFunction;
	onResetSetting: VoidFunction;
	onChangeMode: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeDirection: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeColor: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeLayout: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleIntroTour: () => void;
};
