import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import { Icon } from "src/utils/icon";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(1, 2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	padding: theme.spacing(0, 3) + "!important",
}));

export interface DialogTitleProps {
	children?: React.ReactNode;
	onClose?: () => void;
	sx?: SxProps;
}

export const StyledDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 16,
						top: 16,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Icon icon="ph:x-duotone" fontSize="24px" />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};
