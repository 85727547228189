import {
	brandSettings,
	type brandSettingsType,
} from "src/components/others/BrandSettings";
import {
	type SettingsValueProps,
	type ThemeBranding,
	ValidBrandings,
} from "./components/settings/type";
// routes
import { PATH_DASHBOARD } from "./routes/paths";

// API
// ----------------------------------------------------------------------

const appendSlash = (val: string) => (val.slice(-1) === "/" ? val : val + "/");

export const API_URL = appendSlash(
	process.env.REACT_APP_API_URL ||
		"https://proxy-maximej.internal.smartimpulse.com",
);
export const ROOT_URL = appendSlash(process.env.REACT_APP_ROOT_URL || API_URL);

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard_with_node; // as '/dashboard'
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard; // as '/dashboard'

export const routerBasename =
	process.env.PUBLIC_URL && process.env.PUBLIC_URL.startsWith("http")
		? new URL(process.env.PUBLIC_URL).pathname
		: process.env.PUBLIC_URL;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
	MOBILE_HEIGHT: 50,
	MAIN_DESKTOP_HEIGHT: 104,
	DASHBOARD_DESKTOP_HEIGHT: 104,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
	DESKTOP_HEIGHT: 80,
};

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 280,
	DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 38,
	DASHBOARD_ITEM_SUB_HEIGHT: 30,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
	themeMode: "light",
	themeDirection: "ltr",
	themeColorPresets: "default",
	themeLayout: "horizontal",
	themeStretch: false,
};

export const themeBranding: ThemeBranding =
	process.env.REACT_APP_BRAND !== undefined &&
	(ValidBrandings as readonly string[]).includes(process.env.REACT_APP_BRAND)
		? (process.env.REACT_APP_BRAND as ThemeBranding)
		: "smartImpulse";
// console.log('themeBranding', themeBranding)
export const brandSetting: brandSettingsType = brandSettings[themeBranding];

export const BENCHMARK_SITES_NB = 10;
export const BENCHMARK_VENTILATION_ID = 3;
export const BENCHMARK_AUXILIARIES_ID = 4;
