import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import type React from "react";
import { useEffect, useState } from "react";
import {
	type Control,
	Controller,
	type ControllerRenderProps,
	type FieldErrorsImpl,
	type FieldValues,
	useForm,
} from "react-hook-form";
import { t } from "ttag";
import * as Yup from "yup";

// @mui
import {
	Box,
	Button,
	DialogContent,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useFeedbackContext from "src/hooks/useFeedbackContext";
import useSiteContext from "src/hooks/useSiteContext";
import { contactUs } from "src/services/service";
import { StyledDialog, StyledDialogTitle } from "./StyledDialog";

export type ContactData = {
	name: string;
	email: string;
	subject: string;
	message: string;
	url: string;
	site?: string;
};

type ContactFieldProps = {
	control: Control<FieldValues>;
	errors: FieldErrorsImpl;
	fieldName: string;
	label: string;
	requiredLabel: string;
	otherProps?: object;
};

function ContactField(props: ContactFieldProps) {
	const { control, errors, fieldName, label, requiredLabel, otherProps } =
		props;
	const hasError = errors[fieldName] !== undefined;

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		field: ControllerRenderProps<FieldValues, string>,
	) => {
		field.onChange(event);
	};

	return (
		<Box sx={{ width: "100% " }}>
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => (
					<TextField
						{...field}
						{...otherProps}
						label={label}
						error={hasError}
						value={field.value}
						onChange={(e) => handleChange(e, field)}
						required={true}
						helperText={
							hasError
								? errors[fieldName]?.type === "email"
									? t`The input email must be a valid email.`
									: requiredLabel
								: ""
						}
						sx={{ "& .MuiFormLabel-asterisk": { color: "red" }, width: 1 }}
					/>
				)}
			/>
		</Box>
	);
}

export default function ContactForm() {
	const { enqueueSnackbar } = useSnackbar();
	const { openContact, setOpenContact } = useFeedbackContext();
	const { Site } = useSiteContext();
	const { user } = useAuth();

	const contactSchema = Yup.object({
		name: Yup.string().required(),
		email: Yup.string().required().email(),
		subject: Yup.string().required(),
		message: Yup.string().required(),
		url: Yup.string().required(),
		site: Yup.string(),
	});

	const {
		handleSubmit,
		setValue,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm({ resolver: yupResolver(contactSchema), mode: "all" });

	const [isSubmitSuccessful, setIsSubmitSuccesful] = useState(false);

	const handleClose = () => {
		// console.log(openContact,'handleClose')
		setOpenContact(false);
	};

	const onSubmit = (data: FieldValues) => {
		contactUs(data as ContactData)
			.then((response) => {
				setOpenContact(false);
				if (response.status === 200) {
					enqueueSnackbar(t`Your message was successfully sent.`, {
						variant: "success",
					});
				} else {
					enqueueSnackbar(t`An error occurred. Please try again.`, {
						variant: "error",
					});
				}
				setIsSubmitSuccesful(true);
			})
			.catch((error) => {
				enqueueSnackbar(t`An error occurred. Please try again.`, {
					variant: "error",
				});
				console.error(error);
			});
		// console.log(data, 'contact form')
	};

	useEffect(() => {
		if (user !== undefined && user !== null) {
			if (isSubmitSuccessful) {
				reset({
					name: user.displayName,
					email: user.email,
					subject: "",
					message: "",
					url: "",
					site: "",
				});
				setIsSubmitSuccesful(false);
			} else {
				setValue("name", user.displayName);
				setValue("email", user.email);
				setValue("url", window.location.href);
			}
		}
	}, [user, isSubmitSuccessful]);

	useEffect(() => {
		setValue("site", Site?.uuid);
	}, [Site]);

	return (
		<StyledDialog
			maxWidth={"md"}
			transitionDuration={0}
			sx={{
				minHeight: "100%",
				"& .MuiDialogTitle-root": { p: 3 },
				"& .MuiDialogContent-root": { p: 3, pt: 1 },
			}}
			fullWidth={true}
			onClose={handleClose}
			aria-labelledby="contact-form"
			open={openContact}
		>
			<StyledDialogTitle onClose={handleClose}>
				<Typography variant="h4">{t`Contact us`}</Typography>
			</StyledDialogTitle>
			<DialogContent>
				<Stack
					direction="column"
					alignItems="start"
					justifyContent="space-between"
					spacing={2}
				>
					<Typography variant="subtitle1">
						{t`Do not hesitate to contact us for any questions or technical issue.`}
					</Typography>
					{(user === undefined ||
						user === null ||
						user.displayName === undefined) && (
						<ContactField
							control={control}
							errors={errors}
							fieldName="name"
							label={t`Your name`}
							requiredLabel={t`Your name is required`}
						/>
					)}
					{(user === undefined ||
						user === null ||
						user.email === undefined) && (
						<ContactField
							control={control}
							errors={errors}
							fieldName="email"
							label={t`Your Email`}
							requiredLabel={t`Your email is required`}
						/>
					)}
					<ContactField
						control={control}
						errors={errors}
						fieldName="subject"
						label={t`Subject`}
						requiredLabel={t`Subject is required`}
					/>
					<ContactField
						control={control}
						errors={errors}
						fieldName="message"
						label={t`Message`}
						requiredLabel={t`Message is required`}
						otherProps={{ multiline: true, rows: 4 }}
					/>
					<Box sx={{ textAlign: "right", width: "100%" }}>
						<Button
							disabled={!isValid}
							variant="text"
							onClick={handleSubmit(onSubmit)}
						>
							{t`Send`}
						</Button>
					</Box>
				</Stack>
			</DialogContent>
		</StyledDialog>
	);
}
