import type React from "react";
import { t } from "ttag";

import {
	Box,
	Button,
	DialogContent,
	Rating,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import OnlineReviewsIllustration from "src/assets/illustrations/OnlineReviews";
import useFeedbackContext from "src/hooks/useFeedbackContext";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { sendFeedback } from "src/services/service";
import { StyledDrawerWithHandle } from "../dashboard/MagicButton/layout/layoutMagicButton";
import { CustomScrollbar } from "./Scrollbar";
import { StyledDialog, StyledDialogTitle } from "./StyledDialog";

export type FeedbackData = {
	message: string;
	score: number | null;
	site?: string;
	user_agent: string;
	url: string;
};

export function FeedbackContent({
	mode,
	setIsOpen,
}: { mode: "default" | "tour"; setIsOpen?: (open: boolean) => void }) {
	const { enqueueSnackbar } = useSnackbar();
	const { setOpenForm } = useFeedbackContext();
	const [localFeedback, setLocalFeedback] = useState<string>("");
	const [localRating, setLocalRating] = useState<number | null>(null);

	const { Site } = useSiteContext();
	const { MultiSites } = useNodesContext();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClose = () => {
		setOpenForm(false);
		if (setIsOpen) setIsOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalFeedback(event.target.value);
	};

	const handleClick = () => {
		const feedback_data: FeedbackData = {
			message: localFeedback,
			score: localRating,
			site:
				Site?.uuid ||
				(MultiSites && "uuid" in MultiSites ? MultiSites.uuid : "all"),
			user_agent: navigator.userAgent,
			url: window.location.href,
		};
		sendFeedback(feedback_data)
			.then((response) => {
				setOpenForm(false);
				setLocalFeedback("");
				setLocalRating(null);
				if (setIsOpen) setIsOpen(false);
				if (response.status === 200) {
					enqueueSnackbar(t`Your feedback was successfully sent.`, {
						variant: "success",
					});
				} else {
					enqueueSnackbar(t`An error occurred. Please try again.`, {
						variant: "error",
					});
				}
			})
			.catch((error) => {
				enqueueSnackbar(t`An error occurred. Please try again.`, {
					variant: "error",
				});
				console.error(error);
			});
	};

	return (
		<Stack
			direction="column"
			sx={{ gap: 2, flexGrow: 1, px: isMobile ? 1 : 0 }}
		>
			<Stack
				direction={isMobile ? "column" : "row"}
				alignItems="stretch"
				sx={{ gap: 3 }}
			>
				{(mode === "default" || !isMobile) && (
					<OnlineReviewsIllustration
						sx={{
							"& svg": { maxHeight: "300px" },
							...(!isMobile && { alignSelf: "flex-start" }),
						}}
					/>
				)}
				<Stack direction="column" sx={{ gap: 2, flexGrow: 1 }}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle1">
							{t`Rate your complete experience`}
						</Typography>
						<Rating
							name="feedback"
							value={localRating}
							size="large"
							onChange={(_event, newValue) => {
								setLocalRating(newValue);
							}}
						/>
					</Stack>
					<Typography variant="subtitle1">
						{t`Questions ? Improvements suggestions ? It's here !`}
					</Typography>
					<Box component="form" autoComplete="off" sx={{ width: 1 }}>
						<TextField
							multiline
							label={t`Feedback`}
							value={localFeedback}
							onChange={handleChange}
							rows={4}
							sx={{ width: 1 }}
						/>
					</Box>
				</Stack>
			</Stack>
			<Stack
				direction="row-reverse"
				alignItems="center"
				justifyContent="space-between"
			>
				<Button
					disabled={localRating === null || localFeedback === ""}
					variant="text"
					onClick={handleClick}
				>
					{t`Send`}
				</Button>
				{mode === "tour" && (
					<Button variant="text" color="info" onClick={handleClose}>
						{t`Ignore this step`}
					</Button>
				)}
			</Stack>
		</Stack>
	);
}

export default function FeedbackForm() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { openForm, setOpenForm } = useFeedbackContext();

	const handleOpen = () => {
		setOpenForm(true);
	};

	const handleClose = () => {
		setOpenForm(false);
	};

	if (isMobile) {
		return (
			<StyledDrawerWithHandle
				anchor="bottom"
				open={openForm}
				onOpen={handleOpen}
				onClose={handleClose}
				PaperProps={{
					sx: {
						height: "fit-content!important",
						maxHeight: "90%!important",
						...CustomScrollbar(),
					},
				}}
			>
				<Box sx={{ overflowY: "scroll" }}>
					<Typography variant="h4" component="h1" textAlign="center">
						{t`Do you have any suggestion ?`}
					</Typography>
					<FeedbackContent mode="default" />
				</Box>
			</StyledDrawerWithHandle>
		);
	} else {
		return (
			<StyledDialog
				id="feedback-form"
				maxWidth={"md"}
				transitionDuration={0}
				sx={{
					minHeight: "100%",
					"& .MuiDialogTitle-root": { p: 3 },
					"& .MuiDialogContent-root": { p: 3, pt: 1 },
				}}
				fullWidth={true}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={openForm}
			>
				<StyledDialogTitle onClose={handleClose}>
					<Typography variant="h4" component="div">
						{t`Do you have any suggestion ?`}
					</Typography>
				</StyledDialogTitle>
				<DialogContent>
					<FeedbackContent mode="default" />
				</DialogContent>
			</StyledDialog>
		);
	}
}
