import {
	AppBar,
	Box /* Tooltip */,
	/* Box, */ Stack,
	Toolbar,
} from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import { HEADER, /* NAVBAR */ brandSetting } from "src/config";
import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/others/Iconify";
import Logo from "../../../components/others/Logo";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";

import { alpha } from "@mui/material/styles";
import FeedbackForm from "src/components/others/FeedbackForm";
import SiteSelector from "../../../components/dashboard/siteSelector/SiteSelector";
import CollapseButton from "./CollapseButton";
import FeedbackButton from "./FeedbackButton";
import HijackedButton from "./HijackedButton";

// ----------------------------------------------------------------------

type RootStyleProps = {
	isCollapse: boolean;
	isOffset: boolean;
	verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
	shouldForwardProp: (prop) =>
		prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<RootStyleProps>(({ isOffset, verticalLayout, theme }) => ({
	...cssStyles(theme).bgBlur(),
	backgroundColor: "theme.palette.gradients.mainColor",
	height: HEADER.MOBILE_HEIGHT,
	zIndex: theme.zIndex.appBar + 1,
	backdropFilter: "blur(6px)",
	WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
	boxShadow: theme.customShadows.z8,
	color: theme.palette.text.primary,
	backgroundRepeat: "no-repeat !important",
	backgroundSize: "cover !important",
	transition: theme.transitions.create(["width", "height"], {
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up("sm")]: {
		height: "64px",
		width: "auto !important",
		margin: "20px !important",
		left: 0,
		borderRadius: "16px !important",
		// width:"auto !important",
		// width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
		// ...(isCollapse && {
		//   width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		// }),
		...(isOffset && {
			height: HEADER.MOBILE_HEIGHT,
		}),
		...(verticalLayout && {
			width: "100%",
			height: HEADER.MOBILE_HEIGHT,
			backgroundColor: `linear-gradient(${theme.palette.background.default},rgba(255,0,0,0))`,
		}),
	},
}));

// ----------------------------------------------------------------------

type Props = {
	onOpenSidebar: VoidFunction;
	isCollapse?: boolean;
	verticalLayout?: boolean;
	outsideSiteContext?: boolean;
};

export default function DashboardHeader({
	onOpenSidebar,
	isCollapse = false,
	verticalLayout = false,
	outsideSiteContext = false,
}: Props) {
	const isOffset =
		useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

	const { collapseClick, onToggleCollapse } = useCollapseDrawer();
	const isDesktop = useResponsive("up", "lg");
	const isMedium = useResponsive("up", "md");

	return (
		<RootStyle
			isCollapse={isCollapse}
			isOffset={isOffset}
			verticalLayout={verticalLayout}
			sx={{ backgroundImage: `url(${brandSetting.assetsUrl}/header.png)` }}
		>
			<Toolbar
				sx={{
					padding: "0 19px !important",
					minHeight: "100% !important",
					px: { lg: 5 },
					flexDirection: "row",
					justifyContent: "left",
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent={"start"}>
					{isDesktop && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"start"}
							sx={{
								width: 265,
								mr: 2,
								justifyContent: "space-between",
								justifyItems: "center",
								flexGrow: 1,
							}}
						>
							<Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
								<Box sx={{ justifyContent: "flex-start" }}>
									<CollapseButton
										onToggleCollapse={onToggleCollapse}
										collapseClick={collapseClick}
									/>
								</Box>
								<Box sx={{ flexGrow: 1, justifyContent: "flex-end" }}>
									<Logo justify="left" color="white" />
								</Box>
							</Stack>
						</Stack>
					)}
					{/* <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} /> */}
					{!isDesktop && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"start"}
							sx={{ mr: !isMedium ? 0 : 1 }}
						>
							<IconButtonAnimate
								onClick={onOpenSidebar}
								sx={{
									mr: !isMedium ? 0 : 1,
									color: "white",
									"&:hover": {
										backgroundColor: (theme) =>
											alpha(theme.palette.grey[900], 0.2),
									},
								}}
							>
								<Iconify icon="ph:list-bold" />
							</IconButtonAnimate>
							{isMedium && <Logo justify="left" color="white" />}
						</Stack>
					)}
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					flexGrow="1"
					sx={{ minWidth: "0" }}
				>
					{!outsideSiteContext && <SiteSelector />}
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="end"
					id="headerRight"
				>
					{<LanguagePopover />}
					<HijackedButton />
					{/* <NotificationsPopover /> */}
					<FeedbackButton />
					<FeedbackForm />
					{/* <ContactsPopover /> */}
					<AccountPopover outsideSiteContext={outsideSiteContext} />
				</Stack>
			</Toolbar>
		</RootStyle>
	);
}
