// @mui
import { type BoxProps, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { brandSetting } from "src/config";
// ----------------------------------------------------------------------

interface Props extends BoxProps {
	disabledLink?: boolean;
	color?: string;
	justify?: "left" | "center" | "right";
}

export default function Logo({
	disabledLink = false,
	justify = "center",
	sx,
	color,
}: Props) {
	const logoRef: string =
		typeof color !== "undefined" && color === "white"
			? "logo_white.svg"
			: "logo_color.svg";
	const logo = (
		<Stack
			direction="row"
			sx={{
				// width: 200,
				height: 44,
				justifyContent: justify,
				"& img": {
					maxWidth: "100%",
					maxHeight: "100%",
				},
				...sx,
			}}
		>
			<img
				src={`${brandSetting.assetsUrl}/${logoRef}`}
				alt={`${brandSetting.branding} logo`}
			/>
		</Stack>
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="/">{logo}</RouterLink>;
}
