import { Box, type SxProps } from "@mui/material";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import SimpleBarReact, { type Props as ScrollbarProps } from "simplebar-react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
	flexGrow: 1,
	height: "100%",
	overflow: "hidden",
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
	maxHeight: "100%",
	"& .simplebar-scrollbar": {
		"&:before": {
			backgroundColor: alpha(theme.palette.grey[600], 0.48),
		},
		"&.simplebar-visible:before": {
			opacity: 1,
		},
	},
	"& .simplebar-track.simplebar-vertical": {
		width: 10,
	},
	"& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
		height: 6,
	},
	"& .simplebar-mask": {
		zIndex: "inherit",
	},
	"& .simplebar-content-wrapper": { outline: "none" },
}));

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
	sx?: SxProps;
}

export default function Scrollbar({ children, sx, ...other }: Props) {
	const userAgent =
		typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

	const isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			userAgent,
		);
	const location = useLocation();
	const scrollRef = useRef<SimpleBarReact>(null);

	useEffect(() => {
		// Remove tabIndex=0 from simplebar lib which causes border highlight when tab or click
		try {
			document
				.querySelectorAll(".simplebar-content-wrapper")
				.forEach((item) => {
					item.setAttribute("tabindex", "-1");
				});
		} catch {}
	});

	useEffect(() => {
		if (process.env.NODE_ENV !== "test") {
			// setInterval to listen to URL changes
			var oldUrl = window.location.href;
			const urlChange = setInterval(() => {
				const currentUrl = window.location.href;
				if (scrollRef.current && currentUrl !== oldUrl) {
					// do no reset scroll between info/uuid and info/uuid/kpi
					if (
						!oldUrl.includes(currentUrl + "/kpi") &&
						!currentUrl.includes(oldUrl + "/kpi")
					)
						scrollRef.current.getScrollElement().scrollTo(0, 0);
					oldUrl = currentUrl;
					clearInterval(urlChange);
				}
			}, 100);
		}
	}, [location.pathname]);

	if (isMobile) {
		return (
			<Box sx={{ overflowX: "auto", ...sx }} {...other}>
				{children}
			</Box>
		);
	}

	return (
		<RootStyle>
			<SimpleBarStyle
				ref={scrollRef}
				timeout={500}
				tabIndex={-1}
				clickOnTrack={false}
				sx={sx}
				scrollableNodeProps={{ tabIndex: -1 }}
				{...other}
			>
				{children}
			</SimpleBarStyle>
		</RootStyle>
	);
}

export function CustomScrollbar() {
	return {
		// msOverflowStyle: 'none',
		// overflowY: 'scroll',
		"&::-webkit-scrollbar": {
			scrollbarWidth: "none",
			width: "10px",
		},
		"&::-webkit-scrollbar-track": {
			background: "transparent",
			// background: theme.palette.grey[300],
			borderRadius: "8px",
		},
		"&::-webkit-scrollbar-thumb ": {
			// background: theme.palette.primary.main,
			backgroundColor: "transparent",
			borderRadius: "8px",
		},
		"&:hover::-webkit-scrollbar-thumb ": {
			// background: theme.palette.primary.main,
			backgroundColor: alpha("#637381", 0.48),
			borderRadius: "8px",
		},
		"&::-webkit-scrollbar-thumb:hover": {
			// background: theme.palette.primary.dark,
			backgroundColor: alpha("#637381", 0.8),
		},
		".scroller": {
			// overflowY: 'scroll',
			// scrollbarWidth: 'none',
			// scrollbarColor:  theme.palette.primary.main,
			scrollbarColor: alpha("#637381", 0.48),
			// scrollbarColorHovezr:  [theme.palette.primary.main, theme.palette.grey[300]],
		},
		".scroller:hover": {
			// scrollbarColor:  theme.palette.primary.dark,
			scrollbarColor: alpha("#637381", 0.8),
		},
	};
}
