// i18n
// import './locales/i18n';

// highlight
import "./utils/highlight";

// scroll bar
import "simplebar/src/simplebar.css";

// lightbox
import "react-image-lightbox/style.css";

// map
import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

// polyfills
import "src/utils/requestIdleCallback-polyfill";

import reportWebVitals from "./reportWebVitals";
//
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// ----------------------------------------------------------------------

import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import type { Integration } from "@sentry/types";
import { createRoot } from "react-dom/client";
import { FullApp } from "./FullApp";

// polyfills
// see https://github.com/facebook/create-react-app/issues/13118
// @ts-ignore
// Array.prototype.at polyfill for arquero
export * from "core-js/stable/array/at";
// polyfill for AbortController to abort request
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
// polyfill for ParentNode.ReplaceChildren for Google Maps API
import "@ungap/replace-children";

const container = document.getElementById("root");
const root = createRoot(container!);

if (!!process.env.REACT_APP_SENTRY) {
	Sentry.init({
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
		dsn: process.env.REACT_APP_SENTRY,
		release: process.env.REACT_APP_GIT_SHA,
		integrations: [
			new BrowserTracing(),
			new CaptureConsole({
				levels: ["warn", "error"],
			}) as unknown as Integration,
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.25,
	});
}

root.render(
	<FullApp />,
	// document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
