import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
	return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number: number) {
	return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
	return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
	return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number: string | number) {
	return numeral(number).format("0.0 b");
}

export const formatNumberLocale = (
	inputValue: number | string,
	numberLocale: string,
) => {
	let decimalPrecision = 0;
	let formattedValue = inputValue;

	if (typeof inputValue === "string") {
		let decimalSep = new Intl.NumberFormat(numberLocale)
			.formatToParts(0.5)
			.find((part) => part.type === "decimal")?.value;
		let thousandSep = new Intl.NumberFormat(numberLocale)
			.formatToParts(1000)
			.find((part) => part.type === "group")?.value;
		decimalSep = decimalSep ? decimalSep : ".";
		thousandSep = thousandSep ? thousandSep : "";

		// replace in case of number = 1. => 1,
		formattedValue = inputValue.replace(".", decimalSep);

		const splitValue = inputValue.split(decimalSep);
		if (splitValue.length > 1)
			decimalPrecision = Math.max(splitValue[1].length, 1);

		if (
			inputValue !== "" &&
			!(inputValue.endsWith(decimalSep) || inputValue.endsWith("."))
		) {
			const thousandRegexp = new RegExp(
				(/\s/.test(thousandSep) ? "\\s" : thousandSep) + "+",
				"g",
			);
			const numberValue = Number.parseFloat(
				inputValue.replace(thousandRegexp, "").replace(decimalSep, "."),
			);
			formattedValue = new Intl.NumberFormat(numberLocale, {
				minimumFractionDigits: decimalPrecision,
				maximumFractionDigits: 20,
			}).format(numberValue);
		}
	} else {
		formattedValue = new Intl.NumberFormat(numberLocale, {
			minimumFractionDigits: decimalPrecision,
			maximumFractionDigits: 20,
		}).format(inputValue);
	}
	return formattedValue;
};

export const unformatLocale = (
	formattedValue: string,
	numberLocale: string,
) => {
	if (formattedValue) {
		let decimalSep = new Intl.NumberFormat(numberLocale)
			.formatToParts(0.5)
			.find((part) => part.type === "decimal")?.value;
		let thousandSep = new Intl.NumberFormat(numberLocale)
			.formatToParts(1000)
			.find((part) => part.type === "group")?.value;
		decimalSep = decimalSep ? decimalSep : ".";
		thousandSep = thousandSep ? thousandSep : "";

		const thousandRegexp = new RegExp(
			(/\s/.test(thousandSep) ? "\\s" : thousandSep) + "+",
			"g",
		);
		return Number.parseFloat(
			formattedValue.replace(thousandRegexp, "").replace(decimalSep, "."),
		);
	} else return "";
};
