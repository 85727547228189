import { debounce } from "lodash";
// https://stackoverflow.com/a/67504622/51500
import {
	type DependencyList,
	type EffectCallback,
	useCallback,
	useEffect,
	useRef,
} from "react";

export function useLazyEffect(
	effect: EffectCallback,
	deps: DependencyList = [],
	wait = 300,
) {
	const cleanUp = useRef<void | (() => void)>();
	const effectRef = useRef<EffectCallback>();
	effectRef.current = useCallback(effect, deps);
	const lazyEffect = useCallback(
		// biome-ignore lint: needs assignation here
		debounce(() => (cleanUp.current = effectRef.current?.()), wait),
		[],
	);
	useEffect(lazyEffect, deps);
	useEffect(() => {
		return () =>
			cleanUp.current instanceof Function ? cleanUp.current() : undefined;
	}, []);
}
