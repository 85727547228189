import { useContext } from "react";
import { AlertsContext } from "../contexts/AlertsContext";

// ----------------------------------------------------------------------

const useAlertsContext = () => {
	const context = useContext(AlertsContext);

	if (!context)
		throw new Error("Alerts context must be use inside AlertsProvider");

	return context;
};

export default useAlertsContext;
