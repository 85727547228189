import type { DateRange } from "@mui/lab";
import { type ReactNode, createContext, useState } from "react";

export type DateRangeDate = DateRange<Date>;

export type DateRangeContextProps = {
	mobileOpen?: boolean;
	setMobileOpen: any;
	handleClick: (event: any) => void;
};

const initialState: DateRangeContextProps = {
	mobileOpen: false,
	setMobileOpen: () => {},
	handleClick: () => {},
};

const DateRangePickerContext = createContext(initialState);

type DateRangeDrawerProviderProps = { children: ReactNode };

export default function DateRangePickerContextProvider({
	children,
}: DateRangeDrawerProviderProps) {
	const [anchorElContext, setAnchorElContext] = useState(null);
	const [_open, setOpen] = useState(Boolean(anchorElContext));
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleClickPopover = (event: any) => {
		setAnchorElContext(event.currentTarget);
		setOpenPopoverContext(true);
	};

	const setOpenPopoverContext = (event: boolean) => {
		setOpen(event);
	};

	return (
		<DateRangePickerContext.Provider
			value={{
				mobileOpen: mobileOpen,
				handleClick: handleClickPopover,
				setMobileOpen: setMobileOpen,
			}}
		>
			{children}
		</DateRangePickerContext.Provider>
	);
}

export { DateRangePickerContextProvider, DateRangePickerContext };
