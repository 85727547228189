import { Box, Stack, type SxProps, keyframes, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

type SmartLoaderProps = {
	isChart?: boolean;
	size?: { height?: string; width?: string };
	position?: { left: string; top: string };
	additionalText?: JSX.Element;
	sx?: SxProps;
};

export default function SmartLoader({
	isChart,
	size,
	position,
	additionalText,
	sx,
}: SmartLoaderProps) {
	const theme = useTheme();
	const PRIMARY_MAIN = sx ? "#00B4B7" : theme.palette.primary.main;
	const SECONDARY_MAIN = sx ? "#7BC622" : theme.palette.secondary.main;

	const [showAdditionalText, setShowAdditionalText] = useState(false);

	useEffect(() => {
		const showText = setTimeout(() => {
			setShowAdditionalText(true);
		}, 5000);
		return () => clearTimeout(showText);
	}, []);

	return (
		<>
			<Stack
				id="slamrtLoader Stack"
				direction="column"
				alignItems="center"
				sx={{
					...sx,
					...(!sx && {
						top: position ? position.top : "50%",
						left: position ? position.left : "50%",
						transform: "translate(-50%, -50%)",
					}),
					zIndex: "10",
					position: size ? "relative" : "absolute",
				}}
			>
				<Stack
					direction="row"
					sx={{
						height: size ? size.height : "40px",
						width: size ? size.width : "40px",
						gap: "3px",
						"& .bar": {
							width: "6px",
							background: "transparent",
							display: "inline-block",
							transformOrigin: "center center",
							borderRadius: "3px",
							...(typeof isChart !== "undefined" &&
								isChart === true && {
									transformOrigin: "bottom center",
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
								}),
							animation: `${keyframes`
                  0% {
                    transform: scaleY(0.1);
                    background: ;
                  }
                  50% {
                    transform: scaleY(1);
                    background: ${PRIMARY_MAIN};
                  }
                  100% {
                    transform: scaleY(0.1);
                    background: ${SECONDARY_MAIN};
                  }
                `} 1.2s linear infinite`,
						},
						...(typeof isChart !== "undefined" &&
							isChart === true && {
								padding: "2px 4px",
								borderLeft: "1px solid grey",
								borderBottom: "1px solid grey",
								height: "28px",
								width: "auto",
								opacity: 0.8,
							}),
					}}
				>
					<Box className="bar bar1" sx={{ animationDelay: "0s !important" }} />
					<Box
						className="bar bar2"
						sx={{ animationDelay: "0.2s !important" }}
					/>
					<Box
						className="bar bar3"
						sx={{ animationDelay: "0.4s !important" }}
					/>
					<Box
						className="bar bar4"
						sx={{ animationDelay: "0.6s !important" }}
					/>
				</Stack>
				{additionalText && showAdditionalText && <Box>{additionalText}</Box>}
			</Stack>
		</>
	);
}
