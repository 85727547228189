import { LicenseInfo } from "@mui/x-license-pro";
import { RouterProvider } from "react-router";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { AuthSyncDialog } from "./components/others/AuthSyncDialog";
import NotistackProvider from "./components/others/NotistackProvider";
import { ProgressBarStyle } from "./components/others/ProgressBar";
import RtlLayout from "./components/others/RtlLayout";
import ThemeColorPresets from "./components/others/ThemeColorPresets";
import ThemeLocalization from "./components/others/ThemeLocalization";
import { FeedbackProvider } from "./contexts/FeedbackContext";
import { router } from "./routes";
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function App() {
	// LicenseInfo.setLicenseKey(`${process.env.REACT_APP_LICENSE_KEY}`);
	LicenseInfo.setLicenseKey(
		"0971735fdb4b3fa6f6e48854d2298304T1JERVI6MzQ0NDEsRVhQSVJZPTE2NzEyMzU1NjcwMDAsS0VZVkVSU0lPTj0x",
	);
	return (
		<ThemeProvider>
			<ThemeColorPresets>
				<ThemeLocalization>
					<RtlLayout>
						<FeedbackProvider>
							<NotistackProvider>
								<MotionLazyContainer>
									<ProgressBarStyle />
									<AuthSyncDialog />
									{/* <Settings */}
									<RouterProvider router={router} />
								</MotionLazyContainer>
							</NotistackProvider>
						</FeedbackProvider>
					</RtlLayout>
				</ThemeLocalization>
			</ThemeColorPresets>
		</ThemeProvider>
	);
}
