import type { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
	return {
		MuiCard: {
			styleOverrides: {
				root: {
					position: "relative",
					boxShadow: theme.customShadows.card,
					borderRadius: Number(theme.shape.borderRadius) * 2,
					overflow: "visible",
					zIndex: 0, // Fix Safari overflow: hidden with border radius
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: { variant: "h6" },
				subheaderTypographyProps: { variant: "body2", marginTop: 0 },
			},
			styleOverrides: {
				root: {
					padding: theme.spacing(3, 3, 0),
					[theme.breakpoints.down("sm")]: {
						padding: theme.spacing(2, 2, 0),
					},
					lineHeight: 1,
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1, 3, 3, 3),
					[theme.breakpoints.down("sm")]: {
						padding: theme.spacing(1, 2, 2, 2),
					},
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
				},
			},
		},
	};
}
