import type { IconifyIcon } from "@iconify/react/dist/offline";
import Box from "@mui/material/Box";
import { forwardRef } from "react";
import type { oneOfItem } from "src/@types/metadataSchema";
import { Icon, type OfflineIcons } from "src/utils/icon";
import SmartXIcon from "../assets/icon_smartX";
// Get Iconify icon component
export const Iconify = (
	name: IconifyIcon | OfflineIcons,
	width?: number,
	className?: string,
	flip?: "horizontal" | "vertical" | "horizontal,vertical",
) => {
	return (
		<Icon icon={name} width={width || "24"} className={className} flip={flip} />
	);
};

type iconRefProps = {
	name: IconifyIcon | OfflineIcons;
	width?: number;
	className?: string;
	verticalAlign?: string;
	style?: object;
};
type FlagProps = {
	isoCode: string;
	style: {
		borderRadius: number;
		width: string;
		height: string;
		border: number;
	};
};

export const Flag = (props: FlagProps) => {
	const { isoCode, style } = props;
	if (isoCode !== undefined) {
		const FlagSvg = `${
			process.env.PUBLIC_URL
		}/icons/flags/4x3/${isoCode.toLowerCase()}.svg`;
		const altFlag = `${isoCode} logo`;
		return <img src={FlagSvg} alt={altFlag} style={style} />;
	} else {
		// console.log(isoCode, country, 'FlagSvg isoCode')
		return <></>;
	}
};

export function CountryOptions(country: oneOfItem) {
	return (
		<Box
			sx={{ borderRadius: 23, display: "grid", alignItems: "center", mr: 1 }}
		>
			<picture style={{ borderRadius: 21, height: 18, width: 22, border: 0 }}>
				<Flag
					isoCode={country.enum[0]}
					style={{ borderRadius: 5, width: "100%", height: "100%", border: 0 }}
				/>
			</picture>
		</Box>
	);
}

export const IconifyRef = forwardRef<HTMLDivElement, iconRefProps>(
	(props, ref) => {
		const { name, width, className, verticalAlign, ...otherProps } = props;
		return (
			<div {...otherProps} ref={ref}>
				<Icon
					icon={props.name}
					width={props.width || "24"}
					className={props.className}
					style={verticalAlign ? { verticalAlign: verticalAlign } : {}}
				/>
			</div>
		);
	},
);

// Get Icon in tree
export const getTreeIcon = (nodeType: string | undefined) => {
	switch (nodeType) {
		case "virtual":
			return Iconify("ph:lightning-duotone");
		case "physical":
			return <SmartXIcon />;
		// return Iconify("ph:hard-drive-duotone");
		case "site":
		case "node":
			return Iconify("ph:map-pin-line-duotone");
		case "site_group":
			return Iconify("ph:buildings-duotone");
		case "customer":
			return Iconify("ph:address-book-duotone");
		case "all":
			return <></>;
		default:
			return null;
	}
};

export const getLegendIcon = (
	name: string,
	series: string,
	withMargin?: boolean,
	legendBool?: boolean,
) => (
	<Box
		sx={{
			pr: withMargin ? 0 : 3,
			marginRight: withMargin ? 1 : 0,
			width: 12,
			height: 12,
			left: 0,
			opacity: legendBool !== false ? 1 : 0.7,
			bgcolor: series,
			mask: `url(${process.env.PUBLIC_URL}/icons/${name}.svg) no-repeat center / contain`,
			WebkitMask: `url(${process.env.PUBLIC_URL}/icons/${name}.svg) no-repeat center / contain`,
		}}
	/>
);

export const IconSVG = (color: string, color2: string) => {
	return (
		<Box
			sx={{
				width: 36,
				height: 36,
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
				<g fill={color2}>
					<path
						fill={color}
						d="M128 24a80 80 0 0 0-80 80c0 72 80 128 80 128s80-56 80-128a80 80 0 0 0-80-80Zm0 112a32 32 0 1 1 32-32a32 32 0 0 1-32 32Z"
						opacity="1"
					/>
					<path d="M128 64a40 40 0 1 0 40 40a40 40 0 0 0-40-40Zm0 64a24 24 0 1 1 24-24a24 24 0 0 1-24 24Zm0-112a88.1 88.1 0 0 0-88 88c0 31.4 14.51 64.68 42 96.25a254.19 254.19 0 0 0 41.45 38.3a8 8 0 0 0 9.18 0a254.19 254.19 0 0 0 41.37-38.3c27.45-31.57 42-64.85 42-96.25a88.1 88.1 0 0 0-88-88Zm0 206c-16.53-13-72-60.75-72-118a72 72 0 0 1 144 0c0 57.23-55.47 105-72 118Z" />
				</g>
			</svg>
		</Box>
	);
};
