import type { metadataSchema } from "src/@types/metadataSchema";
import parseSchemaDefs from "./parseSchemaDefs";

export function parseProperties(
	schema: metadataSchema,
	nestedObj: Record<string, any> | undefined,
	key: string,
) {
	if (nestedObj === undefined) return nestedObj;

	if (nestedObj.properties !== undefined) {
		const fullProperties = parseSchemaDefs(schema, nestedObj.properties);
		return fullProperties ? fullProperties[key] : undefined;
	} else return nestedObj[key];
}

export default function getPropertyFromSchema(
	schema: metadataSchema,
	propertyKey: string,
) {
	const subKeys = propertyKey.split(".");
	let parsedProperty: Record<string, any> | undefined = schema;
	subKeys.map((subKey) => {
		if (isNaN(Number.parseInt(subKey)))
			parsedProperty = parseProperties(schema, parsedProperty, subKey);
		else if (parsedProperty && parsedProperty.items !== undefined) {
			// array key => integer
			parsedProperty = parseProperties(schema, parsedProperty, "items");
		}
	});
	return parsedProperty;
}
