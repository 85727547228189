import { Badge, Box, Link, ListItemText } from "@mui/material";
import {
	NavLink as RouterLink,
	matchRoutes,
	useLocation,
} from "react-router-dom";
import type { RouterLinkProps } from "src/components/dashboard/MagicButton/layout/listComponents";
import useAlertsContext from "src/hooks/useAlertsContext";
import { getRoutes } from "src/routes";
import { t } from "ttag";
import { isExternalLink } from "..";
import Label from "../../../components/others/Label";
import Iconify from "../../others/Iconify";
import type { NavItemProps } from "../type";
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from "./style";

// ----------------------------------------------------------------------

export function NavItemRoot({
	item,
	isCollapse,
	open = false,
	active,
	onOpen,
}: NavItemProps) {
	const { id, title, path, icon, info, children, samePathIfInside } = item;
	const { pathname } = useLocation();
	const { unseenAlerts } = useAlertsContext();

	const linkProps: RouterLinkProps = {};
	if (samePathIfInside && pathname.startsWith(path)) {
	} else {
		linkProps.to = path;
		linkProps.component = RouterLink;
	}

	const valid = matchRoutes(getRoutes({ catchAll: false }), path) !== null;

	const renderContent = (
		<>
			{icon &&
				(title === t`Alerts` ? (
					<Badge
						anchorOrigin={{ horizontal: "left", vertical: "top" }}
						badgeContent={
							unseenAlerts > 0 ? (
								<Label color="error">
									{unseenAlerts > 99 ? "99+" : unseenAlerts}
								</Label>
							) : (
								0
							)
						}
					>
						<ListItemIconStyle>{icon}</ListItemIconStyle>
					</Badge>
				) : (
					<ListItemIconStyle>{icon}</ListItemIconStyle>
				))}
			<ListItemTextStyle
				disableTypography
				primary={title}
				isCollapse={isCollapse}
			/>
			{!isCollapse && (
				<>
					{info && info}
					{children && <ArrowIcon open={open} />}
				</>
			)}
		</>
	);

	if (children) {
		return (
			<ListItemStyle onClick={onOpen} activeRoot={active}>
				{renderContent}
			</ListItemStyle>
		);
	}

	return isExternalLink(path) ? (
		<ListItemStyle
			id={id}
			component={Link}
			href={path}
			target="_blank"
			rel="noopener"
		>
			{renderContent}
		</ListItemStyle>
	) : (
		<ListItemStyle
			id={id}
			disabled={!valid}
			{...(linkProps as any)}
			activeRoot={active}
		>
			{renderContent}
		</ListItemStyle>
	);
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, "isCollapse">;

export function NavItemSub({
	item,
	open = false,
	active = false,
	onOpen,
}: NavItemSubProps) {
	const { title, path, info, children } = item;

	const renderContent = (
		<>
			<DotIcon active={active} />
			<ListItemText disableTypography primary={title} />
			{info && info}
			{children && <ArrowIcon open={open} />}
		</>
	);

	if (children) {
		return (
			<ListItemStyle onClick={onOpen} activeSub={active} subItem>
				{renderContent}
			</ListItemStyle>
		);
	}

	return isExternalLink(path) ? (
		<ListItemStyle
			component={Link}
			href={path}
			target="_blank"
			rel="noopener"
			subItem
		>
			{renderContent}
		</ListItemStyle>
	) : (
		<ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
			{renderContent}
		</ListItemStyle>
	);
}

// ----------------------------------------------------------------------

type DotIconProps = {
	active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
	return (
		<ListItemIconStyle>
			<Box
				component="span"
				sx={{
					width: 4,
					height: 4,
					borderRadius: "50%",
					bgcolor: "text.disabled",
					transition: (theme) =>
						theme.transitions.create("transform", {
							duration: theme.transitions.duration.shorter,
						}),
					...(active && {
						transform: "scale(2)",
						bgcolor: "primary.main",
					}),
				}}
			/>
		</ListItemIconStyle>
	);
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
	open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
	return (
		<Iconify
			icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
			sx={{ width: 16, height: 16, ml: 1 }}
		/>
	);
}
