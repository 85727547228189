import { useContext } from "react";
import { FeedbackContext } from "../contexts/FeedbackContext";

// ----------------------------------------------------------------------

const useFeedbackContext = () => {
	const context = useContext(FeedbackContext);

	if (!context)
		throw new Error("Feedback context must be use inside FeedbackProvider");

	return context;
};

export default useFeedbackContext;
