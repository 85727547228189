import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
	Box,
	Button,
	Container,
	IconButton,
	Stack,
	Toolbar,
} from "@mui/material";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { addHours, getTime } from "date-fns";
import { useSnackbar } from "notistack";
import { ReactChildren, ReactNode, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { brandSetting, themeBranding } from "src/config";
import DashboardFooter from "src/layouts/dashboard/footer/Footer";
import Login from "src/pages/auth/Login";
import { getVersionFromAPi } from "src/services/service";
import { CloseIcon } from "src/theme/overrides/CustomIcons";
import { checkInterval, versionStorage } from "src/utils/checkVersion";
import enqueueSnackbarCustom from "src/utils/enqueueSnackbarCustom";
import { t } from "ttag";
// components
import Logo from "../components/others/Logo";
import LanguagePopover from "./dashboard/header/LanguagePopover";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	[theme.breakpoints.up("md")]: {},
	[theme.breakpoints.up("sm")]: {},
	minHeight: "100vh",
	backgroundSize: "cover",
	backgroundRepeat: "no-repeat",
}));

const ContentStyle = styled("div")(({ theme }) => ({
	minHeight: "380px",
	[theme.breakpoints.up("sm")]: {
		minWidth: "500px",
	},
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(5, 2),
	background: theme.palette.background.default,
	alignSelf: "center",
	borderRadius: "16px",
}));

// ----------------------------------------------------------------------

export default function AuthLayout(props: any) {
	const [checkIfUpateAvailable, setCheckIfUpateAvailable] = useState(true);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		versionStorage(setCheckIfUpateAvailable, checkIfUpateAvailable);
		checkInterval(setCheckIfUpateAvailable, checkIfUpateAvailable);
	}, []);

	const action = (snackbarId: any) => (
		<>
			<Button
				onClick={() => {
					window.location.reload();
				}}
			>
				{t`Reload now`}
			</Button>
			<IconButton
				aria-label="close"
				size="small"
				onClick={() => {
					closeSnackbar(snackbarId);
				}}
			>
				<CloseRoundedIcon />
			</IconButton>
		</>
	);

	useEffect(() => {
		getVersionFromAPi().then((data: string) => {
			// console.log(process.env.REACT_APP_GIT_SHA, data, "version");
			const version = data.trim();
			// console.log(version, process.env.REACT_APP_GIT_SHA, 'version not')
			if (version && version !== process.env.REACT_APP_GIT_SHA) {
				enqueueSnackbar(t`A new version is available. `, {
					variant: "info",
					action,
					autoHideDuration: null,
				});
			}
		});
	}, [checkIfUpateAvailable]);

	return (
		<RootStyle
			sx={{ backgroundImage: `url(${brandSetting.assetsUrl}/background.png)` }}
		>
			<Box sx={{ mt: "auto" }}>
				<Stack
					direction="row"
					justifyContent={"center"}
					sx={{
						paddingBottom: "20px",
						flexGrow: 1,
						"& a": { flexGrow: 1, justifyContent: "center" },
					}}
				>
					<Logo
						sx={{ height: brandSetting.logoHeight }}
						color={themeBranding === "smartImpulse" ? "white" : undefined}
					/>
				</Stack>
				<ContentStyle className="test-content">
					<Container
						maxWidth="sm"
						sx={{ justifyContent: "center", alignItems: "center" }}
					>
						{props.component === undefined ? <Outlet /> : props.component}
					</Container>
				</ContentStyle>
			</Box>
			<Box sx={{ mt: "auto" }}>
				<DashboardFooter />
			</Box>
		</RootStyle>
	);
}
