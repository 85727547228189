import type { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Link() {
	return {
		MuiLink: {
			defaultProps: {
				underline: "hover",
			},
		},
	};
}
