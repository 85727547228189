import type {
	installationMetadata,
	meteringplanBenchmark,
	meteringplanObject,
	meteringplanVirtualObject,
} from "./installation";
import type { otherKpi } from "./metadataSchema";

export type day = {
	openingTime?: string;
	closingTime?: string;
};

export const dayKeys = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
] as const;
export type dayKey = typeof dayKeys;
export type WeekScheduleKey = dayKey[number];
export type WeekSchedule = { [key in WeekScheduleKey]?: day };

export type siteObject = {
	uuid: string;
	label: string;
	type: "site";
	// date_start:string,
	// date_end:string,
	// address:string,
	// timezone:string,
	// language:string,
	// coordinates:string,
	// country:string,
	// mainActivity:string,
	// buildingSurface:number,
	// constructionYear: string | number | null,
	// heating: [],
	// cooling: [],
	// waterheating: [],
	// currency: string,
	// internal_reference: any | null,
};

export type customerObject = {
	uuid: string;
	label: string;
	type: "node";
	children: siteObject[];
};

export type metaUpdateParam =
	| {
			uuid: string;
			type: "site";
			metadata: Partial<siteMetadataObject>[];
	  }
	| {
			uuid: string;
			type: "installation";
			metadata: Partial<installationMetadata>[];
	  };

export type siteMetadataObject = {
	// averagekWhCost?: number,
	occupant?: string;
	title?: string;
	address?: string;
	description?: string;
	sectorDescription?: string;
	buildingSurface?: number;
	internal_reference?: string;
	heating?: string[];
	cooling?: string[];
	waterheating?: string[];
	heatingDescription?: string;
	waterheatingDescription?: string;
	ventilationDescription?: string;
	// KPINumber?: number,
	otherKPI?: otherKpi[];
	coolingDescription?: string;
	heatingSchedule?: {
		coolingPeriod?: {
			start?: string;
			end?: string;
		};
		heatingPeriod?: {
			start?: string;
			end?: string;
		};
	};
	subscribedPower?: number | string;
	mainActivity?: string;
	currency?: string;
	constructionYear?: string;
	employeeHours?: WeekSchedule;
	openingSchedule?: WeekSchedule;
	previousYearConsumption?: number;
	previousYearCost?: number;
	// averageCO2Impact?: number,
	containsBMS?: boolean;
	sharedNotes?: string;
	referencePeriod?: {
		startDate?: Date | string;
		endDate?: Date | string;
	};
	attachments?: {
		siteDocuments?: string[];
	};
};

export type locationObject = {
	country?: string;
	address?: string;
	coordinates: [number, number] | [];
};

// returned by getSite

// Remember to update the JSON schema with:
// ./node_modules/.bin/ts-json-schema-generator --path src/@types/SiteContent --type 'ApiSiteContent' --tsconfig tsconfig.json > src/schemas/SiteContent.json
export type ApiSiteContent = {
	uuid: string;
	label: string;
	timezone: string;
	location: locationObject;
	metadata: siteMetadataObject;
	metering_plan: meteringplanObject[];
	virtual: meteringplanVirtualObject[];
	routers: string[];
	default_installation?: meteringplanObject["data_id"];
	language: string;
};

export type SiteContent = ApiSiteContent & {
	unique_id: string;
	has_alerts_access: boolean;
	can_view_metadata: boolean;
	can_edit_metadata: boolean;
};

export type ApiMultiSiteContent = ApiSiteContent[];

export type MultiSiteContent = {
	label: string;
	uuid: string;
	has_alerts_access: boolean;
	installations: meteringplanBenchmark[];
};
