import { Box, type BoxProps } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import Image from "src/components/others/Image";

// ----------------------------------------------------------------------

export default function OnlineReviewsIllustration({ ...other }: BoxProps) {
	const theme = useTheme();
	const PRIMARY = theme.palette.secondary.main; // to replace "#0CBC8B"
	const SECONDARY = theme.palette.primary.main; // to replace "#367CFF"

	return (
		<Box {...other}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				viewBox="0 0 779.067 522.424"
			>
				<g id="Layer_8" data-name="Layer 8">
					<g>
						<rect x="459.893" width="155.191" height="201.999" fill="#f4f4f4" />
						<rect
							x="615.083"
							width="5.034"
							height="201.999"
							transform="translate(1235.201 201.999) rotate(180)"
							fill="#f4f4f4"
						/>
						<rect
							x="615.083"
							width="5.034"
							height="201.999"
							transform="translate(1235.201 201.999) rotate(180)"
							fill="#292a2e"
							opacity="0.05"
						/>
						<rect
							x="488.163"
							y="36.797"
							width="98.651"
							height="128.406"
							fill="#292a2e"
							opacity="0.05"
						/>
						<rect
							x="581.672"
							y="36.797"
							width="5.141"
							height="128.406"
							fill="#292a2e"
							opacity="0.03"
						/>
						<rect
							x="615.083"
							y="251.674"
							width="126.921"
							height="128.086"
							fill="#f4f4f4"
						/>
						<rect
							x="638.204"
							y="275.007"
							width="80.68"
							height="81.421"
							fill="#292a2e"
							opacity="0.05"
						/>
						<rect
							x="742.004"
							y="251.674"
							width="5.034"
							height="128.086"
							transform="translate(1489.043 631.434) rotate(180)"
							fill="#f4f4f4"
						/>
						<rect
							x="742.004"
							y="251.674"
							width="5.034"
							height="128.086"
							transform="translate(1489.043 631.434) rotate(180)"
							fill="#292a2e"
							opacity="0.05"
						/>
						<rect
							x="713.742"
							y="275.007"
							width="5.141"
							height="81.421"
							fill="#292a2e"
							opacity="0.03"
						/>
					</g>
					<g>
						<rect
							id="c_3_c"
							x="18.026"
							y="18.753"
							width="325.24"
							height="325.24"
							fill="#f4f4f4"
						/>
						<rect
							id="c_3_c-2"
							data-name="c_3_c"
							x="14.299"
							y="18.753"
							width="3.728"
							height="325.24"
							fill="#f4f4f4"
						/>
						<rect
							id="c_3_c-3"
							data-name="c_3_c"
							x="14.299"
							y="18.753"
							width="3.728"
							height="325.24"
							fill="#292a2e"
							opacity="0.05"
						/>
						<polygon
							id="c_3_c-4"
							data-name="c_3_c"
							points="96.817 242.87 96.817 157.777 166.521 157.777 166.521 207.214 274.493 207.214 274.493 294.749 320.383 294.749 320.383 343.993 274.493 343.993 265.653 343.993 33.672 343.993 33.672 242.87 96.817 242.87"
							fill="#292a2e"
							opacity="0.05"
						/>
						<g id="c_3_wh">
							<rect
								id="c_3_wh-2"
								data-name="c_3_wh"
								x="106.815"
								y="167.016"
								width="50.759"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-3"
								data-name="c_3_wh"
								x="106.815"
								y="186.162"
								width="50.759"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-4"
								data-name="c_3_wh"
								x="106.815"
								y="205.307"
								width="50.759"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
						</g>
						<g id="c_3_wh-5" data-name="c_3_wh">
							<rect
								id="c_3_wh-6"
								data-name="c_3_wh"
								x="42.673"
								y="251.839"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-7"
								data-name="c_3_wh"
								x="42.673"
								y="270.984"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-8"
								data-name="c_3_wh"
								x="42.673"
								y="290.13"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-9"
								data-name="c_3_wh"
								x="59.981"
								y="251.839"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-10"
								data-name="c_3_wh"
								x="59.981"
								y="270.984"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-11"
								data-name="c_3_wh"
								x="59.981"
								y="290.13"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-12"
								data-name="c_3_wh"
								x="76.821"
								y="251.839"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-13"
								data-name="c_3_wh"
								x="76.821"
								y="270.984"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
							<rect
								id="c_3_wh-14"
								data-name="c_3_wh"
								x="76.821"
								y="290.13"
								width="9.356"
								height="9.239"
								fill="#fffffa"
								opacity="0.34"
							/>
						</g>
						<polygon
							points="18.026 179.549 83.47 179.549 83.47 18.753 88.882 18.753 88.882 179.549 265.653 179.549 265.653 18.753 271.065 18.753 271.065 179.549 343.267 179.549 343.267 184.961 271.065 184.961 271.065 343.993 265.653 343.993 265.653 184.961 88.882 184.961 88.882 343.993 83.47 343.993 83.47 184.961 18.026 184.961 18.026 179.549"
							fill="#fff"
						/>
						<rect
							id="c_3_c-5"
							data-name="c_3_c"
							x="5.07"
							y="343.601"
							width="351.153"
							height="10.816"
							fill="#f4f4f4"
						/>
						<rect
							id="c_3_c-6"
							data-name="c_3_c"
							x="5.07"
							y="343.601"
							width="351.153"
							height="10.816"
							fill="#292a2e"
							opacity="0.1"
						/>
						<rect
							id="c_3_c-7"
							data-name="c_3_c"
							y="343.601"
							width="5.07"
							height="10.816"
							fill="#292a2e"
							opacity="0.1"
						/>
						<rect
							id="c_3_c-8"
							data-name="c_3_c"
							y="343.601"
							width="5.07"
							height="10.816"
							fill="#292a2e"
							opacity="0.1"
						/>
					</g>
					<g>
						<g>
							<path
								d="M237.343,179.585s14.089-12.2,16.039-38.225c1.509-20.154-16.039-5.987-16.039-5.987s.945-14.522-9.442-12.392-15.252,17.646-15.252,17.646"
								fill="#0c0c0c"
							/>
							<path
								d="M173.805,128.3s-51.663,9.065-28.127,78.317h14.014"
								fill="#0c0c0c"
							/>
							<g>
								<path
									id="sk"
									d="M231.989,278.613c-.23-.03-2.774,42.19-51.822,38.509-33.955-2.544-46.062-38.569-46.84-38.569,3.661-.778,9.826-1.909,11.452-2.777,5.346-2.873,11.962-5.863,13.685-16.246.994-5.984-.691-36.6-.173-42.656l52.121,11.634c-.728,12.788-5.825,42.434,12.04,47.681C225.425,277.057,228.627,277.855,231.989,278.613Z"
									fill="#E98862"
								/>
								<path
									id="sh"
									d="M211.143,264.073a37.781,37.781,0,0,1-1.568-8.089,86.251,86.251,0,0,1,.341-18.815,57.155,57.155,0,0,1-28.807-12.621c-.967,3.574-6.29,19.192-4.816,22.589C179.078,253.558,192.169,264.073,211.143,264.073Z"
									fill="#0c0c0c"
									opacity="1"
								/>
								<path
									d="M201.892,253.872c-20.64-.465-46.064-11.782-48.284-39.608l-2-25.108c-2.22-27.825,10.6-51.549,36.794-53.639h0c26.2-2.091,47.582,18.9,49.8,46.729l.887,11.116a84.383,84.383,0,0,1-.068,11.474c-.675,10.1-1.74,25.454-7.843,36.2C225.415,251.186,215.078,254.17,201.892,253.872Z"
									fill="#E98862"
								/>
								<circle cx="230.429" cy="196.077" r="3.237" fill="#0c0c0c" />
								<circle cx="200.705" cy="196.28" r="3.237" fill="#0c0c0c" />
								<path
									d="M155.911,222.611c-7.962.636-15.1-7.367-15.731-15.329s5.469-12.868,13.431-13.5l4.528,5.007,4.618,23.279Z"
									fill="#E98862"
								/>
							</g>
							<g>
								<g>
									<path
										d="M162.981,209.275s.05-39.587,11.836-42.821c7.85-2.154,27.906,7.841,45.553,3.234,7.721-2.016,15.275-.578,16.278,3.066.4,1.443,3.86-19.9-9.456-35.034-10.6-12.048-42.751-13.5-56.989-8.358-39.424,14.225-18.119,66.263-18.119,66.263l2.7,13.466Z"
										fill="#0c0c0c"
									/>
									<g opacity="1">
										<path
											d="M214.42,218.825l.051,0c2.566-.2,6.849-.816,6.7-2.736-.125-1.591-5.8-18.425-6.451-20.339a.656.656,0,0,0-1.242.42c2.521,7.455,6.3,18.939,6.385,20.02-.217.473-2.838,1.122-5.5,1.328a.655.655,0,0,0,.049,1.309Z"
											fill="#0c0c0c"
										/>
									</g>
									<path
										d="M220.16,179.345a15.431,15.431,0,0,1,8.149-.566,13.607,13.607,0,0,1,3.827,1.364,16.03,16.03,0,0,1,3.285,2.316.833.833,0,0,1-1.049,1.293l-.053-.04a14.219,14.219,0,0,0-3.012-1.887,11.644,11.644,0,0,0-3.358-.994,13.178,13.178,0,0,0-6.8.809l-.082.034a1.25,1.25,0,1,1-.975-2.3Z"
										fill="#0c0c0c"
									/>
									<path
										d="M201.159,181.91a13.181,13.181,0,0,0-6.8-.807,11.635,11.635,0,0,0-3.358.994,14.194,14.194,0,0,0-3.012,1.887l-.051.039a.833.833,0,0,1-1.051-1.291,15.981,15.981,0,0,1,3.285-2.317A13.6,13.6,0,0,1,194,179.05a15.428,15.428,0,0,1,8.148.566,1.251,1.251,0,0,1-.846,2.354l-.061-.023Z"
										fill="#0c0c0c"
									/>
								</g>
								<path
									d="M217.563,225.344a33.805,33.805,0,0,1-21.428-4.69,1.369,1.369,0,0,0-2.06,1.379c.715,4.4,3.3,12.429,12.63,13.013,6.026.377,10.144-4.437,12.184-7.6A1.369,1.369,0,0,0,217.563,225.344Z"
									fill="#fff"
								/>
							</g>
							<ellipse
								cx="210.092"
								cy="136.806"
								rx="10.935"
								ry="16.095"
								transform="translate(-29.096 61.683) rotate(-15.635)"
								fill="#0c0c0c"
							/>
						</g>
						<path
							id="b_1_b"
							d="M179.193,306.594c-34.092-1.607-35.527-30.544-35.527-30.544s-16.892,1.092-28.121,2.611c-18.384,2.487-30.512,8.721-40.163,18.373-8.979,8.978-14.445,18.692-8.66,80.71,4.188,44.906,1.24,143.68,1.24,143.68h205.75s12.339-129.465,14.058-149.68c3.283-38.589,5.948-72.206-2.064-80.218-16.471-16.471-48.8-15.476-62.958-15.474C222.518,276.052,225.621,308.782,179.193,306.594Z"
							fill={SECONDARY}
						/>
						<path
							id="b_4_b"
							d="M479.471,520.092l-148.6.734a37.5,37.5,0,0,1-32.357-24.1l-73.292-162.6a37.5,37.5,0,0,1,21.755-48.365c19.366-7.349,38.484-2.408,45.832,16.954l67.335,145.031,122.336,1.985S480.391,520.158,479.471,520.092Z"
							fill={SECONDARY}
						/>
						<path
							d="M284.861,380.766a.5.5,0,0,1,1,.063l-4.183,66.828a.5.5,0,0,1-.46.467.558.558,0,0,1-.071,0,.5.5,0,0,1-.468-.531"
							fill="#0c0c0c"
						/>
						<g id="b_4_ha">
							<path
								d="M310.2,452.432l-30.135-10.563,4.326-.3a5.525,5.525,0,0,0,5.034-5.617,5.323,5.323,0,0,0-5.894-5l-53.313,5.666,1.384,17.133a26.8,26.8,0,0,0,18.7,23.414l23.088,7.192a5.45,5.45,0,0,0,6.446-2.452,5.323,5.323,0,0,0-.836-6.252.479.479,0,0,0,.159-.2A.493.493,0,0,0,279.1,475l4.256,1.334a5.524,5.524,0,0,0,6.864-3.125,5.321,5.321,0,0,0-2.292-6.45.452.452,0,0,0,.13-.105l1.75.548a5.451,5.451,0,0,0,6.446-2.452,5.327,5.327,0,0,0-1.932-7.13.506.506,0,0,0,.017-.108l12.549,4.4a5.02,5.02,0,1,0,3.321-9.474Z"
								fill="#E98862"
							/>
							<path
								id="b_4_ha_sh"
								d="M293.84,458a.486.486,0,0,1-.171-.03l-12.139-4.414a.5.5,0,1,1,.341-.939l12.14,4.414a.5.5,0,0,1-.171.969Z"
								fill="#0a0a0a"
								opacity="0.2"
							/>
							<path
								id="b_4_ha_sh-2"
								data-name="b_4_ha_sh"
								d="M287.7,466.824a.494.494,0,0,1-.167-.029l-9.568-3.4a.5.5,0,0,1,.334-.942l9.568,3.395a.5.5,0,0,1-.167.971Z"
								fill="#0a0a0a"
								opacity="0.2"
							/>
							<path
								id="b_4_ha_sh-3"
								data-name="b_4_ha_sh"
								d="M278.694,475.779a.514.514,0,0,1-.179-.033l-4.481-1.709a.5.5,0,0,1,.356-.934l4.482,1.709a.5.5,0,0,1-.178.967Z"
								fill="#0a0a0a"
								opacity="0.2"
							/>
						</g>
						<path
							id="b_4_b-2"
							data-name="b_4_b"
							d="M252.557,489.729,65.719,520.7a37.5,37.5,0,0,1-41.038-43.5L64.522,317.6a37.5,37.5,0,1,1,73.961,12.443L107.928,441.278l135.763-18.546Z"
							fill={SECONDARY}
						/>
						<g id="b_4_st">
							<path
								d="M83.573,444.769a.5.5,0,0,1-.073-.994l23.288-3.487a.494.494,0,0,1,.569.42.5.5,0,0,1-.421.568l-23.288,3.487A.474.474,0,0,1,83.573,444.769Z"
								fill="#0c0c0c"
							/>
						</g>
						<g id="b_4_st-2" data-name="b_4_st">
							<path
								d="M70.92,521.424a.5.5,0,0,1-.064-1L251.51,489.35l-8.757-66L107.928,441.278a.507.507,0,0,1-.425-.148.5.5,0,0,1-.134-.431l14.589-62.941a.494.494,0,0,1,.576-.411.5.5,0,0,1,.41.577L108.467,440.2,243.128,422.29a.5.5,0,0,1,.563.442l8.866,67a.5.5,0,0,1-.432.55L70.985,521.42A.482.482,0,0,1,70.92,521.424Z"
								fill="#0c0c0c"
							/>
						</g>
					</g>
					<g>
						<path
							d="M396.161,503.145l48.924-150.389a13.164,13.164,0,0,1,12.518-9.092H656.921A13.163,13.163,0,0,1,669.438,360.9L620.515,511.289A13.163,13.163,0,0,1,608,520.38H408.679A13.163,13.163,0,0,1,396.161,503.145Z"
							fill="#292a2e"
						/>
						<rect
							x="296.628"
							y="508.186"
							width="145.462"
							height="12.194"
							rx="6.02"
							fill="#292a2e"
						/>
						<circle cx="532.8" cy="439.359" r="20.905" fill="#fff" />
					</g>
					<g>
						<path
							d="M760.178,287.874H540.111L511.9,309.008V199.767a18.389,18.389,0,0,1,18.389-18.389H760.178a18.389,18.389,0,0,1,18.389,18.389v69.719A18.389,18.389,0,0,1,760.178,287.874Z"
							fill="#fff"
						/>
						<path
							d="M511.9,309.508a.508.508,0,0,1-.224-.053.5.5,0,0,1-.276-.447V199.767a18.911,18.911,0,0,1,18.889-18.889H760.178a18.911,18.911,0,0,1,18.889,18.889v69.718a18.911,18.911,0,0,1-18.889,18.889h-219.9L512.2,309.408A.5.5,0,0,1,511.9,309.508Zm18.389-127.63A17.909,17.909,0,0,0,512.4,199.767V308.009l27.417-20.535a.5.5,0,0,1,.3-.1H760.178a17.909,17.909,0,0,0,17.889-17.889V199.767a17.909,17.909,0,0,0-17.889-17.889Z"
							fill="#292a2e"
						/>
					</g>
					<path
						d="M759.479,233.406H615.355a.707.707,0,0,1,0-1.414H759.479a.707.707,0,0,1,0,1.414Z"
						fill={SECONDARY}
					/>
					<path
						d="M759.479,251.08H615.355a.707.707,0,1,1,0-1.414H759.479a.707.707,0,1,1,0,1.414Z"
						fill={SECONDARY}
					/>
					<path
						d="M693.734,268.753H615.355a.707.707,0,0,1,0-1.414h78.379a.707.707,0,1,1,0,1.414Z"
						fill={SECONDARY}
					/>
					<circle cx="563.689" cy="237.714" r="35.486" fill="#292a2e" />
					<g>
						<polygon
							points="625.54 199.278 628.43 206.754 636.433 207.191 630.215 212.25 632.272 219.997 625.54 215.646 618.808 219.997 620.865 212.25 614.648 207.191 622.651 206.754 625.54 199.278"
							fill={SECONDARY}
						/>
						<polygon
							points="654.15 199.278 657.04 206.754 665.043 207.191 658.825 212.25 660.882 219.997 654.15 215.646 647.418 219.997 649.475 212.25 643.258 207.191 651.261 206.754 654.15 199.278"
							fill={SECONDARY}
						/>
						<polygon
							points="682.76 199.278 685.65 206.754 693.653 207.191 687.435 212.25 689.492 219.997 682.76 215.646 676.028 219.997 678.085 212.25 671.868 207.191 679.871 206.754 682.76 199.278"
							fill={SECONDARY}
						/>
						<polygon
							points="711.37 199.278 714.26 206.754 722.263 207.191 716.046 212.25 718.102 219.997 711.37 215.646 704.638 219.997 706.695 212.25 700.478 207.191 708.481 206.754 711.37 199.278"
							fill={SECONDARY}
						/>
						<polygon
							points="739.98 199.278 742.87 206.754 750.873 207.191 744.656 212.25 746.712 219.997 739.98 215.646 733.248 219.997 735.305 212.25 729.088 207.191 737.091 206.754 739.98 199.278"
							fill={SECONDARY}
						/>
					</g>
					<g>
						<rect
							x="369.359"
							y="122.122"
							width="99.682"
							height="78.376"
							rx="10.111"
							fill="#292a2e"
						/>
						<g>
							<path
								d="M444.306,167.039h-.359a4.959,4.959,0,0,1,3.928,4.885,5.032,5.032,0,0,1-5.08,4.925h-.831a4.958,4.958,0,0,1,3.347,4.95,5.039,5.039,0,0,1-5.08,4.7H438.7l-30.6-.027.044-39.132c3-4.618,8.276-12.421,8.276-12.421a5.773,5.773,0,0,1,9.769,6.156l-3.914,6.214,4.615.007,3.281,0,15.16.017a5.036,5.036,0,0,1,5.069,4.779,4.958,4.958,0,0,1-4.943,5.138,4.963,4.963,0,0,1,3.931,4.884A5.035,5.035,0,0,1,444.306,167.039Z"
								fill="#fff"
							/>
							<polygon
								points="408.141 147.152 408.141 147.336 408.111 186.468 408.111 186.502 391.765 186.475 391.796 147.124 408.141 147.152"
								fill={SECONDARY}
							/>
							<circle
								cx="399.953"
								cy="154.081"
								r="3.377"
								fill="#fff"
								opacity="0.65"
							/>
						</g>
					</g>
					<g>
						<circle cx="563.693" cy="99" r="41.858" fill={SECONDARY} />
						<path
							d="M585.107,98.928a21.415,21.415,0,1,1-42.829,0Z"
							fill="#fff"
						/>
						<g>
							<circle cx="548.577" cy="82.201" r="3.647" fill="#0c0c0c" />
							<circle cx="578.808" cy="82.201" r="3.647" fill="#0c0c0c" />
						</g>
					</g>
					<circle cx="563.689" cy="224.479" r="14.842" fill="#fff" />
					<path
						d="M591.848,259.318a35.5,35.5,0,0,1-56.31,0,37.289,37.289,0,0,1,56.31,0Z"
						fill="#fff"
					/>
					<path
						d="M563.689,273.7a35.986,35.986,0,1,1,35.985-35.986A36.026,36.026,0,0,1,563.689,273.7Zm0-70.972a34.986,34.986,0,1,0,34.985,34.986A35.025,35.025,0,0,0,563.689,202.728Z"
						fill="#292a2e"
					/>
					<g>
						<path
							d="M646.335,505.274a.755.755,0,0,1-.679-.424l-19.908-40.832a.755.755,0,0,1,1.357-.662l19.908,40.832a.755.755,0,0,1-.348,1.01A.748.748,0,0,1,646.335,505.274Z"
							fill="#292a2e"
						/>
						<path
							d="M679.987,502.9a.754.754,0,0,1-.729-.563L656.221,414.39a.755.755,0,1,1,1.46-.382l23.037,87.95a.753.753,0,0,1-.731.946Z"
							fill="#292a2e"
						/>
						<path
							d="M723.932,506.8a.747.747,0,0,1-.294-.06.755.755,0,0,1-.4-.99l23.814-56.084a.755.755,0,0,1,1.389.59l-23.814,56.084A.755.755,0,0,1,723.932,506.8Z"
							fill="#292a2e"
						/>
						<path
							d="M680.842,519.792a.725.725,0,0,1-.184-.023.754.754,0,0,1-.549-.915l27.671-110.7a.755.755,0,1,1,1.465.367l-27.672,110.7A.755.755,0,0,1,680.842,519.792Z"
							fill="#292a2e"
						/>
						<ellipse
							cx="715.192"
							cy="419.162"
							rx="27.171"
							ry="31.646"
							transform="translate(-110.709 347.859) rotate(-25.44)"
							fill={PRIMARY}
						/>
						<ellipse
							cx="715.192"
							cy="419.162"
							rx="27.171"
							ry="31.646"
							transform="translate(-110.709 347.859) rotate(-25.44)"
							fill="#fff"
							opacity="0.4"
						/>
						<ellipse
							cx="751.981"
							cy="461.33"
							rx="15.905"
							ry="18.98"
							transform="translate(-117.899 314.327) rotate(-21.937)"
							fill={PRIMARY}
						/>
						<ellipse
							cx="649.373"
							cy="427.903"
							rx="28.107"
							ry="22.901"
							transform="matrix(0.29, -0.957, 0.957, 0.29, 51.41, 925.121)"
							fill={PRIMARY}
						/>
						<ellipse
							cx="623.283"
							cy="469.193"
							rx="17.458"
							ry="12.404"
							transform="translate(57.578 1006.411) rotate(-80.492)"
							fill={PRIMARY}
						/>
						<rect
							x="628.599"
							y="491.538"
							width="117.544"
							height="29.292"
							fill="#292a2e"
						/>
						<rect
							x="628.599"
							y="491.538"
							width="117.544"
							height="29.292"
							fill="#fff"
							opacity="0.3"
						/>
						<ellipse
							cx="708.512"
							cy="476.891"
							rx="19.492"
							ry="23.154"
							transform="matrix(0.928, -0.374, 0.374, 0.928, -126.859, 299.214)"
							fill={PRIMARY}
						/>
						<circle cx="664.342" cy="477.425" r="18.008" fill={PRIMARY} />
						<ellipse
							cx="751.981"
							cy="461.33"
							rx="15.905"
							ry="18.98"
							transform="translate(-117.899 314.327) rotate(-21.937)"
							fill="#fff"
							opacity="0.68"
						/>
						<ellipse
							cx="649.373"
							cy="427.903"
							rx="28.107"
							ry="22.901"
							transform="matrix(0.29, -0.957, 0.957, 0.29, 51.41, 925.121)"
							fill="#fff"
							opacity="0.68"
						/>
						<ellipse
							cx="623.283"
							cy="469.193"
							rx="17.458"
							ry="12.404"
							transform="translate(57.578 1006.411) rotate(-80.492)"
							fill="#fff"
							opacity="0.68"
						/>
						<ellipse
							cx="708.512"
							cy="476.891"
							rx="19.492"
							ry="23.154"
							transform="matrix(0.928, -0.374, 0.374, 0.928, -126.859, 299.214)"
							fill="#fff"
							opacity="0.68"
						/>
						<circle
							cx="664.342"
							cy="477.425"
							r="18.008"
							fill="#fff"
							opacity="0.4"
						/>
						<path
							d="M722.184,462.264c-13.668,3.819-20.861-7.351-20.861-7.351l.008-.015C708.622,452.724,716.705,455.781,722.184,462.264Z"
							fill="#292a2e"
						/>
						<path
							d="M635.848,466.838A29.986,29.986,0,0,1,620.142,453a9.626,9.626,0,0,1,6.023-1.019C631.856,452.928,635.72,459.2,635.848,466.838Z"
							fill="#292a2e"
						/>
						<path
							d="M667.556,459.706a34.929,34.929,0,0,1-18.786,8.672,18.03,18.03,0,0,1,18.786-8.672Z"
							fill="#292a2e"
						/>
					</g>
					<path
						d="M767.616,522.424H1.487a1,1,0,1,1,0-2H767.616a1,1,0,0,1,0,2Z"
						fill="#292a2e"
					/>
				</g>
			</svg>
		</Box>
	);
}
