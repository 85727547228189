import type { IconifyIcon } from "@iconify/react/dist/offline";
import { Box, type BoxProps, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ReactNode } from "react";
import MatchNotFoundIllustration from "src/assets/illustrations/MatchNotFound";
import { Iconify } from "src/utils/getIcon";
import type { OfflineIcons } from "src/utils/icon";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
	height: "100%",
	width: "100%",
	display: "flex",
	textAlign: "center",
	alignItems: "center",
	flexDirection: "column",
	justifyContent: "center",
	padding: theme.spacing(2, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
	title: string;
	svg?: any;
	description?: string;
	children?: ReactNode;
	icon?: OfflineIcons | IconifyIcon;
}

export default function EmptyContent({
	title,
	description,
	svg,
	icon,
	children,
	...other
}: Props) {
	return (
		<RootStyle {...other}>
			{
				typeof icon !== "undefined" && icon ? (
					<Stack sx={{ m: 4, color: "grey.500" }}>{Iconify(icon, 140)}</Stack>
				) : (
					<Box
						sx={{
							height: "200px",
							opacity: "0.8",
							mb: 2,
							" div": { height: "inherit" },
						}}
					>
						{typeof svg !== "undefined" && svg ? (
							svg
						) : (
							<MatchNotFoundIllustration />
						)}
					</Box>
				)
				// <Image
				//   disabledEffect
				//   visibleByDefault
				//   alt="empty content"
				//   src={ SvgEmptyContent}
				//   sx={{ height: 200, mb: 2 }}
				// />
			}

			{title !== "" && (
				<Typography variant="h6" gutterBottom>
					{title}
				</Typography>
			)}

			{description && (
				<Typography variant="body1" sx={{ color: "text.secondary" }}>
					{description}
				</Typography>
			)}
			{children !== undefined && children}
		</RootStyle>
	);
}
