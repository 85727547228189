import { addHours, getTime } from "date-fns";
import type { Dispatch, SetStateAction } from "react";

export const versionStorage = (
	setCheckIfUpateAvailable: Dispatch<SetStateAction<boolean>>,
	checkIfUpateAvailable: boolean,
) => {
	const storedVersion = sessionStorage.getItem("versionDate");
	if (storedVersion) {
		// console.log(storedVersion, 'versionDate')
		// if(storedVersion){
		const Now = JSON.stringify(getTime(new Date()));
		// console.log('Version change checker Interval before If Now > storedVersion' )
		if (Number.parseFloat(Now) > Number.parseFloat(storedVersion)) {
			// console.log('Version change checker Interval' )
			setCheckIfUpateAvailable(!checkIfUpateAvailable);
			sessionStorage.setItem(
				"versionDate",
				JSON.stringify(getTime(addHours(new Date(), 24))),
			);
		}
		// }
	} else {
		sessionStorage.setItem(
			"versionDate",
			JSON.stringify(getTime(addHours(new Date(), 24))),
		);
	}
};

export const checkInterval = (
	setCheckIfUpateAvailable: Dispatch<SetStateAction<boolean>>,
	checkIfUpateAvailable: boolean,
) => {
	const interval = setInterval(
		() => {
			// console.log('This will run every 30 min for check new release');
			versionStorage(setCheckIfUpateAvailable, checkIfUpateAvailable);
		},
		1000 * 60 * 30,
	);
	return () => clearInterval(interval);
};
