import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	SvgIcon,
	Tooltip,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import useLocales from "src/hooks/useLocales";
import { IconButtonAnimate } from "../../../components/animate";
import MenuPopover from "../../../components/others/MenuPopover";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
	const { allLang, currentLang, onChangeLang, loading } = useLocales();

	const [open, setOpen] = useState<HTMLElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	return (
		<Box sx={{ position: "relative" }}>
			<IconButtonAnimate
				onClick={handleOpen}
				sx={{
					width: "44px",
					height: "44px",

					...(open && { bgcolor: "action.selected" }),
					"&:hover": {
						backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.2),
						// height:'100%',
						// width:'100%',
					},
				}}
			>
				<Tooltip title={currentLang.label}>
					<SvgIcon sx={{ width: 28 }} viewBox="0 0 3 2">
						<currentLang.icon />
					</SvgIcon>
				</Tooltip>

				{/* <Image disabledEffect src={currentLang.icon} alt={currentLang.label} sx={{borderRadius:"3px"}}/> */}
			</IconButtonAnimate>
			{loading && (
				<Stack
					direction="row"
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						zIndex: "10",
						transform: "translate(-50%, -50%)",
					}}
				>
					<CircularProgress size={50} sx={{ color: "secondary.main" }} />
				</Stack>
			)}

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					mt: 1.5,
					ml: 0.75,
					width: 180,
					"& .MuiMenuItem-root": {
						px: 1,
						typography: "body2",
						borderRadius: 0.75,
					},
				}}
			>
				<Stack spacing={0.75}>
					{allLang.map((option: any) => (
						<MenuItem
							key={option.value}
							sx={{ borderRadius: 0.75 }}
							selected={option.value === currentLang.value}
							onClick={() => {
								onChangeLang(option.value);
								handleClose();
							}}
						>
							<SvgIcon
								sx={{ width: 28, mr: 2, borderRadius: "25px" }}
								viewBox="0 0 3 2"
							>
								<option.icon title={option.label} />
							</SvgIcon>

							{option.label}
						</MenuItem>
					))}
				</Stack>
			</MenuPopover>
		</Box>
	);
}
