import { ElementType, ReactNode, useEffect } from "react";
import Smooch from "smooch";
import useAuth from "src/hooks/useAuth";
import { t } from "ttag";

import { useTheme } from "@mui/material";
import { Link } from "@mui/material";
import { RealAuthUser } from "src/@types/auth";
// import BgDark from 'src/assets/solid-color-image-dark.png'
// import BgLight from 'src/assets/solid-color-image-light.png'
import useFeedbackContext from "src/hooks/useFeedbackContext";
import useLocales from "src/hooks/useLocales";

type variantType =
	| "button"
	| "caption"
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "inherit"
	| "subtitle1"
	| "subtitle2"
	| "body1"
	| "body2"
	| "overline"
	| undefined;

export const ContactLink = (props: {
	children: ReactNode;
	variant?: variantType;
	sx?: object;
	component?: ElementType<any>;
}) => {
	const { setOpenContact } = useFeedbackContext();
	const { canUseChat } = useAuth();

	const onClick = () => {
		if (canUseChat()) {
			Smooch.open();
		} else {
			setOpenContact(true);
		}
	};

	return (
		<Link
			onClick={onClick}
			component={props.component ? props.component : "a"}
			variant={props.variant ? props.variant : "body1"}
			sx={{ ...props.sx, "&:hover": { cursor: "pointer" } }}
		>
			{props.children}
		</Link>
	);
};

const init = (locale: string, isLight: boolean, userDetails: RealAuthUser) => {
	const appId = "59a812155145844700e60291";

	try {
		Smooch.init({
			integrationId: appId,
			displayStyle: "button",
			fixedHeader: true,
			// businessName:,
			locale: locale,
			// buttonIconUrl
			// signedUpAt: "datetime automatically set by Smooch when the chat is loaded for the first time",
			// backgroundImageUrl: isLight ? BgLight : BgDark,
			customColors: {
				brandColor: isLight ? "6bac1e" : "6bac1d",
				// conversationColor: isLight ? '161c24' : 'edf0f2',
				// actionColor: isLight ? '161c24' : 'edf0f2',
				// backgroundColor:'6bac1e',
			} as CustomColors,
			customText: {
				headerText: t`Any questions? Contact us!'`,
				inputPlaceholder: t`Type a message...`,
				sendButtonText: t`Send`,
				introAppText: t`Any questions? Any suggestion?<br>Chat directly with your energy efficiency engineer!`,
				settingsHeaderText: t`We'll get back to you at this email address if we missed you.`,
				actionPaymentError: t`An error occurred while processing the card. <br> Please try again or use a different card.`,
				actionPaymentCompleted: t`Payment Completed.`,
				messageError: t`An error occurred while sending your message. Please try again.`,
				messageIndicatorTitleSingular: t`({count}) New message'`,
				messageIndicatorTitlePlural: t`({count}) New messages'`,
			},
			menuItems: {
				imageUpload: true,
				fileUpload: true,
				shareLocation: false,
			},
		}).then(
			() => {
				// console.log('smooch init', Smooch)
				Smooch.updateUser({
					// id: userDetails.state,
					givenName: userDetails.firstName,
					surname: userDetails.lastName,
					email: userDetails.email,
				});
			},
			(error: any) => {
				console.warn("Error in Smooch.init", error);
			},
		);
	} catch (e) {
		// Doing nothing, just avoiding a total crash
		// setisActive(false)
		console.error("smooch error", e);
	}
};

const ReallyActivateMessenger = () => {
	const { user } = useAuth();
	const { currentLang } = useLocales();
	const theme = useTheme();
	const isLight = theme.palette.mode === "light";

	useEffect(() => {
		if (!user) return;

		init(currentLang.value, isLight, user as RealAuthUser);

		return () => {
			try {
				// console.log('Smooch unmount, destroy')
				Smooch.destroy();
			} catch {}
		};
	}, [currentLang, isLight, user]);

	return <></>;
};

export const ActivateMessenger = () => {
	const { canUseChat } = useAuth();

	if (!canUseChat()) return <></>;

	return <ReallyActivateMessenger />;
};
