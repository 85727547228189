import { type ReactNode, createContext } from "react";
import type {
	SettingsContextProps,
	ThemeColorPresets,
	ThemeDirection,
	ThemeLayout,
	ThemeMode,
} from "../components/settings/type";
import { defaultSettings } from "../config";
import useLocalStorage from "../hooks/useLocalStorage";
import getColorPresets, {
	colorPresets,
	defaultPreset,
} from "../utils/getColorPresets";

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
	...defaultSettings,
	onChangeMode: () => {},
	onToggleMode: () => {},
	onChangeDirection: () => {},
	onChangeColor: () => {},
	onToggleStretch: () => {},
	onChangeLayout: () => {},
	onResetSetting: () => {},
	handleIntroTour: () => {},
	setColor: defaultPreset,
	colorOption: [],
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
	children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
	const [settings, setSettings] = useLocalStorage("settings", {
		themeMode: initialState.themeMode,
		themeDirection: initialState.themeDirection,
		themeColorPresets: initialState.themeColorPresets,
		themeStretch: initialState.themeStretch,
		themeLayout: initialState.themeLayout,
	});

	const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeMode: (event.target as HTMLInputElement).value as ThemeMode,
		});
	};

	const onToggleMode = () => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === "light" ? "dark" : "light",
		});
	};

	const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeDirection: (event.target as HTMLInputElement)
				.value as ThemeDirection,
		});
	};

	const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeColorPresets: (event.target as HTMLInputElement)
				.value as ThemeColorPresets,
		});
	};

	const onChangeLayout = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeLayout: (event.target as HTMLInputElement).value as ThemeLayout,
		});
	};

	const onToggleStretch = () => {
		setSettings({
			...settings,
			themeStretch: !settings.themeStretch,
		});
	};

	const onResetSetting = () => {
		setSettings({
			themeMode: initialState.themeMode,
			themeLayout: initialState.themeLayout,
			themeStretch: initialState.themeStretch,
			themeDirection: initialState.themeDirection,
			themeColorPresets: initialState.themeColorPresets,
		});
	};
	return (
		<SettingsContext.Provider
			value={{
				...settings,
				// Mode
				onChangeMode,
				onToggleMode,
				// Direction
				onChangeDirection,
				// Color
				onChangeColor,
				setColor: getColorPresets(settings.themeColorPresets),
				colorOption: colorPresets
					? colorPresets.map((color) => ({
							name: color.name,
							value: color.main,
						}))
					: null,
				// Stretch
				onToggleStretch,
				// Navbar Horizontal
				onChangeLayout,
				// Reset Setting
				onResetSetting,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
}

export { SettingsProvider, SettingsContext };
