import { flattenDeep, isArray } from "lodash";
import type { SiteContent } from "src/@types/SiteContent";
import type { SiteItem } from "src/@types/sites";
import { t } from "ttag";

export const findParentArray = (
	nodes: SiteItem[],
	targetId?: string,
): string[] => {
	if (targetId === undefined) return [];

	let parents: string[] = [];
	nodes.map((node) => {
		if (node.type !== "site" && node.children) {
			const childIndex = node.children.findIndex(
				(child) => child.uuid === targetId,
			);
			if (childIndex !== -1) {
				parents.push(node.uuid);
			} else {
				const result = findParentArray(node.children, targetId);
				if (result.length > 0) {
					parents = [node.uuid].concat(result);
				}
			}
		}
	});
	return parents;
};

export const findParentArraySite = (
	nodes: SiteItem[],
	targetId?: string,
): SiteItem[] => {
	if (targetId === undefined) return [];

	let parents: SiteItem[] = [];
	nodes.map((node) => {
		if (node.type !== "site" && node.children) {
			const childIndex = node.children.findIndex(
				(child) => child.uuid === targetId,
			);
			if (childIndex !== -1) {
				parents.push(node);
			} else {
				const result = findParentArraySite(node.children, targetId);
				if (result.length > 0) {
					parents.push(node);
					parents = parents.concat(result);
				}
			}
		}
	});
	return parents;
};

export const findChildrenArray = (node: SiteItem) => {
	let children: string[] = [];
	if (node.type !== "site" && node.children) {
		node.children.map((child) => {
			children.push(child.uuid);
			children = children.concat(findChildrenArray(child));
		});
	}
	return children;
};

export const returnChild = (item: any) => {
	if (item === undefined) return item;
	const data = isArray(item) ? item : item.children;
	if (data === undefined) return item;
	return flattenDeep(
		data.map((child: any) => {
			if (
				(child.type === "customer" ||
					child.type === "node" ||
					child.type === "site_group") &&
				child.children
			) {
				return returnChild(child.children);
			} else return child;
		}),
	);
};

export const returnParent = (
	item: SiteItem | SiteItem[] | SiteContent,
	MultiSitesOrigin: SiteItem[],
): SiteItem[] => {
	if (!item || !MultiSitesOrigin.length) return [];

	let parents: SiteItem[] | any = [];
	const all: SiteItem = {
		uuid: "all",
		label: t`All my sites`,
		type: "site_group",
		children: MultiSitesOrigin,
	};
	const findParentsRecursively = (
		currentItem: SiteItem | SiteItem[],
		nodes: SiteItem[],
	): boolean => {
		let found = false;
		nodes.map((node: any) => {
			if (typeof currentItem === "object" && "type" in currentItem)
				if (
					!found &&
					!isArray(currentItem) &&
					node.children &&
					node.children.includes(currentItem)
				) {
					parents.unshift(currentItem);
					parents.unshift(node);
					found = true;
				} else if (
					!found &&
					node.children &&
					findParentsRecursively(currentItem, node.children)
				) {
					parents.unshift(node);
					found = true;
				} else if (!found && node === currentItem) {
					parents.unshift(node);
					found = true;
				}
		});
		return found;
	};

	if (
		!(typeof item === "object" && "type" in item) &&
		typeof item === "object" &&
		"uuid" in item
	) {
		parents = findParentArraySite(MultiSitesOrigin, item.uuid);
		const currentItemNode: Partial<SiteItem | any> = {
			uuid: item.uuid,
			label: item.label,
			default_installation: item.default_installation,
			type: "site",
		};
		parents.push(currentItemNode);
	} else findParentsRecursively(item, MultiSitesOrigin);

	parents.splice(0, 0, all);
	return parents;
};

export const findSiteItemByUUID = (
	siteItem: SiteItem[],
	uuid: string,
): SiteItem | null => {
	let foundItem: SiteItem | null = null;
	siteItem.some((siteObj) => {
		if (siteObj.uuid === uuid) {
			foundItem = siteObj;
			return true;
		}
		if (
			(siteObj.type === "customer" || siteObj.type === "site_group") &&
			siteObj.children &&
			siteObj.children.length > 0
		) {
			foundItem = findSiteItemByUUID(siteObj.children, uuid);
			return !!foundItem;
		}
		return false;
	});

	return foundItem;
};
