import "highlight.js/styles/atom-one-dark-reasonable.css";

import hljs from "highlight.js/lib/core";
import bash from "highlight.js/lib/languages/bash";
import css from "highlight.js/lib/languages/css";
import javascript from "highlight.js/lib/languages/javascript";
import jsx from "highlight.js/lib/languages/javascript";
import json from "highlight.js/lib/languages/json";
import scss from "highlight.js/lib/languages/scss";
import html from "highlight.js/lib/languages/xml";

// ----------------------------------------------------------------------

declare global {
	interface Window {
		hljs: any;
	}
}

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("jsx", jsx);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("html", html);
hljs.registerLanguage("scss", scss);
hljs.registerLanguage("css", css);
hljs.registerLanguage("json", json);

if (typeof window !== "undefined") {
	window.hljs = hljs;
}
