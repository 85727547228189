import type { Theme } from "@mui/material/styles";
//
//
import { InputSelectIcon } from "./CustomIcons";

// ----------------------------------------------------------------------

export default function Select() {
	return {
		MuiSelect: {
			defaultProps: {
				IconComponent: InputSelectIcon,
			},
		},
	};
}
