// @mui
import { Collapse, List } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getActive } from "..";
// type
import type { NavListProps } from "../type";
//
import { NavItemRoot, NavItemSub } from "./NavItem";

// ----------------------------------------------------------------------

type NavListRootProps = {
	list: NavListProps;
	isCollapse: boolean;
};

export function NavListRoot({ list, isCollapse }: NavListRootProps) {
	const { pathname } = useLocation();
	const active = getActive(list.path, pathname);
	const [open, setOpen] = useState(active);

	const hasChildren = list.children;

	const activeChildren = (list.children || []).some((item) =>
		getActive(item.path, pathname),
	);

	if (hasChildren) {
		return (
			<>
				<NavItemRoot
					item={list}
					isCollapse={isCollapse}
					active={active || activeChildren}
					open={open}
					onOpen={() => setOpen(!open)}
				/>

				{!isCollapse && (
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{(list.children || []).map((item) => (
								<NavListSub
									key={item.title}
									list={item}
									// sx={{ height: "30px" }}
								/>
							))}
						</List>
					</Collapse>
				)}
			</>
		);
	}

	return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
	list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
	const { pathname } = useLocation();
	const active = getActive(list.path, pathname);

	const [open, setOpen] = useState(active);

	const hasChildren = list.children;

	if (hasChildren) {
		return (
			<>
				<NavItemSub
					item={list}
					onOpen={() => setOpen(!open)}
					open={open}
					active={active}
				/>

				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding sx={{ pl: 3 }}>
						{(list.children || []).map((item) => (
							<NavItemSub
								key={item.title}
								item={item}
								active={getActive(item.path, pathname)}
							/>
						))}
					</List>
				</Collapse>
			</>
		);
	}

	return <NavItemSub item={list} active={active} />;
}
