import axios, { type AxiosAdapter } from "axios";
import { setupCache } from "axios-cache-adapter";
import localforage from "localforage";
// config
import { API_URL } from "../config";
import { sleep } from "./sleep";

// ----------------------------------------------------------------------

const forageStore = localforage.createInstance({
	driver: [localforage.LOCALSTORAGE],
	// Prefix all storage keys to prevent conflicts
	name: "dashboard-cache",
});

let options: { adapter?: AxiosAdapter } = {};

if (process.env.NODE_ENV !== "test") {
	const cache = setupCache({
		maxAge: 15 * 60 * 1000, // 15 minutes
		store: forageStore,
		exclude: { query: false }, // allow caching of requests with '?' queries
		debug: process.env.NODE_ENV !== "production",
	});

	options = {
		adapter: cache.adapter,
	};
}

const axiosInstance = axios.create({
	...options,
	baseURL: API_URL,
	xsrfHeaderName: "X-CSRFToken",
	xsrfCookieName: "csrftoken",
	withCredentials: true,
	transitional: {
		// `false` - throw SyntaxError if JSON parsing failed (Note: responseType must be set to 'json')
		silentJSONParsing: false,

		// try to parse the response string as JSON even if `responseType` is not 'json'
		forcedJSONParsing: false,
	},
	validateStatus: (status) => {
		return status >= 200 && status < 303; // default
	},
	maxRedirects: 0,
});

// DEV only
axiosInstance.interceptors.response.use(async (response) => {
	// add artificial delay for dev env
	const add_sleep = false;
	if (process.env.NODE_ENV === "development" && add_sleep) {
		await sleep(2500);
		// await sleep(Math.floor(Math.random() * 3000))
	}
	return response;
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		const json_error = error.toJSON ? error.toJSON() : error;
		console.warn("Intercepted error", json_error);
		if (error?.response?.data && json_error.response === undefined)
			json_error.response_data = JSON.parse(error.response.data);
		return Promise.reject(json_error);
	},
);

export { axiosInstance, forageStore };
