import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "src/utils/axios";

enum CallStatus {
	NOT_STARTED = 0,
	PENDING = 1,
	RESOLVED = 2,
}

type CallState =
	| {
			state: CallStatus.NOT_STARTED;
			promise?: Promise<AxiosResponse<any, any>>;
	  }
	| {
			state: CallStatus.PENDING;
			promise: Promise<AxiosResponse<any, any>>;
	  }
	| {
			state: CallStatus.RESOLVED;
			promise?: Promise<AxiosResponse<any, any>>;
	  };

const promises: { [key: string]: CallState } = {};

// function setDefault<T>(obj: { [key: string]: T }, prop: string, deflt: T): T {
// 	return obj.hasOwnProperty(prop) ? obj[prop] : (obj[prop] = deflt);
// }
function setDefault<T>(obj: { [key: string]: T }, prop: string, deflt: T): T {
	if (obj.hasOwnProperty(prop)) {
		return obj[prop];
	} else {
		obj[prop] = deflt;
		return deflt;
	}
}

export const callAggregator = (
	url: string,
	config?: AxiosRequestConfig<any>,
	abort_signal?: AbortSignal,
): Promise<AxiosResponse<any, any>> => {
	const uri = axiosInstance.getUri({
		url: url,
		params: config?.params,
		signal: abort_signal,
	});

	const state = setDefault(promises, uri, { state: CallStatus.NOT_STARTED });

	// console.log('callAggregator state: ', state);

	switch (state.state) {
		case CallStatus.NOT_STARTED:
		case CallStatus.RESOLVED: // leverage axios-cache-adapter for existing calls, to handle cache usage and invalidation in one place.
			state.promise = new Promise((resolve, reject) =>
				axiosInstance.get(url, config).then(
					(data) => {
						state.state = CallStatus.RESOLVED;
						resolve(data);
					},
					(reason) => {
						reject(reason);
					},
				),
			);
			// The switch has narrowed down the type of state.state, it won't let us change it
			// @ts-ignore
			state.state = CallStatus.PENDING;
			return state.promise;
		case CallStatus.PENDING:
			// console.log('callAggregator: re-use promise for', uri)
			return state.promise;
	}
};
