// @mui
import {
	FormControlLabel,
	type FormControlLabelProps,
	Switch,
} from "@mui/material";
// form
import { Controller, useFormContext } from "react-hook-form";

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, "control">;

interface Props extends IProps {
	name: string;
}

export default function RHFSwitch({ name, ...other }: Props) {
	const { control } = useFormContext();

	return (
		<FormControlLabel
			control={
				<Controller
					name={name}
					control={control}
					render={({ field }) => <Switch {...field} checked={field.value} />}
				/>
			}
			{...other}
		/>
	);
}
