// import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

// @mui
import { type Localization, enUS, frFR } from "@mui/material/locale";
import { enUS as pickerEN, frFR as pickerFR } from "@mui/x-date-pickers";

import {
	type FlagComponent,
	FR as flagFR,
	GB as flagGB,
	US as flagUS,
} from "country-flag-icons/react/3x2";
import type { Locale } from "date-fns";
import { fr as dateFr, enGB as dateGB, enUS as dateUs } from "date-fns/locale";

import useAuth from "src/hooks/useAuth";

import frLang from "src/i18n/fr.po.json";
import { addLocale, useLocale } from "ttag";
import type { LocaleData } from "ttag/types";

// ----------------------------------------------------------------------

export type Lang = {
	label: string;
	value: string;
	ttagValue: string; // reference to "*.po" files
	translations?: LocaleData;
	systemValue: Localization; // TODO useful ?
	dateLocale: Locale; // See list in https://github.com/date-fns/date-fns/tree/master/src/locale
	numberLocale: string; // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation
	icon: FlagComponent;
	datepickerLocaleText: typeof pickerFR.components.MuiLocalizationProvider.defaultProps.localeText;
};

const LANGS: Lang[] = [
	{
		label: "Français (FR)",
		value: "fr",
		ttagValue: "fr",
		translations: frLang,
		systemValue: frFR,
		dateLocale: dateFr,
		numberLocale: "fr",
		icon: flagFR,
		// cf https://github.com/mui/mui-x/blob/master/packages/x-date-pickers/src/locales/frFR.ts
		datepickerLocaleText:
			pickerFR.components.MuiLocalizationProvider.defaultProps.localeText,
	},
	{
		label: "English (UK)",
		value: "en-gb",
		ttagValue: "",
		systemValue: enUS,
		dateLocale: dateGB,
		numberLocale: "en-GB",
		icon: flagGB,
		datepickerLocaleText:
			pickerEN.components.MuiLocalizationProvider.defaultProps.localeText,
	},
	// {
	//   label: 'German',
	//   value: 'de',
	//   systemValue: deDE,
	//   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
	// },
].concat(
	process.env.NODE_ENV === "production"
		? []
		: [
				{
					label: "English (US)",
					value: "en-us",
					ttagValue: "",
					systemValue: enUS,
					dateLocale: dateUs,
					numberLocale: "en-US",
					icon: flagUS,
					datepickerLocaleText:
						pickerEN.components.MuiLocalizationProvider.defaultProps.localeText,
				},
			],
);

export const codeToLocale = (code: string): Locale => {
	const res = LANGS.find((_lang) => _lang.dateLocale.code === code);
	if (!res) {
		console.debug("Defaulting to first language");
		return LANGS[0].dateLocale;
	}
	return res.dateLocale;
};

function findLang(lang: string | null) {
	const res = LANGS.find((_lang) => _lang.value === lang);
	if (!res) {
		console.debug("Defaulting to first language");
		return LANGS[0];
	}
	return res;
}

export default function useLocales() {
	const { user, seti18n } = useAuth();

	const initialLang = findLang(user?.language);
	const [currentLang, setCurrentLang] = useState(initialLang);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (user === null) return;
		if (currentLang.value === user?.language) return;

		const lang = findLang(user?.language);
		// console.log('useLocales', lang.value, user)
		setCurrentLang(lang);
	}, [user, currentLang]);

	function SetTtagLanguage(lang: Lang) {
		useLocale(lang.ttagValue); // Activate locale

		document.documentElement.lang = lang.value.split("-")[0];
	}

	for (const lang of LANGS) {
		if (lang.ttagValue && lang.translations) {
			addLocale(lang.ttagValue, lang.translations); // adding locale to ttag
		}
	}

	SetTtagLanguage(initialLang);
	sessionStorage.setItem(
		"date-fns-locale-code",
		initialLang.dateLocale.code || "",
	);

	const onChangeLang = (newlang: string) => {
		const lang = findLang(newlang);
		if (lang.value !== user?.language) {
			setLoading(true);
			seti18n(lang.value).then(
				() => {},
				(error) => {
					console.error("seti18n failed:", error);
				},
			);
		}
	};

	return {
		onChangeLang,
		currentLang,
		allLang: LANGS,
		loading,
	};
}
