import { Box, type BoxProps } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import Image from "src/components/others/Image";

// ----------------------------------------------------------------------

export default function DataReportIllustration({ ...other }: BoxProps) {
	const theme = useTheme();
	const PRIMARY = theme.palette.secondary.main; // to replace "#0CBC8B"
	const SECONDARY = theme.palette.primary.main; // to replace "#367CFF"

	return (
		<Box {...other}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				viewBox="0 0 788.266 775.221"
			>
				<g id="Layer_8" data-name="Layer 8">
					<path
						d="M316.628,614.4H306.375a3.928,3.928,0,0,1-3.713-2.628,55.927,55.927,0,0,0-2.78-6.589,3.927,3.927,0,0,1,.747-4.536l7.288-7.287a3.954,3.954,0,0,0,0-5.587L292.173,572.04a3.952,3.952,0,0,0-5.587,0l-7.418,7.419a3.929,3.929,0,0,1-4.5.76,55.292,55.292,0,0,0-6.458-2.662,3.929,3.929,0,0,1-2.662-3.726v-10.51a3.947,3.947,0,0,0-3.948-3.948H239.342a3.947,3.947,0,0,0-3.948,3.948v10.51a3.928,3.928,0,0,1-2.661,3.726,55.15,55.15,0,0,0-6.458,2.662,3.928,3.928,0,0,1-4.5-.76l-7.419-7.419a3.952,3.952,0,0,0-5.587,0l-15.743,15.736a3.952,3.952,0,0,0,0,5.587l7.287,7.287a3.925,3.925,0,0,1,.747,4.536,54.9,54.9,0,0,0-2.773,6.589,3.943,3.943,0,0,1-3.719,2.628H184.313a3.947,3.947,0,0,0-3.948,3.948v22.263a3.947,3.947,0,0,0,3.948,3.948H194.3a3.939,3.939,0,0,1,3.747,2.71,55.815,55.815,0,0,0,2.759,6.769,3.954,3.954,0,0,1-.747,4.529l-7.031,7.032a3.942,3.942,0,0,0,0,5.579l15.743,15.744a3.952,3.952,0,0,0,5.587,0l6.893-6.894a3.951,3.951,0,0,1,4.563-.732,54.915,54.915,0,0,0,6.894,2.876,3.956,3.956,0,0,1,2.689,3.74v9.722a3.949,3.949,0,0,0,3.948,3.954H261.6a3.948,3.948,0,0,0,3.948-3.954v-9.722a3.956,3.956,0,0,1,2.689-3.74,54.915,54.915,0,0,0,6.894-2.876,3.951,3.951,0,0,1,4.563.732l6.893,6.894a3.952,3.952,0,0,0,5.587,0l15.744-15.744a3.944,3.944,0,0,0,0-5.579l-7.032-7.032a3.955,3.955,0,0,1-.747-4.529,55.654,55.654,0,0,0,2.759-6.769,3.94,3.94,0,0,1,3.747-2.71h9.984a3.947,3.947,0,0,0,3.948-3.948V618.351A3.947,3.947,0,0,0,316.628,614.4ZM250.474,662.38a32.9,32.9,0,1,1,32.9-32.9A32.9,32.9,0,0,1,250.474,662.38Z"
						fill="#f4f4f4"
					/>
					<path
						d="M188.568,481.774H174.609a5.349,5.349,0,0,1-5.055-3.577,76.2,76.2,0,0,0-3.784-8.971,5.343,5.343,0,0,1,1.016-6.175l9.922-9.921a5.381,5.381,0,0,0,0-7.606L155.274,424.1a5.381,5.381,0,0,0-7.606,0l-10.1,10.1a5.347,5.347,0,0,1-6.128,1.035,75.1,75.1,0,0,0-8.791-3.624,5.349,5.349,0,0,1-3.624-5.073V412.23a5.374,5.374,0,0,0-5.375-5.375h-30.3a5.374,5.374,0,0,0-5.375,5.375v14.308a5.349,5.349,0,0,1-3.624,5.073,75.187,75.187,0,0,0-8.792,3.624A5.347,5.347,0,0,1,59.43,434.2l-10.1-10.1a5.381,5.381,0,0,0-7.606,0L20.291,445.524a5.381,5.381,0,0,0,0,7.606l9.921,9.921a5.343,5.343,0,0,1,1.017,6.175,74.664,74.664,0,0,0-3.775,8.971,5.369,5.369,0,0,1-5.064,3.577H8.431a5.372,5.372,0,0,0-5.375,5.374v30.31a5.372,5.372,0,0,0,5.375,5.375H22.023a5.366,5.366,0,0,1,5.1,3.69,75.714,75.714,0,0,0,3.756,9.216,5.384,5.384,0,0,1-1.017,6.165l-9.573,9.573a5.37,5.37,0,0,0,0,7.6l21.433,21.433a5.381,5.381,0,0,0,7.606,0l9.385-9.385a5.38,5.38,0,0,1,6.213-1,74.666,74.666,0,0,0,9.385,3.915,5.389,5.389,0,0,1,3.661,5.093v13.235a5.376,5.376,0,0,0,5.375,5.384h30.3a5.376,5.376,0,0,0,5.375-5.384V579.133a5.387,5.387,0,0,1,3.661-5.093,74.728,74.728,0,0,0,9.385-3.915,5.38,5.38,0,0,1,6.213,1l9.384,9.385a5.381,5.381,0,0,0,7.606,0l21.434-21.433a5.37,5.37,0,0,0,0-7.6l-9.573-9.573a5.384,5.384,0,0,1-1.017-6.165,75.874,75.874,0,0,0,3.756-9.216,5.366,5.366,0,0,1,5.1-3.69h13.592a5.372,5.372,0,0,0,5.375-5.375v-30.31A5.372,5.372,0,0,0,188.568,481.774ZM98.5,547.091A44.792,44.792,0,1,1,143.291,502.3,44.793,44.793,0,0,1,98.5,547.091Z"
						fill="#f4f4f4"
					/>
					<g>
						<rect
							x="118.033"
							y="572.458"
							width="1.737"
							height="195.977"
							transform="translate(-72.228 16.913) rotate(-6.245)"
							fill="#292a2e"
						/>
						<rect
							x="107.796"
							y="709.088"
							width="104.283"
							height="1.737"
							transform="translate(-562.504 797.686) rotate(-84.228)"
							fill="#292a2e"
						/>
						<rect
							x="104.533"
							y="749.207"
							width="77.82"
							height="25.255"
							fill="#292a2e"
						/>
						<rect
							x="104.533"
							y="749.207"
							width="77.82"
							height="25.255"
							fill="#fff"
							opacity="0.2"
						/>
						<ellipse
							cx="186.877"
							cy="670.815"
							rx="44.397"
							ry="53.048"
							transform="translate(-461.51 416.827) rotate(-53.042)"
							fill={PRIMARY}
						/>
						<ellipse
							cx="186.877"
							cy="670.815"
							rx="44.397"
							ry="53.048"
							transform="translate(-461.51 416.827) rotate(-53.042)"
							fill="#fff"
							opacity="0.2"
						/>
						<path
							d="M229.264,702.709a35.8,35.8,0,0,1-3.233,3.746c-16.228,10.367-40.181,8.735-59.183-5.565-23.409-17.611-30.435-47.773-15.691-67.366a37.216,37.216,0,0,1,3.225-3.746c16.236-10.376,40.181-8.736,59.191,5.557C236.981,652.953,244.007,683.116,229.264,702.709Z"
							fill="#fff"
							opacity="0.5"
						/>
						<ellipse
							cx="92.751"
							cy="592.204"
							rx="53.048"
							ry="45.682"
							transform="translate(-342.536 180.096) rotate(-37.665)"
							fill={PRIMARY}
						/>
						<path
							d="M113.316,623.234c-17.961,13.873-40.787,15.987-57.333,6.956a40.008,40.008,0,0,1-5.223-5.573c-15.419-19.966-9.116-50.672,14.075-68.571,17.961-13.865,40.787-15.987,57.325-6.963a39.643,39.643,0,0,1,5.23,5.58C142.81,574.629,136.507,605.335,113.316,623.234Z"
							fill="#fff"
							opacity="0.2"
						/>
					</g>
					<g>
						<rect
							x="462.683"
							y="444.019"
							width="283.474"
							height="151.987"
							rx="11.243"
							fill="#f4f4f4"
						/>
						<g>
							<rect
								id="gra19_b"
								x="615.18"
								y="530.323"
								width="22.083"
								height="44.094"
								rx="1.995"
								fill="#fff"
							/>
							<rect
								id="gra19_b-2"
								data-name="gra19_b"
								x="658.784"
								y="488.233"
								width="22.083"
								height="86.184"
								rx="1.995"
								fill="#fff"
							/>
							<rect
								id="gra19_b-3"
								data-name="gra19_b"
								x="702.388"
								y="520.439"
								width="22.083"
								height="53.978"
								rx="1.995"
								fill="#fff"
							/>
							<rect
								id="gra19_b-4"
								data-name="gra19_b"
								x="484.369"
								y="530.323"
								width="22.083"
								height="44.094"
								rx="1.995"
								fill="#fff"
							/>
							<rect
								id="gra19_b-5"
								data-name="gra19_b"
								x="527.973"
								y="488.233"
								width="22.083"
								height="86.184"
								rx="1.995"
								fill="#fff"
							/>
							<rect
								id="gra19_b-6"
								data-name="gra19_b"
								x="571.577"
								y="520.439"
								width="22.083"
								height="53.978"
								rx="1.995"
								fill="#fff"
							/>
						</g>
					</g>
					<g>
						<path
							d="M625.276,53.55S614.927,44.592,613.5,25.471c-1.109-14.8,11.781-4.4,11.781-4.4s-.694-10.668,6.936-9.1,11.2,12.962,11.2,12.962"
							fill="#0c0c0c"
						/>
						<path
							d="M671.949,15.877s37.949,6.659,20.66,57.529H682.315"
							fill="#0c0c0c"
						/>
						<g>
							<path
								d="M697.023,729.808s-4.515,23.4-26.428,24.709-17.162,18.761-17.162,18.761h83.739V731.441Z"
								fill={SECONDARY}
							/>
							<path
								d="M742.852,302.878s-.263-7.035-.208,8.924c.208,59.529-1.776,432.943-1.776,432.943l-55.025-.462L652.51,300.459Z"
								fill="#262626"
							/>
							<path
								d="M603.061,729.808s-4.515,23.4-26.428,24.709-17.161,18.761-17.161,18.761h83.739V731.441Z"
								fill={SECONDARY}
							/>
							<path
								d="M592.418,307.5s-6.874,203.456-6.159,213.4l6.463,222.3,54.117,1,8.726-231.344s5.72-63.815,21.139-123.073a459.357,459.357,0,0,0,14.168-92.938h0Z"
								fill="#262626"
							/>
							<path
								d="M663.811,451.751,659.3,382.742s-4.635,28.689-4.635,53.417c0,12.087,2.489,59.942,2.489,59.942Z"
								fill="#0a0a0a"
							/>
						</g>
						<g>
							<path
								id="sk"
								d="M629.209,126.292c.169-.022,2.038,30.991,38.066,28.287,24.942-1.868,33.836-28.331,34.407-28.331-2.689-.571-6.012-1.439-7.333-1.736a14.552,14.552,0,0,1-11.132-12.237c-.729-4.4.508-26.886.127-31.334l-38.286,8.546c.535,9.393,4.279,31.17-8.844,35.024C634.03,125.149,631.678,125.735,629.209,126.292Z"
								fill="#E98862"
							/>
							<path
								id="sh"
								d="M644.521,115.612a27.691,27.691,0,0,0,1.152-5.943,63.33,63.33,0,0,0-.25-13.82,41.979,41.979,0,0,0,21.16-9.271c.711,2.626,4.621,14.1,3.538,16.593C668.075,107.888,658.459,115.612,644.521,115.612Z"
								fill="#0c0c0c"
								opacity="1"
							/>
							<path
								d="M651.317,108.119c15.161-.342,33.837-8.656,35.468-29.095l1.472-18.443c1.631-20.44-7.783-37.866-27.028-39.4h0c-19.245-1.536-34.952,13.886-36.583,34.326l-.652,8.165a61.977,61.977,0,0,0,.05,8.428c.5,7.418,1.278,18.7,5.761,26.593C634.038,106.145,641.631,108.337,651.317,108.119Z"
								fill="#E98862"
							/>
							<path
								d="M645.577,88.951a19.911,19.911,0,0,1-8.933-2.335.487.487,0,0,1,.442-.868c5.942,3.018,11.522,3.008,16.136-.028a2.988,2.988,0,0,1,.289-.178.487.487,0,0,1,.427.876c-.037.02-.1.061-.18.116A14.608,14.608,0,0,1,645.577,88.951Z"
								fill="#0c0c0c"
								opacity="1"
							/>
							<path
								d="M645.414,93.569a9.89,9.89,0,0,1-1.683-.153.487.487,0,0,1,.168-.96c3.342.584,5.052-.851,5.124-.913a.487.487,0,0,1,.642.733A6.6,6.6,0,0,1,645.414,93.569Z"
								fill="#0c0c0c"
								opacity="1"
							/>
							<circle cx="630.355" cy="65.664" r="2.378" fill="#0c0c0c" />
							<circle cx="654.392" cy="65.814" r="2.378" fill="#0c0c0c" />
							<path
								d="M685.093,85.155c5.848.467,11.089-5.411,11.555-11.26s-4.017-9.452-9.865-9.919l-3.327,3.679-3.392,17.1Z"
								fill="#E98862"
							/>
						</g>
						<path
							d="M584.446,194.569c1.135,13.332,6.655,96.51,7.746,112.932H742.564c.85-14.811,4.664-79.576,7.885-108.539,3.075-27.638,9.108-41.475,4.686-49.646-7.226-13.354-26.972-21.071-40.436-22.894-8.221-1.112-20.587-1.91-20.587-1.91s-1.047,21.18-26.013,22.359c-33.985,1.6-31.716-22.359-31.884-22.359-5.506,0-36.958,3.485-44.653,8.046C570.594,144.975,582.037,166.316,584.446,194.569Z"
							fill={SECONDARY}
						/>
						<g>
							<path
								d="M679.9,75.359s-.037-29.079-8.695-31.455c-5.766-1.582-20.5,5.76-33.461,2.376-5.672-1.481-11.221-.424-11.957,2.252-.292,1.06-2.836-14.62,6.945-25.735,7.788-8.849,31.4-9.913,41.862-6.139,28.96,10.449,13.31,48.674,13.31,48.674l-1.985,9.892Z"
								fill="#0c0c0c"
							/>
							<g opacity="1">
								<path
									d="M642.5,81.883l-.038,0c-1.884-.145-5.03-.6-4.923-2.01.091-1.168,4.262-13.534,4.738-14.94a.481.481,0,0,1,.912.308c-1.852,5.477-4.628,13.912-4.69,14.706.16.348,2.085.825,4.037.976a.482.482,0,0,1-.036.962Z"
									fill="#0c0c0c"
								/>
							</g>
							<path
								d="M637.037,55.364a9.345,9.345,0,0,0-4.812-.626,8.269,8.269,0,0,0-2.4.681,10.216,10.216,0,0,0-2.177,1.328l-.053.04a.832.832,0,0,1-1.048-1.292,11.976,11.976,0,0,1,2.449-1.759,10.267,10.267,0,0,1,2.873-1.051,11.27,11.27,0,0,1,3.065-.228,11.114,11.114,0,0,1,3.1.612,1.252,1.252,0,0,1-.831,2.362c-.03-.011-.06-.022-.088-.035Z"
								fill="#0c0c0c"
							/>
							<path
								d="M651,53.268a11.6,11.6,0,0,1,6.165-.383,10.256,10.256,0,0,1,2.873,1.051,11.943,11.943,0,0,1,2.449,1.76.832.832,0,0,1-1.046,1.293l-.055-.042a10.175,10.175,0,0,0-2.177-1.328,8.262,8.262,0,0,0-2.4-.682,9.345,9.345,0,0,0-4.812.626l-.07.031A1.252,1.252,0,0,1,650.9,53.3C650.933,53.291,650.966,53.278,651,53.268Z"
								fill="#0c0c0c"
							/>
						</g>
						<ellipse
							cx="645.294"
							cy="22.126"
							rx="11.823"
							ry="8.032"
							transform="translate(450.075 637.58) rotate(-74.365)"
							fill="#0c0c0c"
						/>
					</g>
					<g>
						<rect
							y="49.389"
							width="256.608"
							height="137.583"
							rx="12.273"
							fill="#f4f4f4"
						/>
						<g>
							<rect
								id="gra19_b-7"
								data-name="gra19_b"
								x="138.044"
								y="127.513"
								width="19.99"
								height="39.915"
								rx="1.805"
								fill="#fff"
							/>
							<rect
								id="gra19_b-8"
								data-name="gra19_b"
								x="177.516"
								y="89.412"
								width="19.99"
								height="78.016"
								rx="1.805"
								fill="#fff"
							/>
							<rect
								id="gra19_b-9"
								data-name="gra19_b"
								x="216.987"
								y="118.566"
								width="19.99"
								height="48.862"
								rx="1.805"
								fill="#fff"
							/>
							<rect
								id="gra19_b-10"
								data-name="gra19_b"
								x="19.631"
								y="127.513"
								width="19.99"
								height="39.915"
								rx="1.805"
								fill="#fff"
							/>
							<rect
								id="gra19_b-11"
								data-name="gra19_b"
								x="59.102"
								y="89.412"
								width="19.99"
								height="78.016"
								rx="1.805"
								fill="#fff"
							/>
							<rect
								id="gra19_b-12"
								data-name="gra19_b"
								x="98.573"
								y="118.566"
								width="19.99"
								height="48.862"
								rx="1.805"
								fill="#fff"
							/>
						</g>
					</g>
					<g>
						<rect
							x="115.146"
							y="1"
							width="453.17"
							height="527.791"
							rx="16.26"
							fill="#fff"
						/>
						<path
							d="M552.056,529.791H131.406a17.28,17.28,0,0,1-17.26-17.26V17.26A17.28,17.28,0,0,1,131.406,0h420.65a17.28,17.28,0,0,1,17.26,17.26V512.531A17.28,17.28,0,0,1,552.056,529.791ZM131.406,2a15.278,15.278,0,0,0-15.26,15.26V512.531a15.278,15.278,0,0,0,15.26,15.26h420.65a15.278,15.278,0,0,0,15.26-15.26V17.26A15.278,15.278,0,0,0,552.056,2Z"
							fill="#292a2e"
						/>
					</g>
					<circle
						id="gra7_p"
						cx="389.358"
						cy="71.105"
						r="9.747"
						fill={SECONDARY}
					/>
					<circle
						id="gra7_p-2"
						data-name="gra7_p"
						cx="389.358"
						cy="71.105"
						r="9.747"
						fill={SECONDARY}
					/>
					<circle
						id="gra7_p-3"
						data-name="gra7_p"
						cx="389.358"
						cy="120.789"
						r="9.747"
						fill="#292a2e"
					/>
					<circle
						id="gra7_p-4"
						data-name="gra7_p"
						cx="389.358"
						cy="170.473"
						r="9.747"
						fill={SECONDARY}
						opacity="0.5"
					/>
					<circle
						id="gra7_p-5"
						data-name="gra7_p"
						cx="389.358"
						cy="220.157"
						r="9.747"
						fill={SECONDARY}
						opacity="0.2"
					/>
					<g>
						<path
							d="M195.883,145.631A57.447,57.447,0,0,0,234.2,199.784L218.444,238.2A98.913,98.913,0,0,1,253.305,46.729V88.21A57.422,57.422,0,0,0,195.883,145.631Z"
							fill="#fff"
							opacity="0"
						/>
						<path
							d="M195.883,145.631A57.447,57.447,0,0,0,234.2,199.784L218.444,238.2A98.913,98.913,0,0,1,253.305,46.729V88.21A57.422,57.422,0,0,0,195.883,145.631Z"
							fill="#292a2e"
						/>
						<path
							d="M352.208,145.631H310.727A57.422,57.422,0,0,0,253.305,88.21V46.729A98.909,98.909,0,0,1,352.208,145.631Z"
							fill={SECONDARY}
						/>
						<path
							d="M352.208,145.631A98.944,98.944,0,0,1,275.3,242.079l-7.028-41a57.444,57.444,0,0,0,42.459-55.444Z"
							fill={SECONDARY}
							opacity="0.5"
						/>
						<path
							d="M275.3,242.079a99.373,99.373,0,0,1-56.852-3.875l15.755-38.42a57.757,57.757,0,0,0,34.069,1.291Z"
							fill={SECONDARY}
							opacity="0.2"
						/>
					</g>
					<path
						d="M524.948,71.812h-112.9a.707.707,0,0,1,0-1.414h112.9a.707.707,0,0,1,0,1.414Z"
						fill={SECONDARY}
					/>
					<path
						d="M524.948,121.5h-112.9a.707.707,0,0,1,0-1.414h112.9a.707.707,0,0,1,0,1.414Z"
						fill="#292a2e"
					/>
					<g opacity="0.5">
						<path
							d="M524.948,171.18h-112.9a.707.707,0,0,1,0-1.413h112.9a.707.707,0,1,1,0,1.413Z"
							fill={SECONDARY}
						/>
					</g>
					<g opacity="0.2">
						<path
							d="M524.948,220.864h-112.9a.707.707,0,0,1,0-1.413h112.9a.707.707,0,1,1,0,1.413Z"
							fill={SECONDARY}
						/>
					</g>
					<g>
						<g opacity="0.1">
							<path
								d="M525.434,490.891H158.028a12.062,12.062,0,0,1-12.048-12.049V292.7a12.062,12.062,0,0,1,12.048-12.048H525.434A12.061,12.061,0,0,1,537.482,292.7V478.842A12.062,12.062,0,0,1,525.434,490.891Z"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b"
								x="186.961"
								y="322.877"
								width="11.051"
								height="140.539"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-2"
								data-name="gra5_b"
								x="175.909"
								y="367.018"
								width="11.051"
								height="96.399"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-3"
								data-name="gra5_b"
								x="275.401"
								y="445.634"
								width="11.051"
								height="17.783"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-4"
								data-name="gra5_b"
								x="264.35"
								y="359.081"
								width="11.051"
								height="104.335"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-5"
								data-name="gra5_b"
								x="231.181"
								y="379.294"
								width="11.051"
								height="84.122"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-6"
								data-name="gra5_b"
								x="220.129"
								y="408.36"
								width="11.051"
								height="55.056"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-7"
								data-name="gra5_b"
								x="408.061"
								y="350.058"
								width="11.051"
								height="113.359"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-8"
								data-name="gra5_b"
								x="397.01"
								y="391.793"
								width="11.051"
								height="71.624"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-9"
								data-name="gra5_b"
								x="319.621"
								y="367.74"
								width="11.051"
								height="95.676"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-10"
								data-name="gra5_b"
								x="308.57"
								y="378.572"
								width="11.051"
								height="84.844"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-11"
								data-name="gra5_b"
								x="363.841"
								y="322.877"
								width="11.051"
								height="140.539"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-12"
								data-name="gra5_b"
								x="352.79"
								y="329.647"
								width="11.051"
								height="133.769"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-13"
								data-name="gra5_b"
								x="452.281"
								y="322.877"
								width="11.051"
								height="140.539"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-14"
								data-name="gra5_b"
								x="441.23"
								y="367.018"
								width="11.051"
								height="96.399"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
						<g>
							<rect
								id="gra5_b-15"
								data-name="gra5_b"
								x="496.501"
								y="379.294"
								width="11.051"
								height="84.122"
								rx="1.949"
								fill={SECONDARY}
								opacity="0.5"
							/>
							<rect
								id="gra5_b-16"
								data-name="gra5_b"
								x="485.45"
								y="408.36"
								width="11.051"
								height="55.056"
								rx="1.949"
								fill={SECONDARY}
							/>
						</g>
					</g>
					<path
						d="M551.438,296.152a25.507,25.507,0,0,1-22.2-38l60.781-108.11a25.5,25.5,0,1,1,44.455,24.994l-60.781,108.11A25.492,25.492,0,0,1,551.438,296.152Z"
						fill={SECONDARY}
					/>
					<line
						x1="588.771"
						y1="256.319"
						x2="587.862"
						y2="237.884"
						fill="none"
						stroke="#0c0c0c"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.75"
					/>
					<path
						d="M662.468,207.463l-15.727-18.409a3.832,3.832,0,0,0-5.555-.286,3.977,3.977,0,0,0-.13,5.428l3.361,3.934L661.23,210.9Z"
						fill="#E98862"
					/>
					<path
						d="M637.8,212.316l-49.659,3.094a8.075,8.075,0,0,1-8.467-6.727L567.405,135.4a8.077,8.077,0,0,1,7.463-9.394l49.659-3.094a8.076,8.076,0,0,1,8.467,6.728l12.266,73.282A8.077,8.077,0,0,1,637.8,212.316Z"
						fill="#292a2e"
					/>
					<g>
						<path
							d="M644.416,198.13l-16.3-7.546c-1.8-.87-4.562-.788-5.405,1.293a3.827,3.827,0,0,0,1.05,4.648.307.307,0,0,0-.035.051.313.313,0,0,0-.01.044l-2.773-1.343a3.834,3.834,0,0,0-5.382,2.494,3.923,3.923,0,0,0,2.171,4.464l1.181.572a.336.336,0,0,0,.02.126,3.829,3.829,0,0,0-4.379,2.249,3.977,3.977,0,0,0,2.059,5.024l2.9,1.4a.357.357,0,0,0-.26.193.35.35,0,0,0-.022.206,3.822,3.822,0,0,0-3.527,2.856,3.924,3.924,0,0,0,2.171,4.465L642.6,231.3a19.294,19.294,0,0,0,21.353-3.059l9.175-8.3Z"
							fill="#E98862"
						/>
						<g opacity="0.2">
							<path
								d="M639.857,204.077a.354.354,0,0,1-.466.164l-15.5-7.2a.353.353,0,1,1,.3-.641l15.5,7.195a.354.354,0,0,1,.172.47Z"
								fill="#0c0c0c"
							/>
						</g>
						<g opacity="0.2">
							<path
								d="M636.872,211a.354.354,0,0,1-.466.165l-17.292-8.03a.355.355,0,0,1-.172-.47.367.367,0,0,1,.47-.172l17.292,8.03a.355.355,0,0,1,.172.47Z"
								fill="#0c0c0c"
							/>
						</g>
						<g opacity="0.2">
							<path
								d="M633.683,218.513a.354.354,0,0,1-.466.165l-13.794-6.406a.353.353,0,1,1,.3-.641l13.794,6.405a.355.355,0,0,1,.171.47Z"
								fill="#0c0c0c"
							/>
						</g>
					</g>
					<path
						d="M643.415,238.4l107.257,52.612a26.509,26.509,0,0,0,36.8-30.531l-28.64-102.969a26.51,26.51,0,0,0-32.163-19.263c-14.2,3.563-21.825,17.962-18.262,32.163l22.47,55.675-68.728-28.816Z"
						fill={SECONDARY}
					/>
					<path
						d="M743.615,288.124a.358.358,0,0,1-.094-.031l-100.26-49.375a.354.354,0,0,1-.168-.464l18.732-41.129a.351.351,0,0,1,.459-.18l67.05,27.53-13.27-39.967a.353.353,0,1,1,.68-.193l13.47,40.675a.354.354,0,0,1-.478.423l-67.41-27.683-18.446,40.5,99.949,49.224a.354.354,0,0,1-.214.667Z"
						fill="#0c0c0c"
					/>
					<path
						d="M741.9,230.836a.383.383,0,0,1-.084-.025l-12.084-5.4a.353.353,0,1,1,.288-.645l12.085,5.4a.353.353,0,0,1-.2.67Z"
						fill="#0c0c0c"
					/>
					<g>
						<path
							d="M605.284,158.692a3.823,3.823,0,0,0-4.432-1.156.293.293,0,0,0-.429-.226l2.085-2.52a3.966,3.966,0,0,0-.128-5.317,3.829,3.829,0,0,0-5.017-.321c0-.038-.036-.066-.051-.1l.853-1.031a3.925,3.925,0,0,0,.143-4.936,3.836,3.836,0,0,0-5.955-.058l-1.97,2.38c-.011-.012-.009-.031-.022-.042s-.04-.009-.058-.02a3.828,3.828,0,0,0-.971-4.677c-1.633-1.542-4.18-.468-5.453,1.072l-12.038,14.285.346-.646,1.427-4.992a3.967,3.967,0,0,0-2.251-4.819,3.833,3.833,0,0,0-5.062,2.523l-6.653,23.28-1.615,5.653a10.834,10.834,0,0,0,7.145,13.305l1.758.557a19.294,19.294,0,0,0,20.692-6.093l17.513-21.166A3.922,3.922,0,0,0,605.284,158.692Z"
							fill="#E98862"
						/>
						<g opacity="0.2">
							<path
								d="M578.806,158.892a.354.354,0,0,0,.492-.044l11.1-12.985a.354.354,0,1,0-.537-.46l-11.1,12.985a.354.354,0,0,0,.039.5Z"
								fill="#0c0c0c"
							/>
						</g>
						<g opacity="0.2">
							<path
								d="M584.4,163.946a.354.354,0,0,0,.492-.044l12.39-14.491a.354.354,0,0,0-.039-.5.367.367,0,0,0-.5.039l-12.389,14.491a.354.354,0,0,0,.039.5Z"
								fill="#0c0c0c"
							/>
						</g>
						<g opacity="0.2">
							<path
								d="M590.423,169.455a.354.354,0,0,0,.492-.044l9.883-11.559a.354.354,0,1,0-.537-.46l-9.884,11.56a.353.353,0,0,0,.04.5Z"
								fill="#0c0c0c"
							/>
						</g>
					</g>
					<path
						d="M597.365,184.058,577.631,272.8a26.509,26.509,0,0,1-52.34-8.458l27.095-94.809"
						fill={SECONDARY}
					/>
					<line
						x1="579.166"
						y1="256.319"
						x2="590.293"
						y2="215.443"
						fill="none"
						stroke="#0c0c0c"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="0.75"
					/>
					<path
						d="M767.177,775.221H71.048a1,1,0,0,1,0-2H767.177a1,1,0,1,1,0,2Z"
						fill="#292a2e"
					/>
				</g>
			</svg>
		</Box>
	);
}
