// @mui
import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
// components
import { IconButtonAnimate } from "../../../components/animate";

// ----------------------------------------------------------------------

type Props = {
	onToggleCollapse: VoidFunction;
	collapseClick: boolean;
};

export default function CollapseButton({
	onToggleCollapse,
	collapseClick,
}: Props) {
	const sizeIcon = 32;
	const sizePadding = "8px";

	return (
		<IconButtonAnimate
			onClick={onToggleCollapse}
			size="large"
			sx={{
				padding: sizePadding,
				marginRight: "5px",
				"&:hover": {
					backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.2),
				},
			}}
		>
			<Box
				sx={{
					lineHeight: 0,
					height: sizeIcon,
					width: sizeIcon,
					transition: (theme) =>
						theme.transitions.create("transform", {
							duration: theme.transitions.duration.shorter,
						}),
					...(collapseClick && {
						transform: "rotate(180deg)",
					}),
				}}
			>
				{icon}
			</Box>
		</IconButtonAnimate>
	);
}

// ----------------------------------------------------------------------

const icon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 24 24"
	>
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h24v24H0z" />
			<g fill="white" fillRule="evenodd">
				<path
					d="M14.3283 11.4343 18.5126 7.25c.4142-.4142.4142-1.0858 0-1.5-.4142-.4142-1.0858-.4142-1.5 0l-5.543 5.5429c-.3904.3905-.3904 1.0237 0 1.4142l5.543 5.5429c.4142.4142 1.0858.4142 1.5 0 .4142-.4142.4142-1.0858 0-1.5l-4.1843-4.1843a.8.8 0 0 1 0-1.1314Z"
					opacity=".48"
				/>
				<path d="M8.3283 11.4343 12.5126 7.25c.4142-.4142.4142-1.0858 0-1.5-.4142-.4142-1.0858-.4142-1.5 0l-5.543 5.5429c-.3904.3905-.3904 1.0237 0 1.4142l5.543 5.5429c.4142.4142 1.0858.4142 1.5 0 .4142-.4142.4142-1.0858 0-1.5l-4.1843-4.1843a.8.8 0 0 1 0-1.1314Z" />
			</g>
		</g>
	</svg>
);
