import { intersection } from "lodash";
import {
	type ArraySiteFilters,
	type FilterDetails,
	type NumberSiteFilters,
	type SimpleSiteFilters,
	type SiteFilters,
	arraySiteFilters,
	isOneOfItem,
	type metadataSchema,
	numberSiteFilters,
	type oneOfItem,
	simpleSiteFilters,
} from "src/@types/metadataSchema";
import type { SiteItemCustomerChild } from "src/@types/sites";
import { t } from "ttag";
import getPropertyFromSchema from "./getPropertyFromSchema";

export const getFilterDetails = (
	filterType: keyof SiteFilters,
	siteSchema?: metadataSchema,
) => {
	let details: FilterDetails = { title: filterType, placeholder: "" };
	// label & country are not in siteSchema
	if (filterType === "label")
		details = { title: t`Sites`, placeholder: t`Ex: Site 1` };
	else if (filterType === "country_code")
		details = { title: t`Country`, placeholder: t`Ex: France` };
	else if (siteSchema) {
		const property = getPropertyFromSchema(siteSchema, filterType);
		if (property) {
			details = {
				title: property.title,
				placeholder: property.placeholder,
				options: (arraySiteFilters as string[]).includes(filterType)
					? property.items.anyOf
					: property.oneOf,
			};
			if (property.unit) details.unit = property.unit;
		}
	}
	return details;
};

export const filtersSites = (
	sites: SiteItemCustomerChild[],
	filters: SiteFilters,
) => {
	return sites.filter((site) => {
		const simpleFiltersTest = simpleSiteFilters.every((filterType) => {
			const filter = (filters as SimpleSiteFilters)[filterType];
			let siteValue = site[filterType];

			// transform null values to empty string because an option with enum = [""] is added to get these empty data sites
			if (siteValue === null) siteValue = "";

			if (filter.length === 0) {
				// filter === [] means there are no filters on this filterType
				return true;
			} else if (filter.some((item: any) => typeof item === "string")) {
				return (
					siteValue !== null &&
					typeof siteValue === "string" &&
					(filter as string[]).includes(siteValue)
				);
			} else if (filter.some((item: any) => isOneOfItem(item))) {
				return (
					siteValue !== null &&
					typeof siteValue === "string" &&
					(filter as oneOfItem[])
						.map((item) => item.enum[0])
						.includes(siteValue)
				);
			}
		});
		const numberFiltersTest = numberSiteFilters.every((filterType) => {
			const siteValue = site[filterType];
			const filter = (filters as NumberSiteFilters)[filterType];
			// filter.length !== 2 means there are no filters on this filterType: whether [] or impossible value to have
			return filter.length === 2
				? siteValue !== null && siteValue >= filter[0] && siteValue <= filter[1]
				: true;
		});
		const arrayFiltersTest = arraySiteFilters.every((filterType) => {
			const filter = (filters as ArraySiteFilters)[filterType];
			let siteValue = site[filterType];

			// transform [] values to [""] because an option with enum = [""] is added to get these empty data sites
			if (siteValue.length === 0) siteValue = [""];

			if (filter.length === 0) {
				// filter === [] means there are no filters on this filterType
				return true;
			} else {
				return (
					intersection(
						siteValue,
						filter.map((item) => item.enum[0]),
					).length > 0
				);
			}
		});
		return numberFiltersTest && simpleFiltersTest && arrayFiltersTest;
	});
};
