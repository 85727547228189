// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components

import { isArray } from "lodash";
import { type Params, generatePath, useLocation } from "react-router";
import type { NavListProps } from "src/components/nav-section/type";
import { brandSetting } from "src/config";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { ICONS } from "src/utils/icon";
import { t } from "ttag";
// ----------------------------------------------------------------------

const icons = ICONS();

const items = (params: Readonly<Params<string>>): NavListProps[] => {
	const { Site } = useSiteContext();
	const location = useLocation();
	const { MultiSites, MultisiteAlertsAccess, MultisitesCount } =
		useNodesContext();

	const paramsWithDefault: Params<string> = {
		...params,
		installation: "default",
	};

	const paramsForCustomer: Params<string> = {
		...params,
	};

	const hasNode: boolean = Boolean(paramsWithDefault?.node);
	const hasCustomer: boolean =
		hasNode && !MultiSites
			? false
			: Boolean(paramsForCustomer?.customer) ||
				Boolean(
					(MultiSites !== ({} as any) &&
						!isArray(MultiSites) &&
						MultiSites?.type !== "site") ||
						(isArray(MultiSites) && location.pathname.includes("all")),
				);

	const DashboardRoot: string = hasNode
		? generatePath(PATH_DASHBOARD.dashboard_with_node, {
				...paramsWithDefault,
				installation: "default",
			})
		: PATH_DASHBOARD.dashboard;
	const AnalysisRoot: string = hasNode
		? generatePath(PATH_DASHBOARD.analysis.chart, {
				node: paramsWithDefault.node
					? paramsWithDefault.node
					: Site?.uuid || null,
				installation: Site?.default_installation
					? Site?.default_installation
					: "default",
			})
		: PATH_DASHBOARD.analysis.root;

	const hasAlertsAccess = hasNode
		? Site
			? Site.has_alerts_access
			: false
		: MultisiteAlertsAccess
			? MultisiteAlertsAccess
			: false;

	const rows: NavListProps[] = [
		{
			title: t`Dashboard`,
			path: DashboardRoot,
			icon: icons.dashboard,
			samePathIfInside: hasNode ? hasNode : false,
			id: "dashboard",
		},
		{
			title: t`Analysis`,
			path: AnalysisRoot,
			icon: icons.analytics,
			samePathIfInside: !hasNode,
			id: "analysis",
		},
	];
	if (brandSetting.withAlerts && hasAlertsAccess) {
		if (MultisitesCount && MultisitesCount > 800) return [];
		rows.splice(2, 0, {
			title: t`Alerts`,
			path: hasNode
				? generatePath(
						PATH_DASHBOARD.alerts.notifications_with_node,
						paramsWithDefault,
					)
				: Boolean(paramsForCustomer?.customer)
					? generatePath(PATH_DASHBOARD.alerts.notifications_with_customer, {
							...paramsForCustomer,
						})
					: PATH_DASHBOARD.alerts.notifications_with_node,
			icon: icons.alerts,
			id: "alerts",
		});
	}

	if (Site?.can_view_metadata) {
		rows.push({
			title: t`Site information`,
			path: hasNode
				? generatePath(PATH_DASHBOARD.general.site_info_with_node, params)
				: PATH_DASHBOARD.general.site_info,
			icon: icons.site,
			samePathIfInside: hasNode,
			id: "site_info",
		});
	}

	if (!paramsWithDefault?.node && paramsForCustomer?.customer && hasCustomer) {
		rows.splice(
			0,
			1,
			{
				title: t`Dashboard`,
				path: Boolean(paramsForCustomer?.customer)
					? generatePath(PATH_DASHBOARD.dashboard_customer, {
							...paramsForCustomer,
						})
					: PATH_DASHBOARD.dashboard_customer,
				icon: icons.dashboard,
				samePathIfInside: hasCustomer ? hasCustomer : false,
				id: "dashboard multisite",
			},
			{
				title: t`My Sites`,
				path: Boolean(paramsForCustomer?.customer)
					? generatePath(PATH_DASHBOARD.dashboard_table_with_customer, {
							...paramsForCustomer,
						})
					: PATH_DASHBOARD.dashboard_table_with_customer,
				icon: icons.mapPinLine,
				samePathIfInside: hasCustomer ? hasCustomer : false,
				id: "dashboard Table multisite",
			},
			// { title: t`Map`, path: Boolean(paramsForCustomer?.customer) ? generatePath(PATH_DASHBOARD.dashboard_map_with_customer, { ...paramsForCustomer }) : PATH_DASHBOARD.dashboard_map_with_customer, icon: icons.mapPinLine, samePathIfInside: hasCustomer ? hasCustomer : false, id: "dashboard Map multisite" },
		);
		return rows.filter(
			(item) => item.id !== "analysis" && item.id !== "site_info",
		);
	}
	return rows;
};

// function so that translation works
const navConfig = (params: Readonly<Params<string>>) => {
	return [
		// Analyser
		// ----------------------------------------------------------------------
		{
			subheader: t`Analyse`,
			items: items(params).concat(
				process.env.NODE_ENV === "production"
					? []
					: [
							{
								title: t`Actions plan`,
								path: PATH_DASHBOARD.general.actions,
								icon: icons.actions,
								id: "actions",
							},
							// { title: t`Alerts`, path: params?.node ? generatePath(PATH_DASHBOARD.alerts.notifications_with_node, params) : PATH_DASHBOARD.alerts.notifications, icon: icons.alerts },
							{
								title: t`Reports`,
								path: PATH_DASHBOARD.reports.notifications,
								icon: icons.reports,
								id: "reports",
							},
							// { title: t`Site information`, path: PATH_DASHBOARD.general.info, icon: icons.site, id:'siteInformation' },
							{
								title: t`Metering plan`,
								path: PATH_DASHBOARD.general.meteringplan,
								icon: icons.meteringplan,
								id: "meteringPlan",
							},
						],
			),
		},
	].concat(
		process.env.NODE_ENV === "production"
			? []
			: [
					// Gérer
					// ----------------------------------------------------------------------
					{
						subheader: t`Manage`,
						items: [
							{
								title: t`Users`,
								path: PATH_DASHBOARD.users.list,
								icon: icons.users,
								id: "users",
							},
							{
								title: t`Projects followup`,
								path: PATH_DASHBOARD.general.projects,
								icon: icons.projects,
								id: "projects",
							},
							{
								title: t`Issues followup`,
								path: PATH_DASHBOARD.general.issues,
								icon: icons.issues,
								id: "issues",
							},
							{
								title: t`Export`,
								path: PATH_DASHBOARD.export.export,
								icon: icons.exports,
								id: "export",
							},
							// { title: t`API`, path: PATH_DASHBOARD.general.api, icon: icons.api },
						],
					},
				],
	);
};

export default navConfig;
