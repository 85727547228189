import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAuth from "src/hooks/useAuth";
import { t } from "ttag";

// ----------------------------------------------------------------------

export function AuthSyncDialog() {
	const { isAuthenticated, needsReload } = useAuth();

	if (!needsReload) return null;

	return (
		<Dialog
			open={true}
			onClose={() => {}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t`The page needs to be reloaded`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{isAuthenticated
						? t`We have detected a disconnection from another tab.`
						: t`We have detected a connection from another tab.`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => {
						window.location.reload();
					}}
				>
					{t`Reload`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
