import type ColumnTable from "arquero/dist/types/table/column-table";
import { cloneDeep, groupBy, isArray, reduce } from "lodash";
import type { APIGraphSeries, Point } from "src/@types/graphSeries";
import { Frequency, startOfPeriodTimestamp } from "src/utils/timeFormatter";

export type PeriodData = {
	EnergyDay: APIGraphSeries[];
	EnergyWeek: APIGraphSeries[];
	EnergyMonth: APIGraphSeries[];
	EnergyYear: APIGraphSeries[];
};

export function isPeriodData(variable: any): variable is PeriodData {
	return (
		variable instanceof Object &&
		variable.EnergyDay !== undefined &&
		variable.EnergyWeek !== undefined &&
		variable.EnergyMonth !== undefined &&
		variable.EnergyYear !== undefined &&
		Object.values(variable).every((value) => isArray(value))
	);
}

export type PeriodDataTable = {
	EnergyDay: ColumnTable | null;
	EnergyWeek: ColumnTable | null;
	EnergyMonth: ColumnTable | null;
	EnergyYear: ColumnTable | null;
	EnergyTotal: ColumnTable | null;
};

export function isPeriodDataTable(variable: any): variable is PeriodDataTable {
	return (
		variable instanceof Object &&
		variable.EnergyDay !== undefined &&
		variable.EnergyWeek !== undefined &&
		variable.EnergyMonth !== undefined &&
		variable.EnergyYear !== undefined &&
		variable.EnergyTotal !== undefined &&
		Object.values(variable).every(
			(value) => value === null || typeof value === "object",
		)
	);
}

export type ChartDataByPeriod = {
	Electricity?: PeriodData | PeriodDataTable;
	Temperature?: PeriodData;
	DegreeDay?: PeriodData;
};

export const sumData = (data: Point[]) => {
	const initialValue: number = 0;
	return data
		.map((datum) => (datum ? datum[1] : 0))
		.reduce(
			(previousValue: number, currentValue: number) =>
				previousValue + currentValue,
			initialValue,
		);
};

export const aggregateCategory = (
	data: Point[],
	frequency: Frequency,
	timezone: string,
	aggregator: "sum" | "mean",
) => {
	// the timestamp in Point is a unix timestamp

	const groupedEnergy = groupBy(
		data,
		(point) =>
			point !== null && startOfPeriodTimestamp(point[0], timezone, frequency),
	);

	delete groupedEnergy.false;
	const aggregation = reduce(
		groupedEnergy,
		(result, value, key) => {
			const aggregatedVal = value.reduce((previous, current) => {
				switch (aggregator) {
					case "sum":
						return previous + (current !== null ? current[1] : 0);
					case "mean":
						return (
							previous + (current !== null ? current[1] : 0) / value.length
						);
				}
			}, 0);
			// aggregatedVal = parseFloat(aggregatedVal.toFixed(1));
			result.push([Number.parseInt(key), aggregatedVal]);
			return result;
		},
		[] as Point[],
	);
	return aggregation;
};

export const getAggregatedDataFreq = (
	periodData: PeriodData,
	category: APIGraphSeries,
	freq: Frequency,
	timezone: string,
	chartType: keyof ChartDataByPeriod,
) => {
	// apply aggregation for a particular category and frequency
	const copyCategory = cloneDeep(category);
	copyCategory.data = aggregateCategory(
		category.data,
		freq,
		timezone,
		chartType === "Temperature" ? "mean" : "sum",
	);

	switch (freq) {
		case Frequency.Week:
			periodData.EnergyWeek.push(copyCategory);
			break;
		case Frequency.Month:
			periodData.EnergyMonth.push(copyCategory);
			break;
		case Frequency.Year:
			periodData.EnergyYear.push(copyCategory);
			break;
	}
};

export const getAggregatedData = (
	data: APIGraphSeries[],
	chartType: keyof ChartDataByPeriod,
	timezone: string,
	periodFilter?: keyof PeriodData,
) => {
	// apply aggregation on all categories in data
	const periodData: PeriodData = {
		EnergyDay: [],
		EnergyWeek: [],
		EnergyMonth: [],
		EnergyYear: [],
	};
	data.map((category) => {
		periodData.EnergyDay.push(category);
		if (periodFilter) {
			let freq = Frequency.Day;
			switch (periodFilter) {
				case "EnergyWeek":
					freq = Frequency.Week;
					break;
				case "EnergyMonth":
					freq = Frequency.Month;
					break;
				case "EnergyYear":
					freq = Frequency.Year;
					break;
			}
			getAggregatedDataFreq(periodData, category, freq, timezone, chartType);
		} else {
			[Frequency.Week, Frequency.Month, Frequency.Year].map((freq) => {
				getAggregatedDataFreq(periodData, category, freq, timezone, chartType);
			});
		}
	});
	return periodData;
};

export const getPeriodColumn = (periodType: keyof PeriodDataTable) => {
	switch (periodType) {
		case "EnergyDay":
			return "date";
		case "EnergyWeek":
			return "week";
		case "EnergyMonth":
			return "month";
		case "EnergyYear":
			return "year";
		case "EnergyTotal":
			return "total";
	}
};
