import { captureMessage } from "@sentry/browser";
import type { CaptureContext } from "@sentry/types";
import { isArray, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import { type ReactNode, createContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import type { SiteContent } from "src/@types/SiteContent";
import type { Category } from "src/@types/magic";
import type { metadataHistory } from "src/@types/metadataHistory";
import type { metadataSchema } from "src/@types/metadataSchema";
import type { SiteItem } from "src/@types/sites";
import { BENCHMARK_VENTILATION_ID } from "src/config";
import useAuth from "src/hooks/useAuth";
import useNodesContext from "src/hooks/useNodesContext";
import { PATH_DASHBOARD, PATH_PAGE } from "src/routes/paths";
import { t } from "ttag";
import {
	getCommonModels,
	getMetadataHistory,
	getSite,
} from "../services/service";

type SiteProviderProps = { children: ReactNode };

export type SiteContextProps = {
	Site: SiteContent | undefined;
	siteLoading: boolean;
	siteSchema: metadataSchema | undefined;
	installationSchema: metadataSchema | undefined;
	macroCategories: Category[] | undefined;
	metadataUpdates: Array<metadataHistory>;
	metadataUpdatesLoading: boolean;
	reloadSite: () => void;
};
export type MultiSiteContextProps = SiteContextProps[];

const initialState: SiteContextProps = {
	Site: undefined,
	siteLoading: false,
	siteSchema: undefined,
	installationSchema: undefined,
	macroCategories: undefined,
	metadataUpdates: [],
	metadataUpdatesLoading: false,
	reloadSite: () => {},
};

const SiteSelectContext = createContext(initialState);

const pendingRequests = new Set<string>();

function SiteProvider({ children }: SiteProviderProps) {
	const { Sites, MultiSites, nodesLoaded } = useNodesContext();
	const { isAuthenticated, doLogout } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const params = useParams();
	const navigate = useNavigate();

	// const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			getCommonModels()
				.then((response) => {
					let macrocategories = response.data.macrocategories;
					macrocategories = macrocategories.map((mc: Category) =>
						mc.id === BENCHMARK_VENTILATION_ID
							? { ...mc, label: t`Ventilation & HVAC auxiliaries` }
							: mc,
					);
					setSiteState((state) => ({
						...state,
						siteSchema: response.data.site_schema,
						installationSchema: response.data.installation_schema,
						macroCategories: macrocategories,
					}));
				})
				.catch((error) => {
					console.error("Error when loading metadataSchema", error);
				});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (!nodesLoaded) return;
		else {
			const walkTree = (func: (item: SiteItem) => void, tree: SiteItem[]) => {
				tree.forEach((item: SiteItem) => {
					if (item.type === "site") {
						func(item);
					} else if (item.children !== undefined) {
						walkTree(func, item.children as SiteItem[]);
					}
				});
			};

			// find all site uuids, to check that the stored site is in the list
			const site_uuids: string[] = [];
			walkTree((item: SiteItem) => {
				site_uuids.push(item.uuid);
			}, Sites as SiteItem[]);

			if (site_uuids.length === 0) {
				captureMessage("Empty list of sites", {
					sites: JSON.stringify(Sites),
				} as CaptureContext);
				navigate(PATH_PAGE.empty);
				return;
			}

			const saved_site = localStorage.getItem("site");
			const saved_multisite = localStorage.getItem("multisite");

			const demo_site_uuid = "b746f728-6aef-4e14-a147-547a6c26c5df";
			let change_site = false;
			let uuid;
			const site: Partial<SiteItem> | null = saved_site
				? JSON.parse(saved_site)
				: null;
			const multisite: Partial<SiteItem> | null = saved_multisite
				? JSON.parse(saved_multisite)
				: null;

			if (params.node && site_uuids.includes(params.node)) {
				// Site UUID from URL
				// console.log('Load site from URL')
				uuid = params.node;
				// console.log(uuid, "BUG ICI UUIIIID")
			} else if (
				!params.customer &&
				!multisite &&
				site &&
				site.uuid &&
				site_uuids.includes(site.uuid)
			) {
				// Site UUID from localStorage
				// console.log('Load site from localstorage')
				uuid = site.uuid;
				change_site = true;
			} else if (
				!params.customer &&
				!params.node &&
				!multisite &&
				site_uuids.includes(demo_site_uuid)
			) {
				// console.log('Load site from localstorage')
				uuid = demo_site_uuid;
				change_site = true;
			} else if (!params.customer && multisite && multisite.uuid) {
				// Site UUID from localStorage
				// console.log('Load site from localstorage')
				if (multisite)
					navigate(
						generatePath(PATH_DASHBOARD.dashboard_customer, {
							customer: multisite.uuid,
						}),
					);
			} else if (!params.customer && !uuid) {
				// console.log(params.node, params.customer, MultiSites, 'bug ici ? all')

				let node: any = null;
				walkTree((item: SiteItem) => {
					if (node === null) node = item;
				}, Sites as SiteItem[]);

				if (node as SiteItem | null) {
					// console.log('Load first site')
					uuid = node.uuid;
					change_site = true;
					// console.log(uuid, "BUG ICI UUIIIID")
				}
				// }
			}

			if (params.node && change_site && !params.installation) {
				// No snackbar if there was no node in the URL (/dashboard for example)
				// translator: Le point de mesure demandé n'existe pas.
				enqueueSnackbar(t`This measuring point does not exist.`, {
					variant: "info",
				});
			}

			if (!params.customer && uuid && uuid !== SiteState.Site?.uuid) {
				const controller = new AbortController();
				loadSite(uuid, controller.signal);

				// return clean-up function that is called on unmount
				return () => {
					controller.abort();
				};
			}

			if (
				params.customer &&
				MultiSites &&
				((isArray(MultiSites) && params.customer === "all") ||
					(!isArray(MultiSites) && MultiSites.uuid === params.customer))
			) {
				if (
					(params.customer !== "all" &&
						!isArray(MultiSites) &&
						(MultiSites.type === "customer" ||
							MultiSites.type === "site_group")) ||
					(params.customer === "all" && isArray(MultiSites))
				) {
					// setIsLoaded(true)
					setSiteState((state) => ({ ...state }));

					// localStorage.removeItem('site')
					const MultiSiteStateLabel = isArray(MultiSites)
						? t`All my sites`
						: MultiSites.label;
					const MultiSiteStateUUID = isArray(MultiSites)
						? "all"
						: MultiSites.uuid;
					localStorage.setItem(
						"multisite",
						JSON.stringify({
							uuid: MultiSiteStateUUID,
							label: MultiSiteStateLabel,
						}),
					);
					localStorage.getItem("site") && localStorage.removeItem("site");
					const getAllUuids = (items: SiteItem | SiteItem[]): string[] => {
						let uuids: string[] = [];
						([] as SiteItem[]).concat(items).map((item) => {
							if (item.type !== "site" && item.children) {
								uuids = uuids.concat(getAllUuids(item.children as SiteItem[]));
							} else uuids.push(item.uuid);
						});
						return uuids;
					};
					const getAllUuidsMultisite = getAllUuids(MultiSites);
					const controller = new AbortController();
					if (
						SiteState.Site === undefined ||
						!getAllUuidsMultisite.includes(SiteState.Site.uuid)
					)
						loadSite(getAllUuidsMultisite, controller.signal, MultiSites);

					// return clean-up function that is called on unmount
					return () => {
						controller.abort();
					};
				}
			}
		}
	}, [nodesLoaded, params, MultiSites]);

	const loadSite = (
		uuid: string | string[],
		abort_signal: AbortSignal,
		multiSites?: any,
	) => {
		// console.log('loadSite', { uuid, state: SiteState.Site?.uuid, url: params.node })
		try {
			const requestId = uniqueId();
			setSiteState((state) => ({ ...state, siteLoading: true }));
			//  Smart Impulse rest api
			// console.log(pendingRequests, 'pendingRequests')
			if (isArray(uuid)) {
				const MultiSiteStateLabel = isArray(multiSites)
					? t`All my sites`
					: multiSites.label;
				const MultiSiteStateUUID = isArray(multiSites)
					? "all"
					: multiSites.uuid;
				localStorage.setItem(
					"multisite",
					JSON.stringify({
						uuid: MultiSiteStateUUID,
						label: MultiSiteStateLabel,
					}),
				);
				localStorage.getItem("site") && localStorage.removeItem("site");
				setSiteState((state) => ({ ...state, siteLoading: false }));
			} else {
				pendingRequests.add(requestId);
				getSite(uuid, abort_signal).then(
					(site) => {
						pendingRequests.delete(requestId);
						// console.log(site, 'inside reload')

						setSiteState((state) => ({
							...state,
							Site: site,
							siteLoading: pendingRequests.size > 0,
						}));
						localStorage.setItem(
							"site",
							JSON.stringify({ uuid: site.uuid, label: site.label }),
						);
						localStorage.getItem("multisite") &&
							localStorage.removeItem("multisite");
					},
					(error_response: any) => {
						/// - si code 403 -> rediriger vers le formulaire de login -> puis connection
						if (error_response.status === 403) {
							// not supposed to happen with the BroadcastChannel("AuthenticatedStatus"),
							// except on browsers that don't implement BroadcastChannel (mobile browsers except Firefox)
							enqueueSnackbar(
								t`You have been disconnected from another page.`,
								{ variant: "warning", autoHideDuration: 15000 },
							);
							doLogout().then(
								() => {},
								() => {},
							);
						} else if (
							error_response.code === "ERR_CANCELED" ||
							error_response.message === "canceled" ||
							error_response.name === "CanceledError"
						) {
							// the abort controller did its job
						} else {
							console.warn("Different error response from getSite", uuid);
						}
						pendingRequests.delete(requestId);
						setSiteState((state) => ({
							...state,
							siteLoading: pendingRequests.size > 0,
						}));
					},
				);
				getMetadataHistory(uuid)
					.then((response) => {
						setSiteState((state) => ({
							...state,
							metadataUpdates: response,
							metadataUpdatesLoading: false,
						}));
					})
					.catch((error) => {
						console.error("Error when loading metadataHistory", error);
					});
			}
		} catch (err) {
			console.error("loadSite", err);
		}
	};

	const reloadSite = () => {
		/* Bypass loadSite, to do a silent reload of the current site */
		if (!SiteState.Site?.uuid) return;

		getSite(SiteState.Site?.uuid).then(
			(site) => {
				setSiteState((state) => ({ ...state, Site: site }));
				localStorage.setItem(
					"site",
					JSON.stringify({ uuid: site.uuid, label: site.label }),
				);
				localStorage.getItem("multisite") &&
					localStorage.removeItem("multisite");
			},
			(_error_response: any) => {},
		);

		getMetadataHistory(SiteState.Site?.uuid)
			.then((response) =>
				setSiteState((state) => ({ ...state, metadataUpdates: response })),
			)
			.catch((error) => {
				console.error("Error when loading metadataHistory", error);
			});
	};

	const [SiteState, setSiteState] = useState<SiteContextProps>(initialState);

	// console.log(SiteState,'GET SITE BUG')

	return (
		<SiteSelectContext.Provider
			value={{
				...SiteState,
				reloadSite,
			}}
		>
			{children}
		</SiteSelectContext.Provider>
	);
}
export { SiteProvider, SiteSelectContext };
