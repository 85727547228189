import { matchPath } from "react-router-dom";

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from "./vertical";
export { default as NavSectionHorizontal } from "./horizontal";

export function isExternalLink(path: string) {
	return path.includes("http");
}

export function getActive(path: string, pathname: string) {
	path = path.match(/[^/]*\/[^/]*/) ? path.match(/[^/]*\/[^/]*/)![0] : "";
	const patternPath = pathname.match(/[^/]*\/[^/]*/)
		? pathname.match(/[^/]*\/[^/]*/)![0]
		: "";

	if (path === "/table" && patternPath === "/map") {
		return true;
	} else
		return patternPath
			? !!matchPath(
					{ path: patternPath, end: true, caseSensitive: true },
					path || patternPath,
				)
			: !!matchPath(
					{ path: pathname, end: true, caseSensitive: true },
					path || patternPath,
				);
}
