import {
	Button,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import type { StepType } from "@reactour/tour";
import { useNavigate } from "react-router";
import LaunchIllustration from "src/assets/illustrations/Launch";
import { FeedbackContent } from "src/components/others/FeedbackForm";
import useAuth from "src/hooks/useAuth";
import useNodesContext from "src/hooks/useNodesContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import { updateLastTourDate } from "src/services/service";
import { goToWithPeriod } from "src/utils/links";
import { t } from "ttag";
import type { ContentProps } from "./appTour";

export const MULTISITE_DATE =
	process.env.NODE_ENV === "development" ||
	window.location.host.includes("review-apps")
		? "2024-05-03T00:00:00.000Z"
		: "2024-05-06T00:00:00.000Z";

export function updatesTourMultisite(): StepType[] {
	// const { user } = useAuth();
	// const { Site } = useSiteContext();
	const { user } = useAuth();

	// const params = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	// For Smart Impulse, redirection towards the demo sitegroup.

	const customerTour = user?.isSmartImpulse
		? process.env.NODE_ENV === "development" ||
			window.location.host.includes("review-apps")
			? "o6zSa"
			: "o2iMH"
		: "all";

	return [
		{
			selector: "#accountPopover-button",
			position: "center",
			mutationObservables: [
				"#nodeSelector-button",
				"#nodeSelector-content",
				".MuiBackdrop-root",
			],
			resizeObservables: [
				"#nodeSelector-button",
				"#nodeSelector-content",
				".MuiBackdrop-root",
			],
			content: (props: ContentProps) => (
				<Stack
					id="welcomeMessage"
					direction="column"
					sx={{
						textAlign: "center",
						alignItems: "center",
						gap: 2,
						" + div": { display: "none !important" },
						width: isMobile ? undefined : "293px",
					}}
				>
					<Typography
						variant="h5"
						component="h1"
						sx={{ whiteSpace: "break-spaces" }}
					>
						{t`Discover your \nmultisite dashboard!`}
					</Typography>
					<LaunchIllustration sx={{ width: "239px" }} />
					<Stack direction="row" sx={{ gap: 2, justifyContent: "center" }}>
						<Button
							variant="contained"
							color="error"
							onClick={() => {
								props.setIsOpen(false);
								user?.hijacked ? null : updateLastTourDate("last_period");
							}}
						>
							{t`No thanks`}
						</Button>
						<Button
							variant="contained"
							color="success"
							onClick={() => {
								props.setCurrentStep(props.currentStep + 1);
							}}
						>
							{t`Let's go !`}
						</Button>
					</Stack>
					<Button
						variant="text"
						sx={{ width: "fit-content" }}
						onClick={() => {
							props.setIsOpen(false);
						}}
					>
						{t`Remind me later.`}
					</Button>
				</Stack>
			),
		},
		{
			selector: "#nodeSelector-button",
			highlightedSelectors: ["#nodeSelector-button", "#nodeSelector-content"],
			mutationObservables: [
				"#nodeSelector-button",
				"#nodeSelector-content",
				".MuiBackdrop-root",
			],
			resizeObservables: [
				"#nodeSelector-button",
				"#nodeSelector-content",
				".MuiBackdrop-root",
			],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Navigation`}
					</Typography>
					<Typography
						variant="body1"
						component="p"
						sx={{ width: isMobile ? undefined : "293px" }}
					>
						{t`You can now select “All my sites” or a specific group of sites to access a dedicated multisite dashboard.`}
					</Typography>
				</Stack>
			),
			action: () => {
				navigate(
					goToWithPeriod(PATH_DASHBOARD.dashboard_customer, customerTour),
				);
				user?.hijacked ? null : updateLastTourDate("last_period");
			},
			// actionAfter: () => {
			//     waitClickId('dashboard');
			// },
		},
		{
			selector: "main",
			mutationObservables: ["#multisite-dashboard"],
			resizeObservables: ["#multisite-dashboard"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Multisite Dashboard`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`The Dashboard page offers a comprehensive overview of the consumption of your real estate portfolio. It simplifies your energy reporting and helps you identify priorities to reduce and master your consumption.`}
					</Typography>
				</Stack>
			),
			action: () => {
				document
					.querySelector("#multisite-dashboard")
					?.scrollIntoView({ behavior: "smooth" });
			},
		},
		{
			selector: "#statsCard",
			highlightedSelectors: ["#statsCard", "#energyEfficiencyCard"],
			resizeObservables: ["#statsCard", "#energyEfficiencyCard"],
			mutationObservables: ["#statsCard", "#energyEfficiencyCard"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Portfolio total consumption`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`Monitor the total consumption of your park and its average energy performance in kWh/m².`}
					</Typography>
				</Stack>
			),
			action: () => {
				// waitClickId('dashboard');
				// document.querySelector('#statsCard')?.scrollIntoView({ behavior: 'smooth' })
			},
		},
		{
			selector: "#benchmarkCard",
			highlightedSelectors: ["#benchmarkCard"],
			resizeObservables: ["#benchmarkCard"],
			mutationObservables: ["#benchmarkCard"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Sites comparison by end-use`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`Quickly compare your sites based on their total consumption and by end-use (kWh, kWh/m²).`}
					</Typography>
				</Stack>
			),
			action: () => {
				// waitClickId('dashboard');
				document
					.querySelector("#benchmarkCard")
					?.scrollIntoView({ behavior: "smooth" });
			},
		},
		{
			selector: "#energyCard",
			highlightedSelectors: ["#energyCard"],
			resizeObservables: ["#energyCard"],
			mutationObservables: ["#energyCard"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Portfolio consumption evolution`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`Visualise your portfolio consumption evolution over time, by site or by end-use.`}
					</Typography>
				</Stack>
			),
			action: () => {
				// waitClickId('dashboard');
				document
					.querySelector("#energyCard")
					?.scrollIntoView({ behavior: "smooth" });
			},
		},
		{
			selector: "#magicButtons",
			highlightedSelectors: ["#magicButtons"],
			resizeObservables: ["#magicButtons"],
			mutationObservables: ["#magicButtons"],
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`Advanced filters`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`Quickly filter your dashboard by dates or sites categories (countries, main activity, floor area….)`}
					</Typography>
				</Stack>
			),
			action: () => {
				navigate(
					goToWithPeriod(PATH_DASHBOARD.dashboard_customer, customerTour),
				);
				document
					.querySelector("#magicButtons")
					?.scrollIntoView({ behavior: "smooth" });
				// waitClickId('dashboard');
			},
		},
		{
			selector: "main",
			content: (_props: ContentProps) => (
				<Stack sx={{ width: isMobile ? undefined : "293px" }}>
					<Typography variant="h5" component="h1">
						{t`My sites`}
					</Typography>
					<Typography variant="body1" component="p">
						{t`Visualize the consumption and main characteristics of your sites in either table or map mode. You can export the data to Excel in one click.`}
					</Typography>
				</Stack>
			),
			action: () => {
				navigate(
					goToWithPeriod(
						PATH_DASHBOARD.dashboard_table_with_customer,
						customerTour,
					),
				);
				// waitClickId('dashboard');
			},
		},
		{
			selector: "#accountPopover-button",
			highlightedSelectors: ["#accountPopover-button"],
			resizeObservables: ["#accountPopover-button"],
			mutationObservables: ["#accountPopover-button"],
			position: "center",
			content: (props: ContentProps) => (
				<Stack
					direction="column"
					sx={{ gap: 3, " + div": { display: "none !important" } }}
				>
					<Typography variant="h5" component="h1">
						{t`Any other suggestions ?`}
					</Typography>
					<FeedbackContent mode="tour" setIsOpen={props.setIsOpen} />
				</Stack>
			),
			action: () => {
				navigate(
					goToWithPeriod(PATH_DASHBOARD.dashboard_customer, customerTour),
				);
				document
					.querySelector("#accountPopover-button")
					?.scrollIntoView({ behavior: "smooth" });
				// navigate(params.node && params.installation ? generatePath(PATH_DASHBOARD.dashboard_with_node, { node: params.node, installation: params.installation }) : PATH_DASHBOARD.dashboard)
			},
		},
	];
}
