import { type SelectedDays, SpecialDay } from "src/@types/SpecialDay";
import type { ElectricityTypes } from "src/@types/charts";
import {
	SelectedDaysTypes,
	allowedSpecialDays,
} from "src/contexts/ChartOptionsContext";
import { convertToSelectedDays, convertToWeekMask } from "./weekDays";

export const serializeSelectedDays = (days: SelectedDays): string => {
	switch (days) {
		case SpecialDay.OccupiedUnoccupiedDays:
			return SpecialDay.OccupiedUnoccupiedDays;
		case SpecialDay.OccupiedDays:
			return SpecialDay.OccupiedDays;
		case SpecialDay.UnoccupiedDays:
			return SpecialDay.UnoccupiedDays;
		default:
			return convertToWeekMask(days);
	}
};

export const parseSelectedDays = (
	val: string,
	chartType: ElectricityTypes,
): SelectedDays | null => {
	const special_day_validator = (val: SpecialDay) => {
		if (
			SelectedDaysTypes.includes(chartType) &&
			allowedSpecialDays(chartType).includes(val)
		) {
			return val;
		}
		return null;
	};

	switch (val) {
		case SpecialDay.OccupiedUnoccupiedDays:
			return special_day_validator(val);
		case SpecialDay.OccupiedDays:
			return special_day_validator(val);
		case SpecialDay.UnoccupiedDays:
			return special_day_validator(val);
		default:
			const parsed = convertToSelectedDays(val);
			return parsed.length ? parsed : null;
	}
};
