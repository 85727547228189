// strings since the value are cast to string in html attributes
export enum ElectricityTypes {
	Loadcurve = "Loadcurve",
	Power = "Power",
	EnergyDay = "EnergyDay",
	EnergyMonth = "EnergyMonth",
	EnergyWeek = "EnergyWeek",
	EnergyYear = "EnergyYear",
	EnergyDonut = "EnergyDonut",
	PluriannualEvolution = "Pluriannual Evolution",
	TypicalWeek = "TypicalWeek",
	TypicalDay = "TypicalDay",
	// TypicalCustom="TypicalCustom",
	CompareTypicalDay = "CompareTypicalDay",
	CompareTypicalWeek = "CompareTypicalWeek",
	CompareEnergy = "CompareEnergy",
	Benchmark = "Benchmark",
	QoE = "QoE",
	SubscribedPower = "SubscribedPower",
	KPI = "KPI",
}

// strings since the value are cast to string in html attributes
export enum TemperatureTypes {
	DegreeDay = "DegreeDay",
	Temperature = "Temperature",
	NoTemperature = "NoTemperature",
}

// strings since the value are cast to string in html attributes
export type ChartTypes = ElectricityTypes | TemperatureTypes;

export const ChartTypesUrl: { [key in ElectricityTypes]?: string } = {
	[ElectricityTypes.Loadcurve]: "loadcurve",
	[ElectricityTypes.Power]: "power",
	[ElectricityTypes.EnergyDay]: "energy/day",
	[ElectricityTypes.EnergyMonth]: "energy/month",
	[ElectricityTypes.EnergyWeek]: "energy/week",
	[ElectricityTypes.EnergyYear]: "energy/year",
	[ElectricityTypes.PluriannualEvolution]: "energy/pluriannual",
	[ElectricityTypes.EnergyDonut]: "energy/donut",
	[ElectricityTypes.TypicalWeek]: "typicalprofile/week",
	[ElectricityTypes.TypicalDay]: "typicalprofile/day",
	[ElectricityTypes.CompareTypicalDay]: "compare/typicalprofile/day",
	[ElectricityTypes.CompareTypicalWeek]: "compare/typicalprofile/week",
	[ElectricityTypes.CompareEnergy]: "compare/energy",
	[ElectricityTypes.Benchmark]: "energy/benchmark",
	[ElectricityTypes.QoE]: "qoe",
	[ElectricityTypes.SubscribedPower]: "subscribedpower",
};
