import { Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";
import { t } from "ttag";
import { IconButtonAnimate } from "../../../components/animate";
// components
import Iconify from "../../../components/others/Iconify";
import { SmartCircularProgress } from "../loader/LoaderSmart";

// ----------------------------------------------------------------------

export default function HijackedButton() {
	// Go to https://proxy-maximej.internal.smartimpulse.com/hijack/5634/?next=/api/rest/user/ for instance to enter hijack mode

	const { user, releaseHijack } = useAuth();
	const [loading, setLoading] = useState<boolean>(false);

	if (!user?.hijacked) return null;

	const handleClick = () => {
		releaseHijack();
		setLoading(true);
	};
	return !loading ? (
		<Tooltip title={t`Leave hijacking session`}>
			<IconButtonAnimate
				color={"default"}
				size="large"
				onClick={handleClick}
				sx={{
					"&:hover": {
						backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.2),
						// height:52,
						// width:52,
					},
				}}
			>
				<Iconify
					icon="ph:detective-duotone"
					width={20}
					height={20}
					sx={{ color: "orange" }}
				/>
			</IconButtonAnimate>
		</Tooltip>
	) : (
		<SmartCircularProgress />
	);
}
