import { Box, type BoxProps } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import type { ReactNode } from "react";
import ReactQuill, { type ReactQuillProps } from "react-quill";
import { CustomScrollbar } from "../others/Scrollbar";
//
import EditorToolbar, {
	formats,
	redoChange,
	undoChange,
} from "./EditorToolbar";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: theme.shape.borderRadius,
	border: `solid 1px ${theme.palette.grey[500_32]}`,
	"& .ql-container.ql-snow": {
		borderColor: "transparent",
		...theme.typography.body1,
		fontFamily: theme.typography.fontFamily,
	},
	"& .ql-editor": {
		minHeight: 200,
		"&.ql-blank::before": {
			fontStyle: "normal",
			color: theme.palette.text.disabled,
		},
		"& pre.ql-syntax": {
			...theme.typography.body2,
			padding: theme.spacing(2),
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.grey[900],
		},
	},
}));

// ----------------------------------------------------------------------

export interface Props extends ReactQuillProps {
	id?: string;
	error?: boolean;
	simple?: boolean;
	helperText?: ReactNode;
	sx?: BoxProps;
}

const toolbarOptions = [
	["bold", "italic", "underline"],
	// [{ 'header': 1 }, { 'header': 2 }],
	[{ list: "ordered" }, { list: "bullet" }],
	// [{ 'indent': '-1' }, { 'indent': '+1' }]
];

export default function Editor({
	id = "minimal-quill",
	error,
	value,
	onChange,
	simple = false,
	helperText,
	readOnly,
	sx,
	...other
}: Props) {
	const modules = {
		toolbar: toolbarOptions,
		// toolbar: {
		//   container: `#${id}`,
		//   handlers: {
		//     undo: undoChange,
		//     redo: redoChange,
		//   },
		// },
		history: {
			delay: 500,
			maxStack: 100,
			userOnly: true,
		},
		syntax: true,
		clipboard: {
			matchVisual: false,
		},
	};

	return (
		<>
			<RootStyle
				sx={{
					...(error && {
						border: (theme) => `solid 1px ${theme.palette.error.main}`,
					}),
					...sx,
					// ...scroll_bar,
					...CustomScrollbar(),
				}}
			>
				<EditorToolbar id={id} isSimple={simple} />
				<ReactQuill
					readOnly={readOnly}
					value={value}
					onChange={onChange}
					modules={modules}
					formats={formats}
					placeholder="Write something awesome..."
					{...other}
				/>
			</RootStyle>

			{helperText && helperText}
		</>
	);
}
