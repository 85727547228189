import {
	Box,
	LinearProgress,
	Stack,
	type SxProps,
	Typography,
} from "@mui/material";
// @mui
import { alpha, styled, useTheme } from "@mui/material/styles";
import { m } from "framer-motion";
import { useEffect, useState } from "react";
import { t } from "ttag";
//
import Logo from "./Logo";
import ProgressBar from "./ProgressBar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	right: 0,
	bottom: 0,
	zIndex: 99999,
	width: "100%",
	height: "100%",
	position: "fixed",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

type Props = {
	isDashboard?: boolean;
	isLogout?: boolean;
	sx?: SxProps;
};

export default function LoadingScreen({
	isDashboard,
	isLogout,
	...other
}: Props) {
	const theme = useTheme();
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		let step = 20;
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress < 50) {
					step = 10;
				} else if (oldProgress < 75) {
					step = 5;
				} else if (oldProgress < 90) {
					step = 1;
				} else if (oldProgress < 95) {
					step = 0.5;
				} else {
					step /= 2;
				}
				// const diff = Math.random() * 10;
				return Math.min(oldProgress + step, 100);
			});
		}, 200);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<>
			<ProgressBar />

			{!isDashboard && (
				<>
					<RootStyle {...other}>
						<Stack
							direction="column"
							alignItems="center"
							justifyContent="center"
						>
							<Box
								component={m.div}
								initial={{ opacity: 0, scale: 0.1 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{
									duration: isLogout ? 0 : 1.5,
									delay: isLogout ? 0 : 0.2,
									ease: [0, 0.71, 0.2, 1.01],
								}}
							>
								<Logo
									disabledLink
									color={theme.palette.secondary.main}
									sx={{
										width: "400px",
										height: "auto",
										maxWidth: "50%",
										mb: 2,
										mx: "auto",
									}}
								/>
							</Box>
							<m.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{
									duration: isLogout ? 0 : 0.5,
									delay: isLogout ? 0 : 1,
								}}
							>
								<LinearProgress
									color="primary"
									variant="determinate"
									value={progress}
									sx={{
										width: "500px",
										maxWidth: "50%",
										mx: "auto",
										borderRadius: "2px",
										"& .MuiLinearProgress-bar": {
											background: theme.palette.gradients.mainGradient,
										},
									}}
								/>
								<Typography
									variant="h6"
									color="text.primary"
									sx={{ mt: 2, mx: "auto", textAlign: "center !important" }}
								>
									{isLogout ? t`Logout pending...` : t`Loading...`}
								</Typography>
							</m.div>
						</Stack>
					</RootStyle>
				</>
			)}
		</>
	);
}
