import { useContext } from "react";
import { SiteSelectContext } from "../contexts/SiteContext";

// ----------------------------------------------------------------------

// const useNodesContext = () =>

const useSiteContext = () => {
	const context = useContext(SiteSelectContext);

	if (!context) throw new Error("Site context must be use inside SiteProvider");

	return context;
};
export default useSiteContext;
