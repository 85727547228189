import { type ReactNode, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthLayout from "src/layouts/AuthLayout";
import { PATH_PAGE } from "src/routes/paths";
import LoadingScreen from "../components/others/LoadingScreen";
import useAuth from "../hooks/useAuth";
import Login from "../pages/auth/Login";

// ----------------------------------------------------------------------

type AuthGuardProps = {
	children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
	const { isAuthenticated, isInitialized, isDisconnecting, serverError } =
		useAuth();
	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState<string | null>(
		null,
	);

	if (serverError) {
		// navigate to take advantage of lazy loading
		return <Navigate to={PATH_PAGE.page500} />;
	}

	if (serverError) {
		// navigate to take advantage of lazy loading
		return <Navigate to={PATH_PAGE.page500} />;
	}

	if (!isInitialized) {
		return <LoadingScreen />;
	}

	if (isDisconnecting) {
		return <LoadingScreen isLogout={true} />;
	}

	if (!isAuthenticated) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		return (
			<>
				<AuthLayout component={<Login />} />
			</>
		);
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
