import { lazy } from "react";

const KEY = "page-has-been-force-refreshed";

// When catching ChunkLoadError, a good chance is that the server has deleted the file
// because a new release has been deployed.

// from https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
// Modified to use sessionStorage so that it is isolated per tab.
export const lazyWithRetry = (componentImport: () => Promise<any>) =>
	lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed =
			window.sessionStorage.getItem(KEY) !== null;

		try {
			const component = await componentImport();

			// success, so clear the flag
			window.sessionStorage.removeItem(KEY);

			return component;
		} catch (error) {
			if (
				process.env.REACT_APP_RELOAD_ON_CHUNK_ERROR !== undefined &&
				error.name === "ChunkLoadError" &&
				!pageHasAlreadyBeenForceRefreshed
			) {
				// Assuming that the user is not on the latest version of the application.
				// Let's refresh the page immediately.
				window.sessionStorage.setItem(KEY, "1");
				return window.location.reload();
			}

			// The page has already been reloaded
			// Assuming that user is already using the latest version of the application.
			// Let's let the application crash and raise the error.
			throw error;
		}
	});
