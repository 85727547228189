import { max, min } from "date-fns";
import type { DateRangeDate } from "src/contexts/dateRangeContext";

export function intersection(
	rng1: DateRangeDate | null,
	rng2: DateRangeDate | null,
): [Date, Date] | null {
	if (!rng1 || !rng2) return null;

	const [d1_1, d1_2] = rng1;
	const [d2_1, d2_2] = rng2;

	if (d1_1 === null || d1_2 === null) return null;
	if (d2_1 === null || d2_2 === null) return null;

	const intersect: [Date, Date] = [max([d1_1, d2_1]), min([d1_2, d2_2])];
	const valid_intersection = intersect[0] <= intersect[1];
	if (valid_intersection) return intersect;
	return null;
}
