// @mui
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
// components
import ContactForm from "src/components/others/ContactForm";
import { brandSetting, themeBranding } from "src/config";
import useAuth from "src/hooks/useAuth";
// hooks
import useFeedbackContext from "src/hooks/useFeedbackContext";
//
import { PATH_DASHBOARD, PATH_PAGE } from "src/routes/paths";
// config
import { Iconify } from "src/utils/getIcon";
import { t } from "ttag";

export default function DashboardFooter() {
	const year: number = new Date().getFullYear();
	const { setOpenContact } = useFeedbackContext();
	const { user } = useAuth();
	const location = useLocation();

	// const handleClick = () => {
	//   setOpenContact(true)
	// }

	const isLoggedOn =
		user !== undefined && user !== null && user.email !== undefined;
	const isTerms =
		location.pathname.includes("terms") ||
		location.pathname.includes("privacy");
	if (themeBranding === "smartImpulse") {
		return (
			<Stack sx={{ py: 2, ...(isLoggedOn && { background: "none" }) }}>
				<Stack direction="row" justifyContent="center">
					<Typography
						variant="caption"
						component="p"
						sx={{
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
							flexWrap: "wrap",
							flexDirection: "row",
							color: isLoggedOn || isTerms ? "text.disabled" : "white",
							fontWeight: "bold",
							alignContent: "baseline",
						}}
					>
						Copyright ©{year}&nbsp;
						<Link
							tabIndex={-1}
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.smart-impulse.com/"
							sx={{ color: isLoggedOn || isTerms ? "text.disabled" : "white" }}
						>
							Smart&nbsp;Impulse,
						</Link>
						&nbsp;{t`All Rights Reserved`}.{t` Made in France with`}&nbsp;
						{Iconify("ph:heart-fill", 14)}.
					</Typography>
				</Stack>
				<Stack direction="row" justifyContent="center">
					<Typography
						variant="caption"
						component="div"
						sx={{
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
							flexWrap: "wrap",
							flexDirection: "row",
							color: isLoggedOn || isTerms ? "text.disabled" : "white",
							alignContent: "baseline",
							", & *": {
								color: isLoggedOn || isTerms ? "text.disabled" : "white",
								alignContent: "baseline",
							},
						}}
					>
						{t`Stay connected`}&nbsp;
						<Link
							tabIndex={-1}
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.linkedin.com/company/smart-impulse"
						>
							{Iconify("ph:linkedin-logo-fill", 14)}
						</Link>{" "}
						&nbsp;
						<Link
							tabIndex={-1}
							target="_blank"
							rel="noopener noreferrer"
							href="https://twitter.com/SmartImpulse"
						>
							{Iconify("ph:twitter-logo-fill", 14)}
						</Link>{" "}
						&nbsp;
						<Link
							tabIndex={-1}
							target="_blank"
							rel="noopener noreferrer"
							href="https://fr-fr.facebook.com/Smartimpulse/"
						>
							{Iconify("ph:facebook-logo-fill", 14)}
						</Link>
						&nbsp;|&nbsp;
						{/* <Link tabIndex={-1} href={PATH_DASHBOARD.general.terms} sx={{ color: 'text.disabled' }}>Mentions légales</Link> */}
						<Link
							tabIndex={-1}
							color="inherit"
							component={RouterLink}
							sx={{ color: isLoggedOn || isTerms ? "text.disabled" : "white" }}
							to={isLoggedOn ? PATH_DASHBOARD.general.terms : PATH_PAGE.terms}
						>{t`Terms`}</Link>
						&nbsp;|&nbsp;
						<Link
							tabIndex={-1}
							color="inherit"
							component={RouterLink}
							sx={{ color: isLoggedOn || isTerms ? "text.disabled" : "white" }}
							to={
								isLoggedOn ? PATH_DASHBOARD.general.privacy : PATH_PAGE.privacy
							}
						>{t`Privacy policy`}</Link>
						&nbsp;|&nbsp;
						<Link
							tabIndex={-1}
							href="#"
							onClick={() => setOpenContact(true)}
							sx={{ color: isLoggedOn || isTerms ? "text.disabled" : "white" }}
						>{t`Contact us`}</Link>
					</Typography>
					<ContactForm />
				</Stack>
			</Stack>
		);
	}

	return (
		<Stack
			sx={{
				pt: 2,
				width: "100%",
				...(isLoggedOn && { background: "none", py: 2 }),
			}}
			direction="row"
			justifyContent="center"
		>
			<Typography
				variant="body2"
				component="div"
				sx={{
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
					flexWrap: "wrap",
					flexDirection: "row",
					px: 1,
					background: !isLoggedOn || isTerms ? alpha("#fff", 0.8) : "none",
					color: "text.disabled",
					alignContent: "baseline",
					"& a": { color: "text.disabled", alignContent: "baseline" },
				}}
			>
				<Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
					Copyright ©{year}&nbsp;
					<Link
						tabIndex={-1}
						target="_blank"
						rel="noopener noreferrer"
						href={brandSetting.companyUrl}
					>
						{brandSetting.companyName}&nbsp;
					</Link>
				</Box>
				&nbsp;|&nbsp;
				<Link
					tabIndex={-1}
					color="primary.main"
					component={RouterLink}
					to={isLoggedOn ? PATH_DASHBOARD.general.terms : PATH_PAGE.terms}
				>{t`Terms`}</Link>
				&nbsp;|&nbsp;
				<Link
					tabIndex={-1}
					color="primary.main"
					component={RouterLink}
					to={isLoggedOn ? PATH_DASHBOARD.general.privacy : PATH_PAGE.privacy}
				>{t`Privacy policy`}</Link>
				&nbsp;|&nbsp;
				<Link
					tabIndex={-1}
					href="#"
					onClick={() => setOpenContact(true)}
				>{t`Contact us`}</Link>
				&nbsp;|&nbsp;
				<Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
					Powered by&nbsp;
					<Link
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={-1}
						href="https://www.smart-impulse.com/"
					>
						Smart Impulse
					</Link>
				</Box>
			</Typography>
			<ContactForm />
		</Stack>
	);
}
