// @mui
import {
	Alert,
	Box,
	Card,
	Container,
	Divider,
	Link,
	Stack,
	Typography,
} from "@mui/material";
// import { Link as RouterLink } from 'react-router-dom';
import { useState } from "react";
import { brandSetting } from "src/config";
import LanguagePopover from "src/layouts/dashboard/header/LanguagePopover";
// import Logo from '../../components/others/Logo';
// import Image from '../../components/others/Image';
// import IllustrationLogin from 'src/assets/illustration_login.png'
import { t } from "ttag";
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useResponsive from '../../hooks/useResponsive';
// components
import Page from "../../components/others/Page";
// sections
import { LoginForm, LoginFormShorcut } from "../../sections/auth/login";

export default function Login() {
	const [emailForm, setEmailForm] = useState(true);
	// const theme = useTheme();
	// const smUp = useResponsive('up', 'sm');
	// const mdUp = useResponsive('up', 'md');

	const handleFormTypeChange = (isEmailForm: boolean) => {
		setEmailForm(isEmailForm);
	};

	return (
		<Page data-testid="login" title={t`Login`}>
			{/* <Container maxWidth="sm" sx={{ justifyContent: 'center', alignItems: 'center' }}> */}
			<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="h4" gutterBottom>
						{t`Welcome to your Dashboard!`}
					</Typography>
					{emailForm ? (
						<Typography
							sx={{ color: "text.secondary" }}
						>{t`Enter your e-mail below.`}</Typography>
					) : (
						<Typography
							sx={{ color: "text.secondary" }}
						>{t`Enter your password below.`}</Typography>
					)}
				</Box>

				<Box sx={{ justifyContent: "flex-end", ml: 3 }}>
					<LanguagePopover />
				</Box>
			</Stack>

			<LoginForm onFormTypeChange={handleFormTypeChange} />
			{/* <Stack marginTop={2} marginBottom={2}>
            <Divider>{t`OR`}</Divider>
            <LoginFormShorcut />
          </Stack> */}
			<Box
				sx={{
					mt: 1,
					width: "100%",
					textAlign: "center",
					typography: "body2",
				}}
			>
				{brandSetting.allow_dashboard_v1 && emailForm && (
					<Link
						href={brandSetting.oldUrl}
						sx={{ color: "text.disabled" }}
					>{t`or go back to the old version`}</Link>
				)}
			</Box>
			{/* </Container> */}
		</Page>
	);
}
