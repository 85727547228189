import { Theme } from "@mui/material";
import * as aq from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";
import type { Struct } from "arquero/dist/types/table/transformable";
import { uniqueId } from "lodash";
import { APIGraphSeries } from "src/@types/graphSeries";
import { Category, Ratio } from "src/@types/magic";
import { t } from "ttag";

function getBenchmarkSeries(
	dataCumul: ColumnTable,
	macroCategories: Category[],
	theme: Theme,
	setBenchmarkValidSitesData?: (table: ColumnTable) => void,
	ratio?: Ratio,
) {
	const benchmarkSeries: APIGraphSeries[] = [];

	// filter out sites without any day for selected period
	// for some reason filter won't work without escaping first on prod env
	let filteredTable = dataCumul.filter(
		aq.escape((row: Struct | undefined) => row !== undefined && row.days_count),
	);
	if (ratio === "surface") {
		filteredTable = filteredTable
			.filter(
				aq.escape(
					(row: Struct | undefined) =>
						row !== undefined && row.surface !== null,
				),
			)
			.orderby(aq.desc("performance"));
	}

	let surfaceData = filteredTable.array("surface");
	if (setBenchmarkValidSitesData) {
		// important to keep this set here because filteredTable will be filtered on columns again after
		setBenchmarkValidSitesData(filteredTable);
	}

	surfaceData = surfaceData.map((totalValue: number, index: number) => [
		index,
		totalValue,
	]);
	// create line serie for surface of all sites
	const surfaceSeries: APIGraphSeries = {
		unique_id: uniqueId(),
		label: t`Floor area`,
		type: "line",
		color: theme.palette.grey[600],
		data: surfaceData,
	};

	const installations: string[] = filteredTable.array("installation_uuid");

	// select columns that matches number => data by macrocategory + total
	filteredTable = filteredTable.select("total", aq.matches(/\d+/));
	const columns = filteredTable.columnNames();

	columns.forEach((column) => {
		const isTotal = column === "total";
		const macroCategoryId = !isTotal ? Number.parseInt(column) : column;
		const macroCategory = macroCategories.find(
			(item) => item.id === macroCategoryId,
		);
		const colValues = filteredTable.array(column);

		let label = column;
		if (macroCategory) label = macroCategory.label;
		else if (isTotal) label = t`Total energy`;

		if (macroCategory || isTotal) {
			if (
				colValues.some((mcValue: number | undefined) => mcValue !== undefined)
			) {
				benchmarkSeries.push({
					unique_id: uniqueId(),
					label: label,
					itemid: macroCategoryId,
					description: macroCategory ? macroCategory.description : undefined,
					type: isTotal ? "total" : "stacked_element",
					color: macroCategory ? macroCategory.color : "#42374D",
					data: colValues.map((mcValue: number | undefined, index: number) =>
						mcValue === undefined ? [index, 0] : [index, mcValue],
					),
				});
			}
		}
	});

	return {
		benchmarkSeries: benchmarkSeries,
		surfaceSeries: surfaceSeries,
		installations: installations,
	};
}

export default getBenchmarkSeries;
