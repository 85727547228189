import { intervalLengthEnum } from "src/@types/installation";
import { t } from "ttag";

export const translateInterval = (interval_length: intervalLengthEnum) => {
	switch (interval_length) {
		case intervalLengthEnum.len1sec:
			return t`1 second`;
		case intervalLengthEnum.len30sec:
			return t`30 seconds`;
		case intervalLengthEnum.len1min:
			return t`1 minute`;
		case intervalLengthEnum.len5min:
			return t`5 minutes`;
		case intervalLengthEnum.len10min:
			return t`10 minutes`;
		case intervalLengthEnum.len15min:
			return t`15 minutes`;
		case intervalLengthEnum.len20min:
			return t`20 minutes`;
		case intervalLengthEnum.len30min:
			return t`30 minutes`;
		case intervalLengthEnum.len1hour:
			return t`1 hour`;
		case intervalLengthEnum.len1day:
			return t`1 day`;
		case intervalLengthEnum.len1week:
			return t`1 week`;
		case intervalLengthEnum.len1month:
			return t`1 month`;
		case intervalLengthEnum.len1quarter:
			return t`1 quarter`;
		case intervalLengthEnum.len1semester:
			return t`1 semester`;
		case intervalLengthEnum.len1year:
			return t`1 year`;
	}
};
