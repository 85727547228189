import type { ReactNode } from "react";
import type { ThemeBranding } from "../settings/type";

export type paletteType = {
	colorprimary100: "string";
	colorprimary200: "string";
	colorprimary300: "string";
	colorprimary400: "string";
	colorprimary500: "string";
	colorprimary600: "string";
	colorprimary700: "string";
	colorprimary800: "string";
	colorprimary900: "string";
	colorsuccess100: "string";
	colorsuccess200: "string";
	colorsuccess300: "string";
	colorsuccess400: "string";
	colorsuccess500: "string";
	colorsuccess600: "string";
	colorsuccess700: "string";
	colorsuccess800: "string";
	colorsuccess900: "string";
};
export type brandSettingsType = {
	branding: string;
	name: string;
	domain: string;
	companyName: string;
	companyUrl: string;
	companyEmail: string;
	companyAddress: ReactNode;
	companyLegalInfo: ReactNode;
	feedbackEmail: string;
	withAlerts: boolean;
	withChat: boolean;
	allow_dashboard_v1: boolean;
	palette: any;
	bodyFont: string;
	headersFont: string;
	assetsUrl: string;
	logoHeight: number;
	oldUrl: string;
};

export const brandSettings: { [key in ThemeBranding]: brandSettingsType } = {
	smartImpulse: {
		branding: "Smart\xa0Impulse",
		name: "Dashboard",
		domain: "dashboard.smart-impulse.com",
		companyName: "Smart Impulse",
		companyUrl: "https://www.smart-impulse.com/",
		companyEmail: "no-reply@smart-impulse.com",
		companyAddress: (
			<>
				- Adresse : 157 boulevard Macdonald - 75019 Paris - France
				<br />- Téléphone : +33 1 84 17 31 20
			</>
		),
		companyLegalInfo: (
			<>
				- Dénomination : SAS Smart Impulse au capital de 100 950,29 euros
				<br />- Immatriculation : RCS Paris 531 028 819, Numéro de TVA:
				FR82531028819
				<br />
			</>
		),
		feedbackEmail: "julien.kerlidou@smart-impulse.com",
		withAlerts: true,
		withChat: true,
		allow_dashboard_v1: true,
		palette: {
			colorprimary100: "#C9FBEA",
			colorprimary200: "#94F7DF",
			colorprimary300: "#5DE9D3",
			colorprimary400: "#34D3C8",
			colorprimary500: "#00B4B7",
			colorprimary600: "#008D9D",
			colorprimary700: "#006B83",
			colorprimary800: "#004D6A",
			colorprimary900: "#003857",
			colorsuccess100: "#F0FCD2",
			colorsuccess200: "#DEF9A6",
			colorsuccess300: "#C2ED77",
			colorsuccess400: "#A4DC53",
			colorsuccess500: "#7BC622",
			colorsuccess600: "#61AA18",
			colorsuccess700: "#498E11",
			colorsuccess800: "#34720A",
			colorsuccess900: "#265F06",
		},
		bodyFont: "Roboto, sans-serif",
		headersFont: "Roboto, sans-serif",
		assetsUrl: `${process.env.PUBLIC_URL}/smartImpulse`,
		oldUrl: "/v1/",
		logoHeight: 75,
	},
	smeg: {
		branding: "Smart+ by SMEG",
		name: "Smart+",
		domain: "smartplus.smeg.mc",
		companyName: "SMEG",
		companyUrl: "https://www.smeg.mc",
		companyEmail: "no-reply@smart-impulse.com",
		companyAddress: (
			<>
				- Adresse : 10 avenue de Fontvieille - 98000 MONACO
				<br />- Téléphone : 92 05 05 00
			</>
		),
		companyLegalInfo: (
			<>
				- Dénomination : Société Monégasque de l'Electricité et du Gaz – SMEG,
				Société Anonyme Monégasque au capital de 22.950.600€
				<br />- Immatriculation : RCI Monaco n° 56 S 0575, Code NIS 3514Z00118
				<br />
			</>
		),
		feedbackEmail: "anthony.dupont@smeg.mc",
		withAlerts: false,
		withChat: false,
		allow_dashboard_v1: true,
		palette: {
			colorprimary100: "#C8FAE4",
			colorprimary200: "#93F5D2",
			colorprimary300: "#5AE2BF",
			colorprimary400: "#31C6AD",
			colorprimary500: "#00A194",
			colorprimary600: "#00898A",
			colorprimary700: "#006873",
			colorprimary800: "#004B5D",
			colorprimary900: "#00374D",
			colorsuccess100: "#FEF2D3",
			colorsuccess200: "#FDE1A7",
			colorsuccess300: "#FBCB7B",
			colorsuccess400: "#F7B55A",
			colorsuccess500: "#F39325",
			colorsuccess600: "#D0731B",
			colorsuccess700: "#AE5712",
			colorsuccess800: "#8C3E0B",
			colorsuccess900: "#742D07",
		},
		bodyFont: "Calibri, Arial, Helvetica, sans-serif",
		headersFont: "Calibri, Arial, Helvetica, sans-serif",
		assetsUrl: `${process.env.PUBLIC_URL}/smeg`,
		oldUrl: "/",
		logoHeight: 75,
	},
	herveThermique: {
		branding: "Diagelec by Hervé Thermique",
		name: "Diagelec",
		domain: "diagelec.herve-thermique.com",
		companyName: "Hervé Thermique",
		companyUrl: "https://www.herve-thermique.com",
		companyEmail: "dashboard@smart-impulse.com",
		companyAddress: (
			<>
				- Adresse : ZI n°1 – 14 rue Denis Papin - BP 105 – 37301 Joué-lès-Tours
				<br />- Téléphone : +33 2 47 68 20 00
			</>
		),
		companyLegalInfo: (
			<>
				- Dénomination : SAS Hervé Thermique au capital de 3 000 000 €<br />-
				Immatriculation : RCS Tours 627 220 049, Numéro de TVA: FR 33 627 220
				049
				<br />
			</>
		),
		feedbackEmail: "mathieu.salmon@alerteo.com",
		withAlerts: true,
		withChat: false,
		allow_dashboard_v1: true,
		palette: {
			colorprimary100: "#FEE9CB",
			colorprimary200: "#FECD98",
			colorprimary300: "#FEAA65",
			colorprimary400: "#FE883F",
			colorprimary500: "#FE5000",
			colorprimary600: "#DA3600",
			colorprimary700: "#B62100",
			colorprimary800: "#931100",
			colorprimary900: "#790600",
			colorsuccess100: "#FEEED5",
			colorsuccess200: "#FDD9AC",
			colorsuccess300: "#FABD82",
			colorsuccess400: "#F6A262",
			colorsuccess500: "#f17830",
			colorsuccess600: "#CF5823",
			colorsuccess700: "#AD3D18",
			colorsuccess800: "#8B260F",
			colorsuccess900: "#731609",
		},
		bodyFont: "'PT Sans', sans-serif",
		headersFont: "'PT Sans', sans-serif",
		assetsUrl: `${process.env.PUBLIC_URL}/herveThermique`,
		oldUrl: "/",
		logoHeight: 100,
	},
	ejMaintenance: {
		branding: "EJ Maintenance",
		name: "EJ Maintenance",
		domain: "energies.groupe-ej.com",
		companyName: "EJ Maintenance",
		companyUrl: "https://groupe-ej.com/",
		companyEmail: "dashboard@smart-impulse.com",
		companyAddress: (
			<>
				- Adresse : 30 BD PIERRE LEFAUCHEUX - 72100 LE MANS
				<br />- Téléphone : +33(0)7 57 09 79 68
			</>
		),
		companyLegalInfo: (
			<>
				- Dénomination : Groupe EJ - 94 avenue Pierre Piffault - 72100 Le Mans
			</>
		),
		feedbackEmail: "sva@ej-maintenance.com",
		withAlerts: true,
		withChat: false,
		allow_dashboard_v1: false,
		palette: {
			colorprimary100: "#FEF0D3",
			colorprimary200: "#FDDEA7",
			colorprimary300: "#FAC67B",
			colorprimary400: "#F6AE5A",
			colorprimary500: "#F18825",
			colorprimary600: "#CF691B",
			colorprimary700: "#AD4D12",
			colorprimary800: "#8B360B",
			colorprimary900: "#732507",
			colorsuccess100: "#C4EAF6",
			colorsuccess200: "#8ED2ED",
			colorsuccess300: "#51A1CA",
			colorsuccess400: "#256996",
			colorsuccess500: "#002C50",
			colorsuccess600: "#002244",
			colorsuccess700: "#001939",
			colorsuccess800: "#00112E",
			colorsuccess900: "#000C26",
		},
		bodyFont: "'BR Omega', sans-serif",
		headersFont: "'BR Omega', sans-serif",
		assetsUrl: `${process.env.PUBLIC_URL}/ejMaintenance`,
		oldUrl: "/",
		logoHeight: 100,
	},
};
