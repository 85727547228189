import { Box, List, ListSubheader } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
// type
import type { NavSectionProps } from "../type";
//
import { NavListRoot } from "./NavList";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
	<ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
	...theme.typography.overline,
	paddingTop: theme.spacing(3),
	paddingLeft: theme.spacing(2),
	paddingBottom: theme.spacing(1),
	color: theme.palette.secondary.main,
	transition: theme.transitions.create("opacity", {
		duration: theme.transitions.duration.shorter,
	}),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
	navConfig,
	isCollapse = false,
	...other
}: NavSectionProps) {
	return (
		<Box {...other} sx={{ ...(isCollapse && { alignContent: "center" }) }}>
			{navConfig.map((group) => (
				<List key={group.subheader} disablePadding sx={{ px: 2 }}>
					<ListSubheaderStyle
						sx={{
							// ...(isCollapse && {
							//   height:0,
							//   width:0,
							//   margin:0,
							//   padding:0,
							//   opacity: 0,
							// }),
							...(isCollapse && { visibility: "hidden" }),
						}}
					>
						{group.subheader}
					</ListSubheaderStyle>

					{group.items.map((list) => (
						<NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
					))}
				</List>
			))}
		</Box>
	);
}
