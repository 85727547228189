import { useContext } from "react";
import { BenchmarkContext } from "src/contexts/BenchmarkContext";

// ----------------------------------------------------------------------

const useBenchmarkContext = () => {
	const context = useContext(BenchmarkContext);

	if (!context)
		throw new Error("Benchmark context must be use inside BenchmarkProvider");

	return context;
};

export default useBenchmarkContext;
