window.requestIdleCallback =
	window.requestIdleCallback ||
	((cb) => {
		var start = Date.now();
		return setTimeout(() => {
			cb({
				didTimeout: false,
				timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
			});
		}, 1);
	});

window.cancelIdleCallback =
	window.cancelIdleCallback ||
	((id) => {
		clearTimeout(id);
	});
