import { Box, type BoxProps } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import Image from "src/components/others/Image";

// ----------------------------------------------------------------------

export default function MatchNotFoundIllustration({ ...other }: BoxProps) {
	const theme = useTheme();
	// const PRIMARY = theme.palette.secondary.main; // to replace "#0CBC8B"
	const SECONDARY = theme.palette.primary.main; // to replace "#367CFF"

	return (
		<Box {...other}>
			<svg
				id="Layer_1"
				width="100%"
				height="100%"
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 742.746 656.701"
			>
				<g>
					<circle cx="371.373" cy="328.351" r="328.305" fill="#fff" />
					<circle
						cx="371.373"
						cy="328.351"
						r="328.305"
						fill={SECONDARY}
						opacity="0.1"
					/>
					<g>
						<polygon
							points="158.006 439.86 1 439.384 1.646 226.094 110.212 226.423 158.505 275.01 158.006 439.86"
							fill="#fff"
						/>
						<polygon
							points="110.212 226.423 110.065 274.863 158.505 275.01 110.212 226.423"
							fill={SECONDARY}
							opacity="0.3"
						/>
						<path
							d="M159.493,274.95a1.028,1.028,0,0,0-.051-.257c-.011-.032-.019-.063-.033-.095a.975.975,0,0,0-.195-.293l-48.293-48.587a1,1,0,0,0-.293-.2.933.933,0,0,0-.093-.034.961.961,0,0,0-.26-.052c-.021,0-.04-.013-.061-.013L1.649,225.094h0a1,1,0,0,0-1,1L0,439.381a1,1,0,0,0,1,1L158,440.86h0a1,1,0,0,0,1-1l.5-164.85C159.505,274.991,159.494,274.972,159.493,274.95ZM111.2,228.841,133.5,251.268,156.094,274l-45.026-.137ZM2,438.387l.64-211.29,106.565.323-.143,47.44a1,1,0,0,0,1,1l47.44.144-.493,162.85Z"
							fill="#262626"
						/>
					</g>
					<g>
						<polygon
							points="478.084 431.325 263.981 430.676 264.862 139.82 412.909 140.269 478.765 206.525 478.084 431.325"
							fill="#fff"
						/>
						<polygon
							points="412.909 140.269 412.709 206.325 478.765 206.525 412.909 140.269"
							fill={SECONDARY}
							opacity="0.3"
						/>
						<path
							d="M479.753,206.465a1.01,1.01,0,0,0-.05-.256c-.011-.033-.02-.064-.034-.1a.99.99,0,0,0-.194-.293l-65.856-66.256a1,1,0,0,0-.293-.2c-.031-.014-.061-.022-.093-.033a1.01,1.01,0,0,0-.26-.053c-.021,0-.04-.012-.061-.012l-148.047-.449h0a1,1,0,0,0-1,1l-.881,290.856a1,1,0,0,0,1,1l214.1.649h0a1,1,0,0,0,1-1l.681-224.8C479.765,206.506,479.754,206.487,479.753,206.465ZM413.9,142.686l62.452,62.832-62.642-.19ZM264.984,429.679l.875-288.856,146.047.443-.2,65.056a1,1,0,0,0,1,1l65.056.2-.675,222.8Z"
							fill="#262626"
						/>
					</g>
					<g>
						<polygon
							points="741.247 439.86 584.241 439.384 584.887 226.094 693.453 226.423 741.746 275.01 741.247 439.86"
							fill="#fff"
						/>
						<polygon
							points="693.453 226.423 693.306 274.863 741.746 275.01 693.453 226.423"
							fill={SECONDARY}
							opacity="0.3"
						/>
						<path
							d="M742.734,274.95a.983.983,0,0,0-.05-.257c-.011-.032-.02-.063-.034-.095a.99.99,0,0,0-.194-.293l-48.294-48.587a.992.992,0,0,0-.292-.2c-.031-.014-.062-.023-.094-.034a.961.961,0,0,0-.259-.052c-.021,0-.04-.013-.061-.013l-108.566-.329h0a1,1,0,0,0-1,1l-.646,213.29a1,1,0,0,0,1,1l157.006.476h0a1,1,0,0,0,1-1l.5-164.85C742.746,274.991,742.735,274.972,742.734,274.95Zm-48.289-46.109,22.292,22.427L739.335,274l-45.026-.137Zm-109.2,209.546.64-211.29,106.566.323-.144,47.44a1,1,0,0,0,1,1l47.44.144-.493,162.85Z"
							fill="#262626"
						/>
					</g>
					<g>
						<g>
							<g>
								<g>
									<circle cx="317.896" cy="294.33" r="14.988" fill="#292a2e" />
									<circle cx="420.668" cy="294.33" r="14.988" fill="#292a2e" />
								</g>
								<path
									d="M389.376,335.78H349.188a1,1,0,0,1,0-2h40.188a1,1,0,0,1,0,2Z"
									fill="#262626"
								/>
							</g>
							<g>
								<ellipse
									cx="302.435"
									cy="330.166"
									rx="13.37"
									ry="7.037"
									fill="#fff"
								/>
								<ellipse
									cx="436.129"
									cy="330.166"
									rx="13.37"
									ry="7.037"
									fill="#fff"
								/>
							</g>
							<g opacity="0.4">
								<ellipse
									cx="302.435"
									cy="330.166"
									rx="13.37"
									ry="7.037"
									fill={SECONDARY}
								/>
								<ellipse
									cx="436.129"
									cy="330.166"
									rx="13.37"
									ry="7.037"
									fill={SECONDARY}
								/>
							</g>
						</g>
						<path
							d="M479.087,208.909a146.518,146.518,0,0,0-206.706-14.263c-48.956,42.637-64,113.523-36.577,172.4a145.933,145.933,0,0,0,20.993,32.73l1.8,2.133L124.405,544.32a13.661,13.661,0,0,0,19.886,18.735L278.45,420.666l2.241,1.678a146.515,146.515,0,0,0,198.4-213.435ZM487.5,313.337A119.2,119.2,0,0,1,368.777,424.326q-4.177,0-8.382-.3a119.246,119.246,0,1,1,98.092-197.182A118.516,118.516,0,0,1,487.5,313.337Z"
							fill={SECONDARY}
						/>
					</g>
				</g>
			</svg>
		</Box>
	);
}
